import React, { useEffect, useMemo, useState } from "react";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useFetchAllTheCashTransferRequestsMutation,
  useHandleAcceptRequestMutation,
  useHandleRejectRequestMutation,
} from "../../../features/tenant/cashTransactions/cashTransactionsApi";
import { getVendorName } from "../../../utils/getVendorName";
import toast from "react-hot-toast";
import { TiTick, TiTimes } from "react-icons/ti";
import { hasPermission } from "../../../utils/hasPermission";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import CustomExport from "../../../components/CustomExport/CustomExport";
import { CircleLoaderComponent } from "../../../components/Loader/CircleLoaderComponent";

const CashTransferRequests = () => {
  const navigate = useNavigate();
  const location = useLocation();

  

  const [tableDataItems, setTableDataItems] = useState([]);

  const [myList, setMyList] = useState([])
  const [totalRows, setTotalRows] = useState(0);


  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType"));
  const [appUsers, setAppUsers] = useState(appUserTypes);
  const [userTypesArray, setUserTypesArray] = useState({
    name: "All",
    id: null,
  });
  const [userTypeId, setUserTypeId] = useState(userTypesArray.id);
  const initialRequest = {
    // user_type_id: userTypeId,
    limit: 10,
    offset: 0,
    mobile: "",
    startDate: "",
    endDate: currentDate,
  };
  const [request, setRequest] = useState(initialRequest);
  useMemo(() => {
    for (let index = 0; index < appUsers.length; index++) {
      const element = appUsers[index];
      if (
        element.user_type.toLocaleLowerCase() ===
        userTypesArray?.name?.toLocaleLowerCase()
      ) {
        setUserTypeId(element.user_type_id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          user_type_id: +element.user_type_id,
        }));
        break;
      }
    }
  }, [userTypesArray]);

  const clearFilters = () => {
    setRequest(initialRequest);
  };

  const [
    fetchAllTheCashTransferRequests,
    {
      data: fetchAllTheCashTransferRequestsData,
      error: fetchAllTheCashTransferRequestsError,
      isLoading : fetchAllTheCashTransferRequestsisLoading,
    },
  ] = useFetchAllTheCashTransferRequestsMutation();

  const [
    fetchAllTheCashTransferRequestsCSV,
    {
      data: fetchAllTheCashTransferRequestsCSVData,
      error: fetchAllTheCashTransferRequestsCSVError,
    },
  ] = useFetchAllTheCashTransferRequestsMutation();

  const [
    handleAcceptRequest,
    { data: handleAcceptRequestData, error: handleAcceptRequestError },
  ] = useHandleAcceptRequestMutation();

  const [
    handleRejectRequest,
    { data: handleRejectRequestData, error: handleRejectRequestError },
  ] = useHandleRejectRequestMutation();

  const hasViewPermission = hasPermission("viewTransactionRequestList");
  const hasAcceptPermission = hasPermission("acceptTransactionRequestList");
  const hasRejectPermission = hasPermission("rejectTransactionRequestList");

  const handleCashTransferList = (limit, offset) => {
    fetchAllTheCashTransferRequests({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      token: sessionStorage.getItem("access_token"),
      body:request
    });
  };

  const handleCashTransferListCSV = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchAllTheCashTransferRequestsCSV({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      token: sessionStorage.getItem("access_token"),
      body:modifiedRequest
    });
  };

  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if(fetchAllTheCashTransferRequestsCSVData && fetchAllTheCashTransferRequestsCSVData?.body?.data){
      setCsvData(fetchAllTheCashTransferRequestsCSVData?.body?.data);
    }

    if (fetchAllTheCashTransferRequestsCSVError) {
      setCsvData(null);
    }
  }, [fetchAllTheCashTransferRequestsCSVData , fetchAllTheCashTransferRequestsCSVError])


  useEffect(() => {
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    // if(limit!=10||offset!=0)
    handleCashTransferList(limit, offset);
  
  }, [limit, offset]);

  useEffect(() => {
    if (
      fetchAllTheCashTransferRequestsData &&
      fetchAllTheCashTransferRequestsData.body
    ) {
      toast.dismiss();
      toast.success(`${fetchAllTheCashTransferRequestsData.message}`, {
        id: "fetchAllTheCashTransferRequestsData",
      });
      console.log(
        "transactionsData  data",
        fetchAllTheCashTransferRequestsData
      );
      const arr = [...fetchAllTheCashTransferRequestsData.body.data];
      // arr.sort((a, b) => a.id - b.id);
      setTableDataItems(arr);

      setTotalRows( fetchAllTheCashTransferRequestsData &&
        fetchAllTheCashTransferRequestsData.body.count ? fetchAllTheCashTransferRequestsData.body.count  : 0 );

      
    }

    if (fetchAllTheCashTransferRequestsError) {
      console.log(
        "transactionsData error",
        fetchAllTheCashTransferRequestsError
      );
      toast.dismiss();
      toast.error(`${fetchAllTheCashTransferRequestsError.data.message}`, {
        id: "fetchAllTheCashTransferRequestsError",
      });
    }
  }, [
    fetchAllTheCashTransferRequestsData,
    fetchAllTheCashTransferRequestsError,
  ]);

  const handleApprove = (e, row) => {
    console.log("object");
    const remark = window.prompt("please add remarks");
    if (remark && remark.length > 0) {
      toast.error("Please add a remark");
      console.log("remark", remark);
      handleAcceptRequest({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        body: {
          remark: remark,
        },
        account_id: row.account_id.toString(),
        cash_transfer_to_bank_id: row.id.toString(),
      });
      return;
    }
  };

  useEffect(() => {
    if (handleAcceptRequestData) {
      toast.dismiss();
      toast.success(`${handleAcceptRequestData.message}`, {
        id: "handleAcceptRequestData",
      });
      handleCashTransferList(limit, offset);
      handleCashTransferListCSV()
    }

    if (handleAcceptRequestError) {
      toast.dismiss();
      toast.error(`${handleAcceptRequestError.data.message}`, {
        id: "handleAcceptRequestError",
      });
    }
  }, [handleAcceptRequestData, handleAcceptRequestError]);

  const handleReject = (e, row) => {
    const remark = window.prompt("please add remarks");
    if (remark && remark.length == 0) {
      toast.error("Please add a remark");
      return;
    }

    handleRejectRequest({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        remark: remark,
      },
      account_id: row.account_id.toString(),
      cash_transfer_to_bank_id: row.id.toString(),
    });
  };

  useEffect(() => {
    if (handleRejectRequestData) {
      toast.dismiss();
      toast.success(`${handleRejectRequestData.message}`, {
        id: "handleRejectRequestData",
      });
      handleCashTransferList(limit, offset);
    }

    if (handleRejectRequestError) {
      toast.dismiss();
      toast.error(`${handleRejectRequestError.data.message}`, {
        id: "handleAcceptRequestError",
      });
    }
  }, [handleRejectRequestData, handleRejectRequestError]);
  console.log("tableDataItems",tableDataItems);

  const columns = [
    {
      name: "S.No",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Mobile  ",
      selector: (row) => row.mobile,
    },
    {
      name: "User Type",
      selector: (row) => row.user_type,
    },
    {
      name: "Last Balance ",
      selector: (row) => row.balance,
    },
    {
      name: "Redemption Amount - TDS ",
      selector: (row) => row.cash,
    },
    {
      name: "Total Redemption Amount",
      selector: (row) => row.amount_without_tds,
    },
    {
      name: "Remarks  ",
      selector: (row) => row.remarks,
    },
    {
      name: "Transfer ID  ",
      selector: (row) => row.transfer_id,
    },
    {
      name: "State ",
      selector: (row) => row.state,
    },
    {
      name: "District ",
      selector: (row) => row.district,
    },
    {
      name: "city",
      selector: (row) => row.city,
    },
    {
      name: "Request Created At",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
      width: "170px",
    },
    {
      name: "Action Taken At",
      selector: (row) => row.action_date == null ? "" : new Date(row.action_date).toLocaleString(),
      sortable: true,
      width: "170px",
    },
    {
      name: "",
      cell: (r) => (
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => {
            setModalData(r);
            setShowModal(true);
          }}
        >
          View
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

    {
      name: "",
      cell: (row) => (
        <div>
          {row.approval_status == "3" && (
            <div className="flex space-x-1 ">
              {hasAcceptPermission && (
                <button
                  onClick={(e) => handleApprove(e, row)}
                  className="flex justify-center items-center rounded bg-success  px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#14a44d] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(20,164,77,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)]"
                >
                  <TiTick className="mr-1" />
                  Approve
                </button>
              )}
              {hasRejectPermission && (
                <button
                  onClick={(e) => handleReject(e, row)}
                  className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
                >
                  <TiTimes className="mr-1" />
                  Reject
                </button>
              )}
            </div>
          )}
          {row.approval_status == "2" && (
            <div className=" text-red-950 ">Rejected</div>
          )}
          {row.approval_status == "1" && (
            <div className=" text-green-600 ">Approved</div>
          )}
        </div>
      ),
      width: "250px",
    },
  ];
  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };



  const generateCSV = (data) => {
    if (data.body.data.length === 0) {
      return "";
    }
  
    const header = [
      "Id",
      "Name",
      "Mobile",
      "User Type",
     "Last Balance",
     "Transfer ID",
     "Redemption Amount",
     "Created At",
      "District",
      "State",
    ];
    const rows = data.body.data.map((item, index) => [
      index + 1,
      item.name,
      item.mobile,
      item.user_type,
      item.balance,
      item.transfer_id,
      item.cash,
      item.created_at,
      item.district,
      item.state,
    ]);
  
    const csvContent = [header, ...rows]
      .map((row) => row.map((cell) => `"${cell}"`).join(","))
      .join("\n");
  
    return `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
  };
  
  // useEffect(() => {
  //   if (fetchAllTheCashTransferRequestsCSVData?.body?.data?.length > 0) {
  //     const qrList = fetchAllTheCashTransferRequestsCSVData?.body?.data;
  //     const chunkSize = 1000000; // 10 lakh entries
  
  //     if (qrList.length <= chunkSize) {
  //       const csvDataUri = generateCSV(fetchAllTheCashTransferRequestsCSVData);
  //       downloadCSV(csvDataUri, "cash_transer.csv");
  //     } else {
  //       // Divide data into chunks
  //       for (let i = 0; i < qrList.length; i += chunkSize) {
  //         const chunk = qrList.slice(i, i + chunkSize);
  //         const chunkData = { body: { qrsList: chunk } };
  //         const csvDataUri = generateCSV(chunkData);
  //         downloadCSV(csvDataUri, `qr_data_chunk_${i / chunkSize + 1}.csv`);
  //       }
  //     }
  //   }
  // }, [fetchAllTheCashTransferRequestsCSVData]);
  
  const downloadCSV = (dataUri, fileName) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = dataUri;
    downloadLink.download = fileName;
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
  
    downloadLink.click();
  
    document.body.removeChild(downloadLink);
  };






  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Transaction Requests List
            <LinksComponent />
          </div>

          <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
          <div className="flex justify-between items-start">
          {/* <button
            className="btn btn-outline-primary clear-both float-right  mb-2"
            onClick={() => {
              handleCashTransferListCSV()
            }}
          >
            Export Csv
          </button> */}
          <div className="mb-2">
                  <CustomExport fetchData={handleCashTransferListCSV} data={csvData} defaultColumnExport={[
                  ]} 
                    filename="cash_transfer_request_list"
                    />
                </div>


            </div>
                <div className="flex  space-x-2 ">
                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        Mobile No
                      </label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="Mobile..."
                        required
                        value={request.mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, mobile: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="w-52 ">
                    <div className="flex-col slc_dvd topslt">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">User Types</label>
                    <div className="relative searchin">
                      {appUsers && appUsers.length > 0 && (
                        <SelectComponent
                          className="capitalize"
                          //title={"User Types"}
                          selected={userTypesArray}
                          setSelected={setUserTypesArray}
                          dataArr={appUsers}
                        />
                      )}</div>
                    </div>
                  </div> */}

                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        From Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="Start Date"
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              startDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 ">
                    <div className="relative searchin">
                      <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full datepd text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  inputp"
                        placeholder="End Date"
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              endDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      &nbsp;
                    </label>

                    <div className="flex flex-row  gap-2 ">
                      <div className="relative searchin">
                        <button
                          className="btn btn-outline-primary clear-both float-right  pdbtn_all"
                          onClick={() => {
                            handleCashTransferList();
                          
                          }}
                        >
                          Search
                        </button>
                      </div>
                      <div className="relative searchin">
                        <button
                          className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                          onClick={() => {
                            clearFilters();
                          }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

          <div className="p-3">
          {fetchAllTheCashTransferRequestsisLoading &&  <CircleLoaderComponent/>}
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                data={tableDataItems}
                totalRows={
                  fetchAllTheCashTransferRequestsData &&
                  fetchAllTheCashTransferRequestsData?.body?.count
                }
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
            </div>
          </div>
        </div>
        {showModal && modalData && (
          <ModalComponent
            size={"xl"}
            data={""}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full">
              <div className="m-2 ">
                <div className="w-full ">

                <div className="px-2.5 py-2 mt-2">
                 
                  <div className="grid grid-cols-2 mb-2 gap-2">
                    <div className="pl-2">
                  <h2 className="text-xl font-bold mb-2 text-red-600 border-bottom border-gray-400 pb-2">User Details</h2>

                      <p className="text-gray-600 mb-1">
                        User ID:{" "}
                        <span className="font-semibold">
                          {modalData.user_id}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-1">
                        Name:{" "}
                        <span className="font-semibold">{modalData.name}</span>
                      </p>
                      <p className="text-gray-600 mb-1">
                        Email:{" "}
                        <span className="font-semibold">{modalData.email}</span>
                      </p>
                      <p className="text-gray-600 mb-1">
                        Mobile:{" "}
                        <span className="font-semibold">
                          {modalData.mobile}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-1">
                        Gender:{" "}
                        <span className="font-semibold">
                          {modalData.gender}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-1">
                        Date of Birth:{" "}
                        <span className="font-semibold">
                          {modalData.dob ? new Date(modalData.dob).toDateString() : "N/A"}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-1">
                        Address:{" "}
                        <span className="font-semibold">{`${modalData.address1}, ${modalData.city}, ${modalData.state} ${modalData.pincode}`}</span>
                      </p>
                      <p className="text-gray-600 mb-1">
                        PAN:{" "}
                        <span className="font-semibold">{modalData.pan}</span>
                      </p>
                      <p className="text-gray-600 mb-1">
                        Aadhar:{" "}
                        <span className="font-semibold">
                          {modalData.aadhar}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-1">
                        GSTIN:{" "}
                        <span className="font-semibold">
                          {modalData.gstin || "N/A"}
                        </span>
                      </p>
                    </div>
                    <div>
                    <h2 className="text-xl font-bold mb-2 text-red-600 border-bottom border-gray-400 pb-2">Bank Details</h2>
                    <div className="pl-2">
                      <p className="text-gray-600 mb-1">
                        Beneficiary ID:{" "}
                        <span className="font-semibold">
                          {modalData.bene_id}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-1">
                        Beneficiary Name:{" "}
                        <span className="font-semibold">
                          {modalData.bene_name}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-1">
                        Beneficiary Mobile:{" "}
                        <span className="font-semibold">
                          {modalData.bene_mobile}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-1">
                        Beneficiary Branch:{" "}
                        <span className="font-semibold">
                          {modalData.bene_branch}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-1">
                        Transfer Mode:{" "}
                        <span className="font-semibold">
                          {modalData.transfer_mode}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-1">
                        Beneficiary Details:{" "}
                        <span className="font-semibold">
                          {/* {modalData.bene_details} */}
                          {modalData.transfer_mode == "upi" &&
                            modalData.bene_details.upi_id}
                          {modalData.transfer_mode == "banktransfer" &&
                            ("Bank Account :",
                            modalData.bene_details.bank_account)}
                          {modalData.transfer_mode == "banktransfer" &&
                            ("IFSC :", modalData.bene_details.ifsc)}
                        </span>
                      </p>
                    </div>
                    </div>
                  </div>
                </div>

               


                <div className="px-2.5 border-top border-gray-300 pt-2">
                  <h2 className="text-xl font-bold mb-2 text-red-600 border-bottom border-gray-400 pb-2">Wallet Details</h2>
                  <div className="mb-3 mt-3">
                  <div className="grid grid-cols-4 gap-4">

                      <p className="text-gray-600 mb-2 p-2 bg-gray-100 border-gray-300 text-center rounded-sm">
                        Point Earned {" "}
                        <span className="font-semibold">
                          {modalData?.point_earned}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-2 p-2 bg-gray-100 border-gray-300 text-center rounded-sm">
                      Point Balance{" "}
                        <span className="font-semibold">
                          {modalData?.point_balance}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-2 p-2 bg-gray-100 border-gray-300 text-center rounded-sm">
                      Point Redeemed{" "}
                        <span className="font-semibold">
                          {modalData?.point_redeemed}
                        </span>
                      </p>
                      <p className="text-gray-600 mb-2 p-2 bg-gray-100 border-gray-300 text-center rounded-sm">
                      Point Reserved{" "}
                        <span className="font-semibold">
                          {modalData?.point_reserved}
                        </span>
                      </p>
                    </div>
                    
                  </div>
                </div>
                </div>

              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </>
  );
};

export default CashTransferRequests;
