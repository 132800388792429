import { rootApi } from "../../api/rootApi";

export const productsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchProducts: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/products`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    countProducts: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/products/count`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    fetchProductClassificationList: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/products/classification-list`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchProductsCategoriesWithPercentage: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/products/category`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchProductsCategoriesWithTopPercentage: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/products/category/top`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchProductsCategoriesTotalMrpFromUserPointEntriesMonthWise: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/products/category/upe/total-mrp?zone_id=${params.zone_id}&state=${params.state}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),


    fetchProductsCategoriesTotalScanCountFromUserPointEntriesMonthWise: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/products/category/upe/total-count?zone_id=${params.zone_id}&state=${params.state}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchProductsClassificationTotalCountFromUserPointEntriesMonthWise: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/products/classification/upe/total-count?zone_id=${params.zone_id}&state=${params.state}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchProductsClassificationTotalMrpFromUserPointEntriesMonthWise: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/products/classification/upe/total-mrp?zone_id=${params.zone_id}&state=${params.state}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchProductsCodeTotalCountFromUserPointEntriesMonthWise: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/products/product-code/upe/total-count?zone_id=${params.zone_id}&state=${params.state}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchUserTypeTotalMrpSumFromUserPointEntriesMonthWise: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/products/user_type/upe/total-mrp?zone_id=${params.zone_id}&state=${params.state}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    fetchProductsByCategoryId: builder.mutation({
      query: (params) => {
        // console.log("fetchProductsByCategoryId", params);
        return {
          method: "GET",
          url: `/tenant/products/filters/${params.category_id}${
            params.limit
              ? `?limit=${params.limit}&&offset=${params.offset}`
              : ""
          }${params?.product_code ? `&&product_code=${params.product_code}` : ""}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    addProduct: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/products/add`,
          headers: {
            Authorization: "Bearer " + params.token,
            "Content-Type": "application/json",
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    bulkInsertProduct: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/products/bulk-insert`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),

    bulkUpdateProduct: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/products/bulk-update`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    bulkDeleteProduct: builder.mutation({
      query: (params) => {
        return {
          method: "DELETE",
          url: `/tenant/products/bulk-delete`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    updateProductByProductId: builder.mutation({
      query: (params) => {
        return {
          method: "PUT",
          url: `/tenant/products/update/${params.product_id}`,
          headers: {
            Authorization: "Bearer " + params.token,
            "Content-Type": "application/json",
            slug: params.tenant_id,
          },
          body: params.body,
        };
      },
    }),
    deletedProductById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/products/delete/${params.product_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),
    disableProductById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/products/disable/${params.product_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    changeProductPointStatusById: builder.mutation({
      query: (params) => {
        return {
          method: "GET",
          url: `/tenant/products/points/${params.product_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
        };
      },
    }),

    updateProductImages: builder.mutation({
      query: (params) => {
        return {
          method: "POST",
          url: `/tenant/products/update/images`,
          headers: {
            Authorization: "Bearer " + params.token,
            slug: params.tenant_id,
          },
          // body: params.body,
        };
      },
    }),
  }),
});

export const {
  useFetchProductsByCategoryIdMutation,
  useCountProductsMutation,
  useFetchProductsMutation,
  useAddProductMutation,
  useUpdateProductByProductIdMutation,
  useDeletedProductByIdMutation,
  useFetchProductsCategoriesWithPercentageMutation,
  useFetchProductsCategoriesWithTopPercentageMutation,
  useBulkInsertProductMutation,
  useBulkUpdateProductMutation,
  useBulkDeleteProductMutation,
  useDisableProductByIdMutation,
  useUpdateProductImagesMutation,
  useChangeProductPointStatusByIdMutation,
  useFetchProductsCategoriesTotalMrpFromUserPointEntriesMonthWiseMutation,
  useFetchProductsCategoriesTotalScanCountFromUserPointEntriesMonthWiseMutation,
  useFetchProductsClassificationTotalCountFromUserPointEntriesMonthWiseMutation,
  useFetchProductsCodeTotalCountFromUserPointEntriesMonthWiseMutation,
  useFetchUserTypeTotalMrpSumFromUserPointEntriesMonthWiseMutation,
  useFetchProductsClassificationTotalMrpFromUserPointEntriesMonthWiseMutation,
  useFetchProductClassificationListMutation
} = productsApi;
