import React, { useEffect, useState } from "react";
import { useFetchAppUserMutation, useUpdateAppUserDataMutation, useUpdateAppUserStatusMutation, useUpdateKycStatusMutation } from "../../../features/tenant/appUsers/appUsersApi";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useLocation, useNavigate } from "react-router-dom";

import copy from "../../../assests/tenant/images/copy.svg";

import view from "../../../assests/tenant/images/view.svg";
import adhar_vsvg from "../../../assests/tenant/images/adhar_vsvg.svg";
import adhar_v from "../../../assests/tenant/images/adhar_v.svg";
import hide from "../../../assests/tenant/images/hide.svg";
import imageSvg from "../../../assests/tenant/images/addhar.svg";
import greenCheck from "../../../assests/tenant/images/green-check.svg";

import { useGetBankDetailByAppUserIdMutation } from "../../../features/tenant/appUserBankDetails/appUserBankApi";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { useGetFormTemplateMutation } from "../../../features/tenant/formTemplate/formTemplateApi";

import { toast } from "react-hot-toast";
import { useGetLocationByPincodeMutation } from "../../../features/tenant/pincode/pincodeApi";
import { useUploadSingleFileMutation } from "../../../features/admin/imageUpload/imageUploadApi";
import { useUpdateProfileMutation } from "../../../features/tenant/appUserProfile/appProfileApi";
import { useGetMappingDetailsByAppUserIdMutation } from "../../../features/tenant/userMapping/userMappingApi";
import { getVendorName } from "../../../utils/getVendorName";
import ImageModal from "../../../components/Tenant/ImageModal/ImageModal";


const JqrUserProfile = () => {
  const location = useLocation();

  const [copied, setCopied] = useState(false);

  const [showAadhar, setShowAadhar] = useState(false);
  const [showPan, setShowPan] = useState(false);
  const [showGSTIN, setShowGSTIN] = useState(false);
  const [activeTab, setActiveTab] = useState("bank");

  const [showModal, setShowModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  const [showAadharImage, setShowAadharImage] = useState(false);
  const [showPanImage, setShowPanImage] = useState(false);
  const [showGstinImage, setShowGstinImage] = useState(false);

  const navigate = useNavigate();

  const [showKycDetails, setShowKycDetails] = useState(false);
  const [KycDetails, setKycDetails] = useState(null);


  const handleToggleKycDetails = (type, data) => {
    console.log("showKycDetails", showKycDetails);
    if (showKycDetails == false) {
      setKycDetails({ type, data });
    } else {
      setKycDetails(null);
    }
    setShowKycDetails(!showKycDetails);
  }

  const handleToggleAadhar = () => {
    setShowAadhar(!showAadhar);
  };

  const handleTogglePan = () => {
    setShowPan(!showPan);
  };

  const handleToggleGSTIN = () => {
    setShowGSTIN(!showGSTIN);
  };

  const handleCopyToClipboard = () => {
    const textToCopy = profileData?.referral_code;

    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy).then(() => {
        setCopied(true);

        // Reset copied status after 1.5 seconds
        setTimeout(() => {
          setCopied(false);
        }, 1500);
      });
    }
  };

  const [fetchAppUserProfile, { data: appUserData, error: appUserError }] =
    useFetchAppUserMutation();

  const [
    updateKycStatus,
    {
      data: updateKycStatusData,
      error: updateKycStatusError,
      isLoading: updateKycStatusIsLoading,
      isError: updateKycStatusIsError,
    },
  ] = useUpdateKycStatusMutation();


  const [
    fetchBankDetailByAppUserIdProfile,
    {
      data: fetchBankDetailByAppUserIdData,
      error: fetchBankDetailByAppUserIdError,
    },
  ] = useGetBankDetailByAppUserIdMutation();


  //Mapping Details
  const [
    getAppUserMappingDetails,
    { data: appUserMappingDetailsData, error: appUserMappingDetailsError },
  ] = useGetMappingDetailsByAppUserIdMutation();

  // console.log("userProfile ---------->", profileData);

  useEffect(() => {
    if (appUserMappingDetailsData) {
      // console.log("appUserMappingDetailsData", appUserMappingDetailsData);
    }

    if (appUserMappingDetailsError) {
      console.log("appUserMappingDetailsError", appUserMappingDetailsError);
    }
  }, [appUserMappingDetailsData, appUserMappingDetailsError]);
  // getAllWheelHistoryByUserId

  const updateKycStatusFunc = (type) => {
    if (!window.confirm(`Are you sure you want to update ${type.toUpperCase()} status to verified ?`)) {
      return;
    }

    toast.loading("Updating Kyc Status", { id: "loading-update-kyc-status" });

    const bodyData = { ...profileData };

    bodyData[`is_valid_${type}`] = true;
    bodyData[`${type}`] = profileData[type];
    bodyData["type"] = "tenant-manual-kyc";
    const tenantId = JSON.parse(sessionStorage.getItem("vendorData")).tenant_id;

    // console.log("updateKycStatus bodyData", bodyData);

    updateKycStatus({
      body: bodyData,
      tenant_id: tenantId,
      id: profileData.id,
    });
  }

  useEffect(() => {
    if (updateKycStatusData && updateKycStatusData.body) {
      toast.dismiss();
      toast.success("Kyc Status Updated", { id: "kycStatusSuccess" });
      // console.log("updateKycStatusData", updateKycStatusData.body);
      location.state = updateKycStatusData.body;
      setProfileData(updateKycStatusData.body);
    }

    if (updateKycStatusError) {
      console.log("updateKycStatusError", updateKycStatusError)
    }
  }, [updateKycStatusData, updateKycStatusError])




  const [profileData, setProfileData] = useState(null);
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const [editProfileData, setEditProfileData] = useState(profileData);

  useEffect(() => {
    setEditProfileData(profileData);
  }, [profileData]);

  useEffect(() => {
    const data = location.state;
    // console.log("location user datata", data);
    // setProfileData(data);
    if (profileData == null && data && data?.id) {
      // toast.loading("Fetching User Profile", { id: "loading-user-profile" })
      fetchAppUserProfile({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        type: "tenant",
        app_user_id: data?.id
      });
    }
  }, []);


  const fetchUserProfileFunc = (id) => {
    if (location.state) {
      // toast.loading("Fetching User Profile", { id: "loading-user-profile" })
      fetchAppUserProfile({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        type: "tenant",
        app_user_id: location.state?.id
      });
    }
  }

  const fetchUserBankDetailfun = () => {
    fetchBankDetailByAppUserIdProfile({
      id: profileData.id,
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  };


  const formatUTCDateToDDMMYY = (date) => {
    const utcDate = new Date(date);
    const day = utcDate.getUTCDate().toString().padStart(2, "0");
    const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = utcDate.getUTCFullYear().toString().slice(-2);

    return day + "-" + month + "-" + year;
  };



  useEffect(() => {
    if (profileData?.id) {
      fetchUserBankDetailfun();

      getAppUserMappingDetails({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id: profileData.id,
      });


      // });
    }
  }, [profileData]);


  useEffect(() => {
    if (appUserData && appUserData.body) {
      // toast.dismiss();
      // console.log("APP USER DATA", appUserData.body);
      toast.success("Profile Fetched", { id: "success-profile-fetch" })
      setProfileData(appUserData.body);

    }

    if (appUserError) {
      toast.dismiss();
      toast.error("Unable to fetch profile.", { id: "error-profile-unable-to-fetch" })
      console.log("APP USER ERROR", appUserError);
    }
  }, [appUserData, appUserError]);

  const [imagesData, setImagesData] = useState([]);
  const [
    uploadImages,
    {
      data: uploadImagesData,
      error: uploadImagesError,
      isError: uploadImagesIsError,
      isLoading: uploadImagesIsLoading,
    },
  ] = useUploadSingleFileMutation();

  useEffect(() => {
    if (uploadImagesData) {
      // console.log( "Upload Image data" ,uploadImagesData);
      const arr = [];
      // for (let i = 0; i < uploadImagesData.body.length; i++) {
      //   const element = uploadImagesData.body[i];
      //   arr.push(element.filename);
      // }
      console.log("success image upload", uploadImagesData.body);
      arr.push(uploadImagesData.body.fileLink);

      setImagesData(arr);
      const updatedData = { ...editProfileData };

      updatedData["profile_pic"] = arr[0];

      setEditProfileData(updatedData);
    }

    if (uploadImagesError) {
      console.log("Something went wrong while uploading image");
      toast.error("Something went wrong while uploading image", {
        id: "uploadImagesError",
      });
    }
  }, [uploadImagesData, uploadImagesError]);

  const [
    getFormTemplate,
    { data: formTemplateData, error: formTemplateError },
  ] = useGetFormTemplateMutation();

  const fetchFormTemplate = (userType) => {
    getFormTemplate({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      form_type: "6",
      user_type: userType,
    });
  };

  const [templateData, setTemplateData] = useState(null);

  const [formTemplateColumns, setFormTemplateColumns] = useState([]);

  useEffect(() => {
    if (
      formTemplateData &&
      formTemplateData.body &&
      formTemplateData.body.template && profileData
    ) {
      // console.log("formTemplateData", formTemplateData);
      const arr = Object.values(formTemplateData.body.template);
      const data = [];

      const columnsArr = [];

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].type.trim().toLowerCase() == "file") {
          const element = {
            ...arr[i],
            type: "file",
            value: "",
          };
          data.push(element);
        } else {
          const type = arr[i].name;
          const element = { ...arr[i], value: profileData[type] };
          console.log(element);
          data.push(element);
        }

        columnsArr.push(arr[i].name);
      }
      setFormTemplateColumns(columnsArr);
      setTemplateData(data);
    } else {
      console.log("Form Template Not Found");
      // toast.error("Form Template Not Found", { id: "formTemplateNotFound" });
      setTemplateData(null);
    }

    if (formTemplateError) {
      console.log("formTemplateError", formTemplateError);
    }
  }, [formTemplateData, formTemplateError]);

  // console.log("formTemplateColumns ", formTemplateColumns);

  const [
    getLocationByPincode,
    { data: GetLocationByPincodeData, error: GetLocationByPincodeError },
  ] = useGetLocationByPincodeMutation();

  useEffect(() => {
    if (GetLocationByPincodeData) {
      console.log("getLocationByPinc", GetLocationByPincodeData);
      toast.dismiss();
      toast.success(GetLocationByPincodeData.message, {
        id: "GetLocationByPincodeData",
      });

      if (GetLocationByPincodeData && GetLocationByPincodeData.body) {
        const updatedData = { ...editProfileData };

        updatedData["state"] = GetLocationByPincodeData.body[0].state;
        updatedData["district"] = GetLocationByPincodeData.body[0].district;

        setEditProfileData(updatedData);
      }
    }
    if (GetLocationByPincodeError) {
      toast.dismiss();
      toast.error(GetLocationByPincodeError.data.message, {
        id: "GetLocationByPincodeError",
      });
    }
  }, [GetLocationByPincodeData, GetLocationByPincodeError]);

  const handleFormTemplateValueChange = (type, value) => {
    // console.log("handleFormTemplateValueChange", profileData);

    const updatedData = { ...editProfileData };

    updatedData[type] = value;

    // console.log(updatedData);
    setEditProfileData(updatedData);
  };

  const [
    updateProfile,
    { data: updatedProfileData, error: updatedProfileError },
  ] = useUpdateProfileMutation();

  const updateUserProfile = (e) => {
    e.preventDefault();

    // const formData = new FormData();

    // for (const key in editProfileData) {
    //   if (editProfileData[key] !== null && editProfileData[key] !== "null") {
    //     formData.append(key, editProfileData[key]);
    //   }
    // }

    updateProfile({
      data: editProfileData,
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      type: "tenant",
    });
  };

  useEffect(() => {
    if (updatedProfileData && updatedProfileData.body) {
      toast.dismiss();
      toast.success("Profile Updated Sucessfully", {
        id: "updatedProfileData",
      });

      // console.log("updated profile", updatedProfileData);
      setShowModal(false);
      setProfileData(updatedProfileData.body);
    }

    if (updatedProfileError) {
      toast.error("Some error occured", { id: "updatedProfileError" });
      console.log("updatedProfileError", updatedProfileError);
    }
  }, [updatedProfileData, updatedProfileError]);

  const [mappingDetailsModal, setmappingDetailsModal] = useState(false);

  const [
    updateAppUserStatus,
    {
      data: updateAppUserStatusData,
      error: updateAppUserStatusError,
      isLoading: updateAppUserStatusIsLoading,
      isError: updateAppUserStatusIsError,
    },
  ] = useUpdateAppUserDataMutation();


  const handleStatusChange = (data, status) => {
    // console.log("data data ", data);
    updateAppUserStatus({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      type: "tenant",
      body: {
        id: Number(data.id),
        status: status,
      },
    });
  };


  useEffect(() => {
    // toast.dismiss();
    if (updateAppUserStatusData) {
      toast.success("Successfully updated status");
      fetchUserProfileFunc();
    }

    if (updateAppUserStatusError) {
      toast.error("Error Updating status", { id: "updateAppUserStatusError" })
      console.log("updateAppUserStatusError", updateAppUserStatusError)
    }
  }, [updateAppUserStatusData, updateAppUserStatusIsError, updateAppUserStatusError]);

  return (
    <>
      <div className="flex w-full bg-slate-200 h-full justify-center">
        <div className="w-full">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Profile
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className=" bg-white shadow rounded-lg">
              <div className="flex">
                <div className="cl_left w-40  border-r-2 border-r-gray-200">
                  <div className="imagbx p-3 text-center">
                    {profileData?.profile_pic ? (
                      <span className="inline-block bg-gray-100 border border-gray-300 rounded-full p-1">
                        <img
                          src={
                            profileData?.profile_pic
                              ? profileData?.profile_pic
                              : ""
                          }
                          onClick={() =>
                            setShowImageModal(
                              profileData?.profile_pic
                                ? profileData?.profile_pic
                                : ""
                            )
                          }
                          alt=""
                          className="w-32 h-32 rounded-full"
                        />
                      </span>
                    ) : (
                      <span className="inline-block bg-gray-100 border border-gray-300 rounded-full p-1">
                        <img
                          src="https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
                          alt=""
                          className="w-32 h-32 rounded-full"
                          onClick={() =>
                            setShowImageModal(
                              "https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
                            )
                          }
                        />
                      </span>
                    )}

                    <h1 className="font-bold text-xl capitalize">
                      {" "}
                      {profileData?.name}
                    </h1>
                    {/* {profileData?.mobile && (
                      <span className="flex text-sm text-gray-500 justify-center gap-1 mt-1 align-baseline">
                        {" "}
                        <img src={verified} alt="" />
                        Verified Account
                      </span>
                    )} */}

                    <span className="flex text-sm text-gray-500 justify-center gap-1 mt-1 align-baseline">
                      Joined :{" "}
                      {profileData?.created_at &&
                        profileData.created_at.length > 0 &&
                        profileData?.created_at.slice(0, 10)}
                    </span>
                    <span className="flex text-sm text-blue-700 justify-center gap-1 mt-1 align-baseline">
                      Referral Code&nbsp;&nbsp;:&nbsp;&nbsp;
                      {profileData?.referral_code}{" "}
                      <img
                        src={copy}
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={handleCopyToClipboard}
                      />
                      {copied && (
                        <span style={{ color: "green" }}>Copied!</span>
                      )}
                    </span>
                    <span className="flex text-sm text-black-700 justify-center gap-1 mt-1 align-baseline">
                      {profileData?.salesteam_name && (
                        <>
                          Dealer Name&nbsp;&nbsp;:&nbsp;&nbsp;
                          {profileData?.salesteam_name}
                        </>
                      )}
                    </span>
                    <span className="flex text-sm text-black-700 justify-center gap-1 mt-1 align-baseline">
                      {profileData?.profession && (
                        <>
                          Profession Name&nbsp;&nbsp;:&nbsp;&nbsp;
                          {profileData?.profession}
                        </>
                      )}
                    </span>

                    <div className="w-full flex justify-center relative group mt-2">
                      {profileData?.status == "1" ? (
                        <>
                          <button
                            onClick={() => {
                              handleStatusChange(profileData, "2");
                            }}
                            className="flex justify-center items-center rounded bg-green-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                          >
                            Active
                          </button>
                          <div className="absolute bottom-full mb-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-white bg-black p-2 rounded text-xs">
                            Click to Deactivate
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => {
                              handleStatusChange(profileData, "1");
                            }}
                            className="flex justify-center items-center rounded bg-gray-900 px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gray-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                          >
                            Inactive
                          </button>
                          <div className="absolute bottom-full mb-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-white bg-black p-2 rounded text-xs">
                            Click to Activate
                          </div>
                        </>
                      )}
                    </div>



                    <button
                      type="button"
                      className="bg-blue-500 hover:bg-blue-600 text-white text-xs font-medium uppercase leading-normal py-2 px-4 rounded-lg mt-2"
                      onClick={() => setmappingDetailsModal(true)}
                    >
                      {" "}
                      Mapping Details{" "}
                    </button>
                  </div>

                  <div className="kyc_bx border-t-2 border-gray-200 mt-2">
                    <div className="p-3">
                      <h2 className="font-bold text-base mb-2">KYC Info</h2>

                      <div className="flex justify-between items-start mb-2 space-x-2">
                        <div className="">
                          <span className="text-gray-400 text-sm  block">
                            Aadhar Number
                          </span>
                          <h5 className="flex text-black text-sm gap-1">
                            {showAadhar ? profileData?.aadhar : "*************"}
                          </h5>
                        </div>
                        <div className="flex justify-center items-center space-x-2">
                          <button
                            type="button"
                            className="bg-black p-1 rounded-sm"
                          >
                            {showKycDetails ? (
                              <img
                                src={adhar_v}
                                alt=""
                                onClick={() => handleToggleKycDetails("aadhar", profileData?.aadhar_details)}
                              />
                            ) : (
                              <img
                                src={adhar_vsvg}
                                alt=""
                                onClick={() => handleToggleKycDetails("aadhar", profileData?.aadhar_details)}
                              />
                            )}
                          </button>
                          <button
                            type="button"
                            className="bg-black p-1 rounded-sm"
                          >
                            {showAadhar ? (
                              <img
                                src={view}
                                alt=""
                                onClick={handleToggleAadhar}
                              />
                            ) : (
                              <img
                                src={hide}
                                alt=""
                                onClick={handleToggleAadhar}
                              />
                            )}
                          </button>


                          <button className="bg-black p-1 rounded-sm" type="button"> <img
                            src={imageSvg}
                            alt=""
                            onClick={() => setShowAadharImage(true)}
                            disabled={profileData?.aadhar_image && profileData?.aadhar_image?.length > 1 ? true : false}
                          /></button>


                          {
                            profileData?.is_valid_aadhar ?
                              <button className="cursor-none p-1 rounded-sm" type="button">
                                <img
                                  src={greenCheck}
                                  alt=""
                                /></button>

                              :
                              <button disabled={profileData?.aadhar && profileData.aadhar?.length > 0 ? false : true} className={`py-1.5 px-2 text-sm text-white rounded-sm bg-blue-500 disabled:bg-gray-400 ${profileData?.aadhar && profileData.aadhar?.length > 0 ? " " : " cursor-not-allowed"}`} type="button" onClick={() => updateKycStatusFunc("aadhar")}>
                                Update
                              </button>
                          }

                        </div>
                      </div>

                      {showAadharImage && (
                        <ImageModal
                          show={showAadharImage ? true : false}
                          setShow={setShowAadharImage}
                          size="md"
                          data={profileData?.aadhar_image}
                        >
                          {profileData?.aadhar_image?.length > 0 ?
                            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                              <img
                                src={profileData?.aadhar_image}
                                alt="kyc-aadhar-img"
                                className="h-full w-full object-cover object-center group-hover:opacity-75"
                              />
                            </div>

                            : <h2 className="text-center">
                              No Image
                            </h2>
                          }
                        </ImageModal>
                      )}

                      <div className="flex justify-between items-center mb-2">
                        <div className="">
                          <span className="text-gray-400 text-sm  block">
                            Pan Number
                          </span>
                          <h5 className="flex text-black text-sm  gap-1">
                            {showPan ? profileData?.pan : "*************"}
                          </h5>
                        </div>
                        <div className="flex justify-center items-center space-x-2">

                          <button
                            type="button"
                            className="bg-black p-1 rounded-sm"
                          >
                            {showKycDetails ? (
                              <img
                                src={adhar_v}
                                alt=""
                                onClick={() => handleToggleKycDetails("pan", profileData?.pan_details)}
                              />
                            ) : (
                              <img
                                src={adhar_vsvg}
                                alt=""
                                onClick={() => handleToggleKycDetails("pan", profileData?.pan_details)}
                              />
                            )}
                          </button>

                          <button
                            type="button"
                            className="bg-black p-1 rounded-sm"
                          >
                            {showPan ? (
                              <img
                                src={view}
                                alt=""
                                onClick={handleTogglePan}
                              />
                            ) : (
                              <img
                                src={hide}
                                alt=""
                                onClick={handleTogglePan}
                              />
                            )}
                          </button>

                          <button className="bg-black p-1 rounded-sm" type="button"> <img
                            src={imageSvg}
                            alt=""
                            onClick={() => setShowPanImage(true)}
                          /></button>

                          {
                            profileData?.is_valid_pan ?
                              <button className=" cursor-none p-1 rounded-sm" type="button"> <img
                                src={greenCheck}
                                alt=""
                              /></button>

                              :
                              <button disabled={profileData?.pan && profileData.pan?.length > 0 ? false : true} className={`py-1.5 px-2 text-sm text-white rounded-sm bg-blue-500 disabled:bg-gray-400 ${profileData?.pan && profileData.pan?.length > 0 ? " " : " cursor-not-allowed"}`} type="button" onClick={() => updateKycStatusFunc("pan")}>
                                Update
                              </button>
                          }
                        </div>
                      </div>

                      {showPanImage && (
                        <ImageModal
                          show={showPanImage ? true : false}
                          setShow={setShowPanImage}
                          size="md"
                          data={profileData?.pan_image}
                        >
                          {profileData?.pan_image?.length > 0 ?
                            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                              <img
                                src={profileData?.pan_image}
                                alt="kyc-pan-img"
                                className="h-full w-full object-cover object-center group-hover:opacity-75"
                              />
                            </div>
                            : <h2 className="text-center">
                              No Image
                            </h2>
                          }
                        </ImageModal>
                      )}

                      <div className="flex justify-between items-center">
                        <div className="">
                          <span className="text-gray-400 text-sm  block">
                            GSTIN
                          </span>
                          <h5 className="flex text-black text-sm  gap-1">
                            {showGSTIN ? profileData?.gstin : "*************"}
                          </h5>
                        </div>
                        <div className="flex justify-center items-center space-x-2">
                          <button
                            type="button"
                            className="bg-black p-1 rounded-sm"
                          >
                            {showKycDetails ? (
                              <img
                                src={adhar_v}
                                alt=""
                                onClick={() => handleToggleKycDetails("gstin", profileData?.gstin_details)}
                              />
                            ) : (
                              <img
                                src={adhar_vsvg}
                                alt=""
                                onClick={() => handleToggleKycDetails("gstin", profileData?.gstin_details)}
                              />
                            )}
                          </button>
                          <button
                            type="button"
                            className="bg-black p-1 rounded-sm"
                          >
                            {showGSTIN ? (
                              <img
                                src={view}
                                alt=""
                                onClick={handleToggleGSTIN}
                              />
                            ) : (
                              <img
                                src={hide}
                                alt=""
                                onClick={handleToggleGSTIN}
                              />
                            )}
                          </button>
                          <button className="bg-black p-1 rounded-sm" type="button"> <img
                            src={imageSvg}
                            alt=""
                            onClick={() => setShowGstinImage(true)}
                          /></button>


                          {
                            profileData?.is_valid_gstin ?
                              <button className=" cursor-none p-1 rounded-sm" type="button"> <img
                                src={greenCheck}
                                alt=""
                              /></button>

                              :
                              <button disabled={profileData?.gstin && profileData.gstin?.length > 0 ? false : true} className={`py-1.5 px-2 text-sm text-white rounded-sm bg-blue-500 disabled:bg-gray-400 ${profileData?.gstin && profileData.gstin?.length > 0 ? " " : " cursor-not-allowed"}`} type="button" onClick={() => updateKycStatusFunc("gstin")}>
                                Update
                              </button>
                          }
                        </div>
                      </div>

                      {showGstinImage && (
                        <ImageModal
                          show={showGstinImage ? true : false}
                          setShow={setShowGstinImage}
                          size="md"
                          data={profileData?.gstin_image}
                        >
                           {profileData?.gstin_image?.length > 0 ?
                          <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                            <img
                              src={profileData?.gstin_image}
                              alt="kyc-gstin-img"
                              className="h-full w-full object-cover object-center group-hover:opacity-75"
                            />
                          </div>
                           : <h2 className="text-center">
                           No Image
                         </h2>
                       }
                        </ImageModal>
                      )}


                    </div>
                  </div>
                </div>

                <div className="cl_right w-full ">
                  <div className="pl-3 pr-3  pt-3 flex justify-between items-center ">
                    <h2 className="font-bold text-base ">Basic Info</h2>
                    <div className="flex justify-center">
                      <button
                        type="submit"
                        className="text-white flex gap-1 items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center "
                        onClick={() => setShowModal(true)}
                      >
                        <svg
                          className="text-white"
                          width="15"
                          height="15"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          {" "}
                          <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />{" "}
                          <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                        </svg>{" "}
                        Edit
                      </button>
                    </div>
                  </div>

                  <div className="border-b border-gray-200">
                    <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-3">
                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          Email
                        </span>

                        <a
                          href={`mailto:${profileData?.email}`}
                          className="flex text-black text-sm  gap-1"
                        >
                          {profileData?.email}
                        </a>
                      </div>

                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          Mobile No
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          +91-{profileData?.mobile}
                        </h5>
                      </div>

                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          Gender / DOB
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.gender} /{" "}
                          {profileData?.dob
                            ? new Date(profileData?.dob).toLocaleDateString()
                            : ""}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="border-b border-gray-200">
                    <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-3">
                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          State
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.state}
                        </h5>
                      </div>


                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          District
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.district}
                        </h5>
                      </div>

                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          Pincode
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.pincode}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="border-b border-gray-200">
                    <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-3">
                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          Address 1
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.address1}
                        </h5>
                      </div>

                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          Address 2
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.address2}
                        </h5>
                      </div>

                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          City
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.city}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="border-b border-gray-200">
                    <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-3">
                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          FirmId / Firm Name
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.firm_id} / {profileData?.firm_name}
                        </h5>
                      </div>

                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          Dealer/Distributor Name
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.dealer_name}
                        </h5>
                      </div>

                      <div className="">
                        <span className="text-gray-400 text-sm  block">
                          Dealer/Distributor Mobile
                        </span>
                        <h5 className="flex text-black text-sm  gap-1">
                          {profileData?.dealer_mobile}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="grid divide-y divide-neutral-200 ">
                    <details className="group ">
                      <summary className="p-3 flex justify-between items-center font-medium cursor-pointer list-none">
                        <h2 className="font-bold text-base ">
                          Account Details{" "}
                        </h2>
                        <span className="transition group-open:rotate-180">
                          <svg
                            fill="none"
                            height="24"
                            shapeRendering="geometricPrecision"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            viewBox="0 0 24 24"
                            width="24"
                          >
                            <path d="M6 9l6 6 6-6"></path>
                          </svg>
                        </span>
                      </summary>

                      <div className="px-3">
                        <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                          <li className="mr-2">
                            <span
                              className={`inline-block px-4 py-2 rounded-t-lg ${activeTab === "bank"
                                ? "bg-blue-600 text-white"
                                : "hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"
                                }`}
                              onClick={() => setActiveTab("bank")}
                            >
                              BANK
                            </span>
                          </li>
                          <li className="mr-2">
                            <span
                              className={`inline-block px-4 py-2 rounded-t-lg ${activeTab === "upi"
                                ? "bg-blue-600 text-white"
                                : "hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"
                                }`}
                              onClick={() => setActiveTab("upi")}
                            >
                              UPI
                            </span>
                          </li>
                        </ul>
                        <div className="bankbox border border-gray-100 p-3 overflow-y-auto h-32">
                          {fetchBankDetailByAppUserIdData?.body?.length > 0 ? (
                            fetchBankDetailByAppUserIdData?.body.map((e) => {
                              if (
                                e.transfer_mode == "banktransfer" &&
                                activeTab === "bank"
                              ) {
                                return (
                                  <div className="flex items-center mb-2 border-b border-gray-200 pb-3">
                                    <input
                                      id="default-checkbox"
                                      type="checkbox"
                                      value=""
                                      checked={e.status == "1"}
                                      className="w-4 h-4 mt-0.5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <ul className="flex justify-between">
                                      <li className="border-r border-gray-400 pr-2 flex items-center">
                                        <label
                                          for="default-checkbox"
                                          className="ml-2 mb-0 text-sm font-medium text-gray-900 dark:text-gray-300"
                                        >
                                          Bank Name&nbsp;&nbsp;:&nbsp;&nbsp;
                                          {e?.bene_bank}
                                        </label>
                                      </li>
                                      <li className="border-r border-gray-400 pr-2 flex items-center">
                                        <label
                                          for="default-checkbox"
                                          className="ml-2 mb-0 text-sm font-medium text-gray-900 dark:text-gray-300"
                                        >
                                          A/C&nbsp;&nbsp;:&nbsp;&nbsp;
                                          {e?.bene_details?.bank_account}
                                        </label>
                                      </li>
                                      <li className="border-r border-gray-400 pr-2 flex items-center">
                                        <label
                                          for="default-checkbox"
                                          className="ml-2 mb-0 text-sm font-medium text-gray-900 dark:text-gray-300"
                                        >
                                          Branch&nbsp;&nbsp;:&nbsp;&nbsp;
                                          {e?.bene_branch}
                                        </label>
                                      </li>
                                      <li className="border-r border-gray-400 pr-2 flex items-center">
                                        <label
                                          for="default-checkbox"
                                          className="ml-2 mb-0 text-sm font-medium text-gray-900 dark:text-gray-300"
                                        >
                                          IFSC CODE&nbsp;&nbsp;:&nbsp;&nbsp;
                                          {e?.bene_details?.ifsc}
                                        </label>
                                      </li>
                                    </ul>
                                  </div>
                                );
                              } else if (
                                e.transfer_mode == "upi" &&
                                activeTab === "upi"
                              ) {
                                return (
                                  <div className="flex items-center mb-2 border-b border-gray-200 pb-3">
                                    <input
                                      id="default-checkbox"
                                      type="checkbox"
                                      value=""
                                      checked={e.status == "1"}
                                      className="w-4 h-4 mt-0.5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <ul className="flex justify-between">
                                      <li className="border-r border-gray-400 pr-2 flex items-center">
                                        <label
                                          for="default-checkbox"
                                          className="ml-2 mb-0 text-sm font-medium text-gray-900 dark:text-gray-300"
                                        >
                                          Upi Id&nbsp;&nbsp;:&nbsp;&nbsp;
                                          {e?.bene_details?.upi_id}
                                        </label>
                                      </li>
                                    </ul>
                                  </div>
                                );
                              }
                            })
                          ) : (
                            <div>No data Found</div>
                          )}
                        </div>
                      </div>

                      {/* {fetchBankDetailByAppUserIdData?.body?.length > 0 &&
                      fetchBankDetailByAppUserIdData?.body[0]?.transfer_mode ==
                        "banktransfer" ? (
                        <>




                          <div className="border-b border-gray-200">
                         


                            <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-2">
                              <div className="">
                                <span className="text-gray-400 text-sm  block">
                                  Bank Name
                                </span>
                                <h5 className="flex text-black text-sm  gap-1">
                                  {fetchBankDetailByAppUserIdData?.body
                                    ?.length > 0 &&
                                    fetchBankDetailByAppUserIdData?.body[0]
                                      ?.bene_bank}
                                </h5>
                              </div>

                              <div className="">
                                <span className="text-gray-400 text-sm  block">
                                  A/C Name
                                </span>
                                <h5 className="flex text-black text-sm  gap-1">
                                  {fetchBankDetailByAppUserIdData?.body
                                    ?.length > 0 &&
                                    fetchBankDetailByAppUserIdData?.body[0]
                                      ?.bene_name}
                                </h5>
                              </div>

                              <div className="">
                                <span className="text-gray-400 text-sm  block">
                                  A/C Number
                                </span>
                                <h5 className="flex text-black text-sm  gap-1">
                                  {fetchBankDetailByAppUserIdData?.body
                                    ?.length > 0 &&
                                    fetchBankDetailByAppUserIdData?.body[0]
                                      ?.bene_details?.bank_account}
                                </h5>
                              </div>
                            </div>
                          </div>

                          <div className="border-b border-gray-200">
                            <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-2">
                              <div className="">
                                <span className="text-gray-400 text-sm  block">
                                  Branch
                                </span>
                                <h5 className="flex text-black text-sm  gap-1">
                                  {fetchBankDetailByAppUserIdData?.body
                                    ?.length > 0 &&
                                    fetchBankDetailByAppUserIdData?.body[0]
                                      ?.bene_branch}
                                </h5>
                              </div>

                              <div className="">
                                <span className="text-gray-400 text-sm  block">
                                  IFSC Code
                                </span>
                                <h5 className="flex text-black text-sm  gap-1">
                                  {fetchBankDetailByAppUserIdData?.body
                                    ?.length > 0 &&
                                    fetchBankDetailByAppUserIdData?.body[0]
                                      ?.bene_details?.ifsc}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="border-b border-gray-200">
                          <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-2">
                            <div className="">
                              <span className="text-gray-400 text-sm  block">
                                UPI ID
                              </span>
                              <h5 className="flex text-black text-sm  gap-1">
                                {fetchBankDetailByAppUserIdData?.body?.length >
                                  0 &&
                                  fetchBankDetailByAppUserIdData?.body[0]
                                    ?.bene_details?.upi_id}
                              </h5>
                            </div>
                          </div>
                        </div>
                      )} */}
                    </details>
                    {profileData?.user_type == "retailer" ||
                      profileData?.user_type == "distributor" ? (
                      <>
                        {/* <details className="group ">
                        <summary className="p-3 flex justify-between items-center font-medium cursor-pointer list-none">
                          <h2 className="font-bold text-base ">
                            Business Info{" "}
                          </h2>
                          <span className="transition group-open:rotate-180">
                            <svg
                              fill="none"
                              height="24"
                              shapeRendering="geometricPrecision"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              viewBox="0 0 24 24"
                              width="24"
                            >
                              <path d="M6 9l6 6 6-6"></path>
                            </svg>
                          </span>
                        </summary>
                        <div className="border-b border-gray-200">
                          <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-2">
                            <div className="">
                              <span className="text-gray-400 text-sm  block">
                                Your Shop/Firm Name
                              </span>
                              <h5 className="flex text-black text-sm  gap-1">
                                Genefied
                              </h5>
                            </div>

                            <div className="">
                              <span className="text-gray-400 text-sm  block">
                                Business Type
                              </span>
                              <h5 className="flex text-black text-sm  gap-1">
                                Proprietorship
                              </h5>
                            </div>

                            <div className="">
                              <span className="text-gray-400 text-sm  block">
                                Distributor
                              </span>
                              <h5 className="flex text-black text-sm  gap-1">
                                Nikhil
                              </h5>
                            </div>
                          </div>
                        </div>
                      </details> */}
                      </>

                    ) : null}

                    {profileData?.user_type == "retailer" ||
                      profileData?.user_type == "distributor" ? (
                      <>
                        {/* <details className="group ">
                        <summary className="p-3 flex justify-between items-center font-medium cursor-pointer list-none">
                          <h2 className="font-bold text-base ">
                            Other Details{" "}
                          </h2>
                          <span className="transition group-open:rotate-180">
                            <svg
                              fill="none"
                              height="24"
                              shapeRendering="geometricPrecision"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              viewBox="0 0 24 24"
                              width="24"
                            >
                              <path d="M6 9l6 6 6-6"></path>
                            </svg>
                          </span>
                        </summary>
                        <div className="border-b border-gray-200">
                          <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-2">
                            <div className="">
                              <span className="text-gray-400 text-sm  block">
                                Highest Education
                              </span>
                              <h5 className="flex text-black text-sm  gap-1">
                                Graduate
                              </h5>
                            </div>

                            <div className="">
                              <span className="text-gray-400 text-sm  block">
                                Material Status
                              </span>
                              <h5 className="flex text-black text-sm  gap-1">
                                Married
                              </h5>
                            </div>

                            <div className="">
                              <span className="text-gray-400 text-sm  block">
                                No of kids
                              </span>
                              <h5 className="flex text-black text-sm  gap-1">
                                4
                              </h5>
                            </div>
                          </div>
                        </div>

                        <div className="border-b border-gray-200">
                          <div className="grid grid-cols-3 gap-2 pl-3 pr-3 pb-3 pt-2">
                            <div className="">
                              <span className="text-gray-400 text-sm  block">
                                Hobbies
                              </span>
                              <h5 className="flex text-black text-sm  gap-1">
                                Watch Cricket
                              </h5>
                            </div>
                          </div>
                        </div>
                      </details> */}
                      </>

                    ) : null}
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>

        {showImageModal && (
          <ImageModal
            show={showImageModal ? true : false}
            setShow={setShowImageModal}
            size="md"
            data={showImageModal}
          >
            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
              <img
                src={showImageModal}
                className="h-full w-full object-cover object-center group-hover:opacity-75"
              />
            </div>
          </ImageModal>
        )}
        {showModal && (
          <ModalComponent
            size={"2xl"}
            data={"nadnandand"}
            show={showModal}
            setShow={setShowModal}
          >
            <div className="w-full p-4">
              <h1 className="flex items-center gap-1 font-semibold text-base border-b pb-2 border-gray-200">
                <svg
                  className="h-6 w-6 text-black"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  {" "}
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />{" "}
                  <circle cx="12" cy="7" r="4" />
                </svg>
                Edit Profile
              </h1>

              <form className="w-full  mt-3">
                <div className="grid grid-cols-2 gap-4">
                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Name
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter Name"
                      value={editProfileData?.name}
                      onChange={(e) =>
                        handleFormTemplateValueChange("name", e.target.value)
                      }
                    />
                  </div>

                  <div className="w-full ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Mobile No
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter Mobile No"
                      value={editProfileData?.mobile}
                    />
                  </div>

                  <div className="w-full ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Email
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter Email"
                      value={editProfileData?.email}
                      onChange={(e) =>
                        handleFormTemplateValueChange("email", e.target.value)
                      }
                    />
                  </div>

                  <div className="w-full ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Gender
                    </label>
                    <select
                      className="shadow-sm bg-gray-50 border h-auto border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      onChange={(e) =>
                        handleFormTemplateValueChange("gender", e.target.value)
                      }
                    >
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                      <option value={"other"}>Other</option>
                    </select>
                  </div>

                  <div className="w-full ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      DOB
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="date"
                      placeholder="Enter DOB"
                      value={editProfileData?.dob}
                      onChange={(e) =>
                        handleFormTemplateValueChange("dob", e.target.value)
                      }
                    />
                  </div>

                  <div className="w-full ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Pincode
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter Pincode"
                      value={editProfileData?.pincode}
                      onChange={(e) => {
                        if (e.target.value.length == 6) {
                          console.log(e);
                          getLocationByPincode({
                            pincode: e.target.value,
                            token: sessionStorage.getItem("access_token"),
                            tenant_id: JSON.parse(
                              sessionStorage.getItem("vendorData")
                            ).tenant_id,
                          });
                        }
                        handleFormTemplateValueChange(
                          "pincode",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-4 mt-3">
                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      State
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter State"
                      value={editProfileData?.state}
                      onChange={(e) =>
                        handleFormTemplateValueChange("state", e.target.value)
                      }
                    />
                  </div>
                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      City
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter City"
                      value={editProfileData?.city}
                      onChange={(e) =>
                        handleFormTemplateValueChange("city", e.target.value)
                      }
                    />
                  </div>

                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      District
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter District"
                      value={editProfileData?.district}
                      onChange={(e) =>
                        handleFormTemplateValueChange(
                          "district",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-3">
                  <div className="w-full ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Address
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter Address"
                      value={editProfileData?.address1}
                      onChange={(e) =>
                        handleFormTemplateValueChange(
                          "address1",
                          e.target.value
                        )
                      }
                    />
                  </div>

                  <div className="w-full ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Address 2
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter Address"
                      value={editProfileData?.address2}
                      onChange={(e) =>
                        handleFormTemplateValueChange(
                          "address2",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      User Id
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter User ID"
                      value={editProfileData?.user_id}
                      onChange={(e) =>
                        handleFormTemplateValueChange("user_id", e.target.value)
                      }
                    />
                  </div>

                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Password
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter password"
                      value={editProfileData?.password}
                      onChange={(e) =>
                        handleFormTemplateValueChange(
                          "password",
                          e.target.value
                        )
                      }
                    />
                  </div>

                  {/* <div className="w-full ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Assoiate Dealer
                    </label>
                    <select className="shadow-sm bg-gray-50 border h-auto border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  ">
                      <option>Ajay Pumps (New Delhi)</option>
                      <option>Sanjay Pumps (Agra)</option>
                    </select>
                  </div>

                  <div className="w-full ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Linkd Employee
                    </label>
                    <select className="shadow-sm bg-gray-50 border h-auto border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  ">
                      <option>Vivek </option>
                      <option>Saabir</option>
                    </select>
                  </div> */}
                </div>

                <div className="grid grid-cols-3 gap-4 mt-3">
                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Firm Id
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter Firm ID"
                      value={editProfileData?.firm_id}
                      onChange={(e) =>
                        handleFormTemplateValueChange("firm_id", e.target.value)
                      }
                    />
                  </div>
                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      Firm Name
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter Firm Name"
                      value={editProfileData?.firm_name}
                      onChange={(e) =>
                        handleFormTemplateValueChange(
                          "firm_name",
                          e.target.value
                        )
                      }
                    />
                  </div>

                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      ASM
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter ASM"
                      value={editProfileData?.asm}
                      onChange={(e) =>
                        handleFormTemplateValueChange("asm", e.target.value)
                      }
                    />
                  </div>

                  <div className="w-full  ">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-first-name"
                    >
                      FSR
                    </label>
                    <input
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      type="text"
                      placeholder="Enter FSR"
                      value={editProfileData?.fsr}
                      onChange={(e) =>
                        handleFormTemplateValueChange("fsr", e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="w-full  mt-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-first-name"
                  >
                    User Profile
                  </label>
                  <input
                    className="shadow-sm bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2  "
                    type="file"
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        const uploadFile = new FormData();

                        uploadFile.append("image", e.target.files[0]);
                        uploadImages({
                          body: uploadFile,
                        });
                        // handleFormTemplateValueChange(
                        //   idx,
                        //   element.label,
                        //   e,
                        // );
                      }
                    }}
                  />

                  {imagesData.length > 0 &&
                    imagesData.map((img, index) => (
                      <div
                        key={index}
                        className="overflow-hidden rounded-lg mt-3 bg-gray-200 xl:aspect-h-8 xl:aspect-w-7 w-10 h-10"
                      >
                        <img
                          src={img}
                          alt=""
                          srcset=""
                          className="max-w-full max-h-full object-cover object-center"
                        />
                      </div>
                    ))}
                </div>
                <div className="grid grid-cols-3 gap-4 mt-3">
                  {formTemplateColumns.includes("pan") && (
                    <div className="w-full  ">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-first-name"
                      >
                        Pan
                      </label>
                      <input
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                        type="text"
                        placeholder="Enter Pan"
                        value={editProfileData?.pan}
                        onChange={(e) =>
                          handleFormTemplateValueChange("pan", e.target.value)
                        }
                      />
                    </div>
                  )}
                  {formTemplateColumns.includes("aadhar") && (
                    <div className="w-full  ">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-first-name"
                      >
                        Aadhar
                      </label>
                      <input
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                        type="text"
                        placeholder="Enter Aadhar"
                        value={editProfileData?.aadhar}
                        onChange={(e) =>
                          handleFormTemplateValueChange(
                            "aadhar",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  )}
                  {formTemplateColumns.includes("gstin") && (
                    <div className="w-full  ">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-first-name"
                      >
                        GSTIN
                      </label>
                      <input
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                        type="text"
                        placeholder="Enter GSTIN"
                        value={editProfileData?.gstin}
                        onChange={(e) =>
                          handleFormTemplateValueChange("gstin", e.target.value)
                        }
                      />
                    </div>
                  )}
                </div>

                <div className="grid grid-cols-3 gap-4 mt-3">
                  {formTemplateColumns.includes("fsr") && (
                    <div className="w-full  ">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-first-name"
                      >
                        FSR
                      </label>
                      <input
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                        type="text"
                        placeholder="Enter FSM"
                        value={editProfileData?.fsr}
                        onChange={(e) =>
                          handleFormTemplateValueChange("fsr", e.target.value)
                        }
                      />
                    </div>
                  )}
                  {formTemplateColumns.includes("asm") && (
                    <div className="w-full  ">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-first-name"
                      >
                        ASM
                      </label>
                      <input
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                        type="text"
                        placeholder="Enter ASM"
                        value={editProfileData?.asm}
                        onChange={(e) =>
                          handleFormTemplateValueChange("asm", e.target.value)
                        }
                      />
                    </div>
                  )}
                </div>
                {/* {templateData &&
                  templateData.map((element, idx) => {
                    return element.type === "select" ? (
                      <div className="col-md-12 my-3" key={idx}>
                        <SelectComponent
                          title={element.label}
                          selected={{ id: 0, name: `Select ${element.label}` }}
                          // setSelected={setselectedBrandName}
                          dataArr={element.options ? element.options : []}
                        />
                      </div>
                    ) : element.type === "file" ? (
                      <div className="w-full col-md-12 my-2 flex flex-col justify-center ">
                        <div className="flex flex-col items-start justify-center w-full">
                          {element.label} {element.required ? "*" : ""}
                          <label
                            for="dropzone-file"
                            className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                          >
                            <div className="flex flex-col items-center justify-center">
                              <svg
                                aria-hidden="true"
                                className="w-20 h-20 mb-3 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                ></path>
                              </svg>
                              <p className="mb-2 text-sm text-gray-500">
                                <span className="font-semibold">
                                  Click to upload
                                </span>
                                or drag and drop
                              </p>
                              <p className="text-xs text-gray-500">
                                PNG or JPG (800x400px)
                              </p>
                            </div>
                            <input
                              id="dropzone-file"
                              type="file"
                              accept="image/*"
                              className="hidden"
                              onChange={(e) => {
                                if (e.target.files.length > 0) {
                                  const uploadFile = new FormData();

                                  uploadFile.append(
                                    "images",
                                    e.target.files[0],
                                  );
                                  uploadImages({
                                    body: uploadFile,
                                  });
                                  // handleFormTemplateValueChange(
                                  //   idx,
                                  //   element.label,
                                  //   e,
                                  // );
                                }
                              }}
                            />
                          </label>
                        </div>

                        <div
                          className="d-flex justify-content-center align-items-center h-100"
                          style={{ width: "150px" }}
                        >
                          {imagesData.length > 0 &&
                            imagesData.map((img, index) => (
                              <div
                                key={index}
                                className="w-100 overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7 "
                              >
                                <img
                                  src={
                                    
                                    img
                                  }
                                  alt=""
                                  srcset=""
                                  className="h-full w-full object-cover object-center"
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    ) : element.name == "pincode" ? (
                      <div className="col-md-12 my-3" key={idx}>
                        <InputComponent
                          name={element.label}
                          type={element.type}
                          maxLength={element.maxLength}
                          placeholder={element.label}
                          required={element.required}
                          value={element.value}
                          setValue={(e) => {
                            handleFormTemplateValueChange(
                              idx,
                              element.label,
                              e,
                            );

                            if (e.length == 6) {
                              // console.log(e);
                              getLocationByPincode({
                                pincode: e,
                                token: sessionStorage.getItem("access_token"),
                                tenant_id: JSON.parse(
                                  sessionStorage.getItem("vendorData"),
                                ).tenant_id,
                              });
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div className="col-md-12 my-3" key={idx}>
                        <InputComponent
                          name={element.label}
                          type={element.type}
                          maxLength={element.maxLength}
                          placeholder={element.label}
                          required={element.required}
                          value={element.value}
                          setValue={(e) =>
                            handleFormTemplateValueChange(idx, element.label, e)
                          }
                        />
                      </div>
                    );
                  })} */}
                <div className="flex justify-center mt-4">
                  <button
                    type="submit"
                    className="text-white flex gap-1 items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center "
                    fdprocessedid="648ct2"
                    onClick={updateUserProfile}
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </ModalComponent>
        )}

        {mappingDetailsModal &&
          appUserMappingDetailsData &&
          appUserMappingDetailsData.body && (
            <ModalComponent
              size={"2xl"}
              data={"nadnandand"}
              show={mappingDetailsModal}
              setShow={setmappingDetailsModal}
            >
              <div className="p-4 flex gap-4">
                <div className="w-1/2">
                  <h2 className="text-xl font-semibold mb-2">Mapped Parent Data</h2>
                  <div className="overflow-y-auto max-h-96 p-2">
                    {appUserMappingDetailsData.body.parent.map((parentItem) => (
                      <div
                        key={parentItem.id}
                        className="bg-white rounded-lg  shadow-md border border-gray-300 mb-3 transition transform hover:scale-105 duration-300 ease-in-out"
                      >
                        <div className="bg-blue-700 rounded-t-lg px-3 py-2.5">
                          <p className="text-white font-semibold">
                            ID&nbsp;:&nbsp;{parentItem.app_user_id}
                          </p>
                          <p
                            className="underline text-white font-semibold"
                            onClick={() =>
                              navigate(
                                `/genefied/${getVendorName()}/user/${parentItem.user_type.toLowerCase()}`
                              )
                            }
                          >
                            User type&nbsp;:&nbsp;
                            {parentItem.user_type.toUpperCase()}
                          </p>
                        </div>
                        <div className="px-3 py-2.5">
                          <p> Name&nbsp;:&nbsp;{parentItem.app_user_name}</p>
                          <p>Mobile&nbsp;:&nbsp;{parentItem.app_user_mobile}</p>
                          <p>
                            Mapped On&nbsp;:&nbsp;{" "}
                            {new Date(parentItem.created_at).toLocaleString()}
                          </p>
                          <p>
                            Mapping Status&nbsp;:&nbsp;{" "}
                            {parentItem.status == 1 ? "Active" : "Inactive"}
                          </p>
                          <p>
                            User Status&nbsp;:&nbsp;{" "}
                            {parentItem.user_status == 1
                              ? "Active"
                              : "Inactive"}
                          </p>
                          {/* Add more fields here as needed */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w-1/2">
                  <h2 className="text-xl font-semibold mb-2">Mapped Child Data</h2>
                  <div className="overflow-y-auto max-h-96 p-2">
                    {appUserMappingDetailsData.body.child.map((childItem) => (
                      <div
                        key={childItem.id}
                        className="bg-white rounded-lg shadow-md border border-gray-800 mb-3 transition transform hover:scale-105 duration-300 ease-in-out"
                      >
                        <div className="bg-blue-700 rounded-t-lg px-3 py-2.5">
                          <p className="text-white font-semibold">
                            ID&nbsp;:&nbsp;{childItem.mapped_app_user_id}{" "}
                          </p>
                          <p
                            className="underline text-white font-semibold"
                            // `/genefied/${getVendorName()}/user/${childItem.mapped_user_type.toLowerCase()}/${childItem.mapped_app_user_id}`
                            onClick={() => {
                              navigate(
                                `../user/${childItem.mapped_user_type.toLowerCase()}`,
                                { replace: true }
                              );
                            }}
                          >
                            User Type&nbsp;:&nbsp;
                            {childItem.mapped_user_type.toUpperCase()}{" "}
                          </p>
                        </div>
                        {/* <Link> */}
                        <div className="px-3 py-2.5">
                          <p>
                            Name&nbsp;:&nbsp;{childItem.mapped_app_user_name}{" "}
                          </p>
                          {/* </Link> */}
                          <p>
                            Mobile&nbsp;:&nbsp;
                            {childItem.mapped_app_user_mobile}
                          </p>
                          <p>
                            Mapped On&nbsp;:&nbsp;{" "}
                            {new Date(childItem.created_at).toLocaleString()}
                          </p>
                          <p>
                            Mapping Status&nbsp;:&nbsp;{" "}
                            {childItem.status == 1 ? "Active" : "Inactive"}
                          </p>
                          <p>
                            User Status&nbsp;:&nbsp;{" "}
                            {childItem.user_status == 1 ? "Active" : "Inactive"}
                          </p>
                          {/* Add more fields here as needed */}
                        </div>{" "}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </ModalComponent>
          )}




        {showKycDetails &&
          KycDetails &&
          KycDetails?.type && (
            <ModalComponent
              size={"md"}
              data={"nadnandand"}
              show={showKycDetails}
              setShow={setShowKycDetails}
            >
              <div className="p-4 flex gap-4">
                <div className="w-full">
                  <h2 className="text-xl text-center underline font-semibold uppercase mb-2">{KycDetails && KycDetails.type + " Details"} </h2>
                  {KycDetails && KycDetails?.data && Object.keys(KycDetails?.data).length > 0 ?
                    <>
                      <>
                        {KycDetails.type == "pan" && <div>
                          <h2> Name : {KycDetails.data?.registered_name}</h2>
                        </div>}
                      </>

                      <>
                        {KycDetails.type == "aadhar" && <div>
                          <h2 className="my-1.5"> Name : {KycDetails.data?.name}</h2>
                          <h2 className="my-1.5"> Dob : {KycDetails.data?.dob}</h2>
                          <h2 className="my-1.5"> Gender : {KycDetails.data?.gender}</h2>
                          {/* <h2 className="my-1.5"> Image :  </h2> */}
                          {KycDetails.data?.photo_link && <img src={`data:image/png;base64,${KycDetails.data?.photo_link}`} />}
                        </div>}
                      </>

                      <>
                        {KycDetails.type == "gstin" && <div>
                          <h2 className="my-1.5"> Legal Business Name : {KycDetails.data?.legal_name_of_business}</h2>
                          <h2 className="my-1.5">  Registration Date : {KycDetails.data?.date_of_registration}</h2>
                          <h2 className="my-1.5">  Principal Address : {KycDetails.data?.principal_place_address}</h2>
                        </div>}
                      </>
                    </>


                    : "Not found"
                  }

                </div>

              </div>
            </ModalComponent>
          )}
      </div>
    </>
  );
};

export default JqrUserProfile;
