import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { useLocation, useNavigate } from "react-router-dom";
import SelectComponentWithSearch from "../../../components/Tenant/SelectComponentWIthSearch/SelectComponentWithSearch";
import MultiSelectComponent from "../../../components/Tenant/MultiSelectComponent/MultiSelectComponent";
import { AiTwotoneDelete } from "react-icons/ai";
import { useGetAppUserTypesMutation } from "../../../features/tenant/appUsers/appUsersApi";
import { toast } from "react-hot-toast";
import { getCurrentDate } from "../../../utils/getCurrentDate";
import { useCreateSalesBoosterMutation } from "../../../features/tenant/salesBooster/salesBoosterApi";
import { getVendorName } from "../../../utils/getVendorName";
import TriggerOn from "../../../components/Tenant/SalesBooster/TriggerOn";
import {
  useFetchHeirarchyMutation,
  useFetchSubcategoriesMutation,
} from "../../../features/tenant/productHeirarchy/productHeirarchyApi";
import { States } from "../../../utils/getStates";
import { useFetchProductsByCategoryIdMutation, useFetchProductClassificationListMutation, useFetchProductsMutation, useCountProductsMutation } from "../../../features/tenant/products/productsApi";
import { CircularProgress } from "@mui/material";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useUploadSingleFileMutation } from "../../../features/admin/imageUpload/imageUploadApi";
import { getEventDates } from "../../../utils/salesBoosterUtils";
import { egyptStates } from "../../../utils/ultimatrueStates";

function generateProductHeirarchyDropdownOptions(data) {
  const subcategoryMapping = {};
  data.forEach((item) => {
    if (item.is_sub === 1 && item.is_master === 0) {
      const parentCategoryName = data.find(
        (category) => category.id === item.master_id,
      )?.name;
      const parentCategoryId = data.find(
        (category) => category.id === item.master_id,
      )?.id;

      if (parentCategoryName) {
        if (!subcategoryMapping[parentCategoryName]) {
          subcategoryMapping[parentCategoryName] = [];
        }
        subcategoryMapping[parentCategoryName].push({
          name: item.name,
          id: item.id,
        });
      }
    }
  });

  let options = [];
  let i = 0;
  for (const parentCategory in subcategoryMapping) {
    if (subcategoryMapping.hasOwnProperty(parentCategory)) {
      const res = [];
      subcategoryMapping[parentCategory].forEach((subcategory) => {
        res.push({
          id: subcategory.id,
          name: `${subcategory.name} : ${parentCategory}`,
        });
      });

      res.forEach((element) => {
        options.push({ id: element.id, name: element.name });
        i++;
      });
    }
  }
  return options;
}

const allSalesBoosterTypes = [
  {
    id: 1,
    name: "All Products",
  },
  {
    id: 2,
    name: "Classification",
  },
  {
    id: 3,
    name: "Category-Subcategory",
  },
];

const allFreqTypes = [
  {
    id: 1,
    name: "daily",
  },
  {
    id: 2,
    name: "weekly",
  },
  {
    id: 3,
    name: "monthly",
  },
];

const CreateSalesBooster = () => {
  const [
    AddSalesBooster,
    {
      data: addSalesBoosterData,
      error: addSalesBoosterError,
      isLoading: addSalesBoosterisLoading,
    },
  ] = useCreateSalesBoosterMutation();

  const [
    fetchProductClassificationList,
    {
      data: productClassificationListData,
      error: productClassificationListError,
      isLoading: productClassificationListisLoading,
    },
  ] = useFetchProductClassificationListMutation();

  const navigate = useNavigate();
  const location = useLocation();


  const [allStates, setAllStates] = useState(JSON.parse(sessionStorage.getItem("vendorData")).tenant_name === "ultimatrue" ? egyptStates : [{ id: "ALL", name: "All" }, ...States]);
  const [selectedStates, setSelectedStates] = useState([]);

  const [runningDates, setRunningDates] = useState([]);

  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [images, setImages] = useState(null);
  const [pdfFile, setPdfFile] = useState(null)

  const [isUpdate, setIsUpdate] = useState(false);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const [name, setName] = useState();
  const [startDate, setStartDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState();
  const [applicableOn, setApplicableOn] = useState();

  const [triggerOns, setTriggerOns] = useState([]);

  const [salesBoosterType, setsalesBoosterType] = useState({
    id: -1,
    name: "Select Type",
  });

  const [salesBoosterFreq, setsalesBoosterFreq] = useState({
    id: -1,
    name: "Select Frequency",
  });



  useEffect(() => {
    if (selectedStates) {
      console.log("selectedStates", selectedStates);
      if (selectedStates.length > 0) {
        let found = false;
        selectedStates.forEach(state => {
          if (state.id == "ALL")
            found = true;
        })

        if(found){
          setSelectedStates(States);
        }
      }
    }
  }, [selectedStates])

  const [
    getAppUserTypes,
    {
      data: appUserTypesData,
      isLoading: appUserTypesIsLoading,
      error: appUserTypesIsError,
    },
  ] = useGetAppUserTypesMutation();

  useEffect(() => {
    toast.dismiss();
    //fetching all user types
    getAppUserTypes({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  const handleSubmit = (e) => {
    toast.loading();
    e.preventDefault();
    toast.dismiss();



    // return;
    const finalStates = [];
    selectedStates.forEach((each) => {
      finalStates.push(each.name);
    });

    if (!name) {
      toast.error("Name is required", {
        id: "nameError",
      });
      return;
    }

    if (!applicableOn || applicableOn.length <= 0) {
      toast.error("Select users", {
        id: "applicableOnError",
      });
      return;
    }

    if (!startDate || !endDate) {
      toast.error("Start Date and End Date are required", {
        id: "dateError",
      });
      return;
    }

    if (salesBoosterType.id == -1) {
      toast.error("Type is required", {
        id: "salesBoosterTypeError",
      });
      return;
    }


    const finalProducts = [];
    selectedProducts.forEach(
      ({ product_id, product_code, name, category_name, category_id }) => {
        finalProducts.push(product_code);
      },
    );

    // console.log("salesBoosterType", salesBoosterType);
    if (salesBoosterType.id != 1 && (!finalProducts || finalProducts.length <= 0)) {
      toast.error("Select Some Products", {
        id: "productsError",
      });
      return;
    }

    if (!finalStates || finalStates.length <= 0) {
      toast.error("Select States", {
        id: "categoriesError",
      });
      return;
    }

    // date error check
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (start > end) {
      toast.error("End date should be greater than Start date", {
        id: "dateError",
      });
      return;
    }

    // if(salesBoosterFreq.id == -1){
    //   toast.error("Select Frequency Type", {
    //     id: "salesBoosterFreq",
    //   });
    //   return;
    // }

    // if(salesBoosterFreq.id != 1 && runningDates.length == 0){
    //   toast.error("Invalid start and end date for frequency type", {
    //     id: "invalidDateSalesBoosterFreq",
    //   });
    //   return;
    // }

    const userTypeIds = applicableOn.map((user) => user.user_type_id);

    const data = {
      name: name,
      user_type: userTypeIds,
      start_date: startDate,
      end_date: endDate,
      products: finalProducts,
      states: finalStates,
      // freq_type : salesBoosterFreq.name ,
      // running_dates : runningDates,
      type: salesBoosterType.id,
      image: images,
      pdf: pdfFile

    };

    // console.log("Final Data", data);
    // return;
    AddSalesBooster({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: data,
    });
  };

  useEffect(() => {
    if (addSalesBoosterData) {
      toast.dismiss();
      console.log("add sales booster success ", addSalesBoosterData);
      toast.success("Added successfully ", { id: "addSalesBoosterSuccess" });
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/sales-booster`);
      }, 400);
    }

    if (addSalesBoosterError) {
      toast.dismiss();
      console.log("add sales booster error", addSalesBoosterError);
      toast.error(addSalesBoosterError?.data.message ? addSalesBoosterError.data?.message : "Something went wrong", {
        id: "addSalesBoosterError",
      });
    }
  }, [addSalesBoosterData, addSalesBoosterError]);

  const [selectedCategories, setSelectedCategories] = useState({
    id: -1,
    name: "Select Subcategory",
  });

  const [selectedClassification, setSelectedClassification] = useState({
    id: -1,
    name: "Select Classification",
  });

  const [
    fetchHeirarchy,
    {
      data: heirarchyData,
      isLoading: heirarchyIsLoading,
      isError: heirarchyIsError,
      error: heirarchyError,
    },
  ] = useFetchHeirarchyMutation();

  useEffect(() => {
    fetchHeirarchy({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });

    fetchProductClassificationList({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    })
  }, []);

  useEffect(() => {
    if (productClassificationListData && productClassificationListData.body) {
      console.log("productClassificationListData", productClassificationListData)
      const arr = [];

      productClassificationListData.body.forEach((each, idx) => {
        if (each.classification && each.classification?.length > 0) {
          arr.push({ id: idx, name: each.classification })
        }
      })
      // console.log("arr classification" , arr)
      setClassificationDropdownOptions(arr);
    }

    if (productClassificationListError) {
      console.log("productClassificationListError", productClassificationListError);
    }

  }, [productClassificationListData, productClassificationListError])


  const [dropdownOptions, setDropdownOptions] = useState([]);

  const [classificationDropdownOptions, setClassificationDropdownOptions] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (heirarchyData && heirarchyData.body) {
      const data = generateProductHeirarchyDropdownOptions(heirarchyData?.body);
      console.log("data", data)
      setDropdownOptions(data.length > 0 ? data : []);
    }
  }, [heirarchyData, heirarchyError]);

  const handleReset = () => {
    setName("");
    setApplicableOn([]);
    setSelectedStates([]);
    setSelectedCategories({
      id: -1,
      name: "Select Subcategory",
    });
    setStartDate(getCurrentDate());
    setEndDate("");
    setProductsArray([]);
    setSelectedProducts([]);
    setImages(null);
    setPdfFile(null);
  };

  const [
    fetchProducts,
    {
      data: productsData,
      isLoading: productsIsLoading,
      error: productsError,
    },
  ] = useFetchProductsMutation();

  const [
    countAllProducts,
    {
      data: countallProductsData,
      isLoading: countallProductsIsLoading,
      isError: countallProductsIsError,
      error: countallProductsError,
    },
  ] = useCountProductsMutation();

  const [
    fetchProductsByCategoryId,
    {
      data: productsByCategoryData,
      isLoading: productsByCategoryIsLoading,
      error: productsByCategoryError,
    },
  ] = useFetchProductsByCategoryIdMutation();

  const fetchProductsByClassification = (classification, product_code = "") => {

    setTotalPages(0);
    setTotalRows(0);
    setProductsArray([])
    const request = {
      limit: limit,
      offset: offset,
      classification: classification,
      status: "1",
      product_code: product_code
    }

    fetchProducts({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
    countAllProducts({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  const fetchProductsByCatId = (subCatId, product_code = "") => {
    setTotalPages(0);
    setTotalRows(0);
    setProductsArray([])
    const obj = {
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      category_id: Number(subCatId),
      limit: limit,
      offset: offset,
      product_code: product_code
    }
    fetchProductsByCategoryId(obj);
  };

  useEffect(() => {
    // console.log(
    //   "productsByCategoryData",
    //   productsByCategoryData?.body?.products,
    // );
    if (
      productsByCategoryData &&
      productsByCategoryData.body &&
      productsByCategoryData.body.products
    ) {
      setTotalRows(
        productsByCategoryData.body.products.length
          ? Number(productsByCategoryData.body.products.length)
          : 0,
      );
      setTotalPages(
        Math.ceil(productsByCategoryData.body.total_products / limit),
      );
      setProductsArray(productsByCategoryData.body.products);
    }
    if (productsByCategoryError) {
      console.log("productsByCategoryError", productsByCategoryError);
    }
  }, [productsByCategoryData, productsByCategoryError]);

  useEffect(() => {
    // console.log("selectedCategories", selectedCategories, offset);
    if (selectedCategories.id != -1) {

      if (searchQuery == "") {
        fetchProductsByCatId(selectedCategories.id);
      }

    }
  }, [selectedCategories, offset, searchQuery]);

  useEffect(() => {
    // console.log("selectedClassification", selectedClassification, offset);
    if (selectedClassification.id != -1) {
      if (searchQuery == "") {
        fetchProductsByClassification(selectedClassification.name);
      }
    }
  }, [selectedClassification, offset, searchQuery])

  useEffect(() => {
    setTotalPages(0);
    setTotalRows(0);
    setOffset(0);
    setCurrentPage(0);
    setProductsArray([])
    setSelectedProducts([]);
    setSelectedCategories({
      id: -1,
      name: "Select Subcategory",
    });
    setSelectedClassification({
      id: -1,
      name: "Select Classification",
    });

  }, [salesBoosterType])


  useEffect(() => {
    if (productsData && productsData.body && productsData.body) {
      // console.log("productsData.body.products", productsData.body.products);
      setTotalRows(
        productsData.body.products.length
          ? Number(productsData.body.products.length)
          : 0,
      );

      setProductsArray(productsData.body.products);
    }
  }, [productsData, productsError])

  useEffect(() => {

    if (countallProductsData && countallProductsData.body?.total_products) {
      setTotalPages(
        Math.ceil(countallProductsData.body.total_products / limit),
      );
    }

    if (countallProductsError) {
      toast.dismiss();
      toast.error("Something went wrong", { id: "countallproductsError" })
    }
  }, [countallProductsData, countallProductsError])


  // useEffect(() => {
  //   toast.dismiss();
  //   if (salesBoosterFreq.id != -1 && salesBoosterFreq.id != 1) {
  //     // console.log("salesBoosterFreq", salesBoosterFreq);
  //     const dates = getEventDates(startDate, endDate, salesBoosterFreq?.name);
  //     if(!dates || dates.length == 0){
  //       toast.error(`Invalid Start and End dates for ${salesBoosterFreq.name} frequnecy`, {id : "invalidDateFreqType"});
  //     }
  //     setRunningDates(dates);
  //     // console.log("dates", dates)
  //   }else {
  //     setRunningDates([]);
  //   }
  // }, [salesBoosterFreq, startDate, endDate])



  const [productsArray, setProductsArray] = useState([]);

  const handleCheckboxChange = (
    product_id,
    name,
    product_code,
    category_id,
    category_name,
  ) => {
    const index = selectedProducts.findIndex(
      (product) => product.product_id === product_id,
    );
    if (index === -1) {
      setSelectedProducts([
        ...selectedProducts,
        { product_id, name, product_code, category_id, category_name },
      ]);
    } else {
      setSelectedProducts(
        selectedProducts.filter((product) => product.product_id !== product_id),
      );
    }
  };

  const handleRemoveSelected = () => {
    const updatedProducts = selectedProducts.filter(
      (product) => !product.isSelected,
    );
    setSelectedProducts(updatedProducts);
  };

  const handleCheckboxToggle = (product_id) => {
    const updatedProducts = selectedProducts.map((product) =>
      product.product_id === product_id
        ? { ...product, isSelected: !product.isSelected }
        : product,
    );
    setSelectedProducts(updatedProducts);
  };

  const [selectedProducts, setSelectedProducts] = useState([]);

  const nextPageFunc = (page) => {
    const newOffset = limit * page;
    setCurrentPage(page);
    setOffset(newOffset);
  };

  const prevPageFunc = (page) => {
    const newOffset = limit * page;
    setCurrentPage(page);
    setOffset(newOffset);
  };

  const handleSearchClick = () => {

    console.log("search clicked", searchQuery);

    if (searchQuery?.length <= 2) {
      toast.error("Enter 3 characters or more to search", { id: "searchQuerySalesBosster" });
      return;
    }

    if (selectedCategories.id != -1) {
      fetchProductsByCatId(selectedCategories.id, searchQuery);
    } else if (selectedClassification.id != -1) {
      fetchProductsByClassification(selectedClassification.name, searchQuery);
    }
  }

  // console.log("total page", totalPages, currentPage, limit, offset);

  const [
    uploadImage,
    {
      data: uploadImageData,
      isLoading: uploadImageIsLoading,
      error: uploadImageError,
    },
  ] = useUploadSingleFileMutation();

  const [
    uploadPdfFile,
    {
      data: uploadPdfFileData,
      isLoading: uploadPdfFileIsLoading,
      error: uploadPdfFileError,
    },
  ] = useUploadSingleFileMutation();

  const previewImage = (event) => {
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    uploadImage({
      body: formData,
    });
  };

  useEffect(() => {
    toast.dismiss();
    if (uploadImageData) {
      console.log("success image upload", uploadImageData.body);
      setImages(uploadImageData.body.fileLink);
    }
    if (uploadImageError) {
      toast.error("Image upload failed");
    }

    if (uploadImageIsLoading) {
      toast.loading("Uploading your image...", { id: "uploadImageLoading" });
    }
  }, [uploadImageData, uploadImageError, uploadImageIsLoading]);


  const previewPdf = (event) => {
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    uploadPdfFile({
      body: formData,
    });
  };

  useEffect(() => {
    toast.dismiss();
    if (uploadPdfFileData) {
      console.log("success file upload", uploadPdfFileData.body);
      setPdfFile(uploadPdfFileData.body.fileLink);
    }
    if (uploadPdfFileError) {
      toast.error("Image upload failed");
    }

    if (uploadPdfFileIsLoading) {
      toast.loading("Uploading your file...", { id: "uploadImageLoading" });
    }
  }, [uploadPdfFileData, uploadPdfFileError, uploadPdfFileIsLoading]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {isUpdate ? "Update " : "Add "} Sales Booster
            <LinksComponent />
          </div>
          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <div className=" flex flex-col justify-center align-middle">
                <div className="">
                  <div className="flex-col justify-center ">
                    <div className="flex w-full space-x-6">
                      <div className="mb-6 w-1/2">
                        <InputComponent
                          name={"Name"}
                          placeholder={"Enter name..."}
                          type={"text"}
                          value={name}
                          setValue={setName}
                          required={true}
                        />
                      </div>
                      <div className="mb-6 w-1/2">
                        <MultiSelectComponent
                          title={"User Types Applicable On"}
                          placeholder={"Select User Types applicable on..."}
                          selected={applicableOn}
                          setSelected={setApplicableOn}
                          dataArr={
                            appUserTypesData ? appUserTypesData.body : []
                          }
                        />
                      </div>

                      <div className="mb-6 w-1/2">
                        <MultiSelectComponent
                          title={"Select States"}
                          placeholder={"Select States applicable on..."}
                          selected={selectedStates}
                          setSelected={setSelectedStates}
                          dataArr={allStates ? allStates : []}
                        />
                      </div>
                    </div>
                    <div className="flex w-full space-x-6">
                      <div className="mb-6 w-1/4">
                        <InputComponent
                          name={"Start Date"}
                          type={"date"}
                          value={startDate}
                          setValue={setStartDate}
                          required={true}
                        />
                      </div>
                      <div className="mb-6 w-1/4">
                        <InputComponent
                          name={"End Date"}
                          type={"date"}
                          value={endDate}
                          setValue={setEndDate}
                          required={true}
                        />
                      </div>
                      {/* <div className="mb-6 w-1/4">
                        <SelectComponentWithSearch
                          title={"Select Frequency"}
                          placeholder={"Select Sales booster Freq..."}
                          selected={salesBoosterFreq}
                          setSelected={setsalesBoosterFreq}
                          dataArr={allFreqTypes}
                        />
                      </div> */}


                    </div>

                    {/* <div className="flex justify-start items-center space-x-2 w-full pb-3 overflow-y-scroll max-w-2xl " >

                      {
                        runningDates.map((each) => <span className="bg-blue-300 min-w-24 text-center rounded-sm p-1" key={each}>
                          {each}
                        </span>)
                      }

                    </div> */}
                  </div>

                  <div className="flex w-full justify-evenly items-center space-x-6">

                    <div className="w-1/2">
                      <div className="w-full flex justify-center ">
                        <div className="flex items-center justify-center w-full">
                          <label
                            for="dropzone-file"
                            className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                          >
                            <div className="flex flex-col items-center justify-center ">
                              <svg
                                aria-hidden="true"
                                className="w-40 h-40 mb-3 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  strokeLinejoin="round"
                                  stroke-width="2"
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                ></path>
                              </svg>
                              <p className="mb-2 text-sm text-gray-500">
                                <span className="font-semibold block">
                                  Upload Image
                                </span>{" "}
                              </p>
                              <p className="text-xs text-gray-500">PNG or JPG</p>
                            </div>
                            <input
                              id="dropzone-file"
                              type="file"
                              accept="image/*"
                              className="hidden"
                              onChange={(e) => previewImage(e)}
                            />
                          </label>
                        </div>
                      </div>

                      {images && (
                        <img
                          src={images}
                          alt=""
                          srcset=""
                          className="flex items-center justify-center h-16 w-16 mt-2 mb-2 border border-gray-300 p-2 m-auto rounded-md"
                        />
                      )}
                    </div>

                    <div className="w-1/2">
                      <div className="w-full flex justify-center ">
                        <div className="flex items-center justify-center w-full">
                          <label
                            for="dropzone-pdf-file"
                            className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100  "
                          >
                            <div className="flex flex-col items-center justify-center ">
                              <svg
                                aria-hidden="true"
                                className="w-40 h-40 mb-3 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  strokeLinejoin="round"
                                  stroke-width="2"
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                ></path>
                              </svg>
                              <p className="mb-2 text-sm text-gray-500">
                                <span className="font-semibold block">
                                  Upload File
                                </span>{" "}
                              </p>
                              <p className="text-xs text-gray-500">PDF</p>
                            </div>
                            <input
                              id="dropzone-pdf-file"
                              type="file"
                              accept=".pdf"
                              className="hidden"
                              onChange={(e) => previewPdf(e)}
                            />
                          </label>
                        </div>
                      </div>

                      {pdfFile && (
                        <img
                          src={pdfFile}
                          alt=""
                          srcset=""
                          className="flex items-center justify-center h-16 w-16 mt-2 mb-2 border border-gray-300 p-2 m-auto rounded-md"
                        />
                      )}
                    </div>



                  </div>



                  <div className="flex-col justify-center items-center w-full">

                    <div className="flex justify-start items-center w-full space-x-6" >


                      <div className="mb-6 w-1/4">
                        <SelectComponentWithSearch
                          title={"Select Type"}
                          placeholder={"Select Sales booster type..."}
                          selected={salesBoosterType}
                          setSelected={setsalesBoosterType}
                          dataArr={allSalesBoosterTypes}
                        />
                      </div>

                      {salesBoosterType.id == 3 ? (
                        <div className="mb-6 max-w-xs">
                          <SelectComponentWithSearch
                            title={"Select Sub Categories"}
                            placeholder={"Select Sub Categories applicable on..."}
                            selected={selectedCategories}
                            setSelected={setSelectedCategories}
                            dataArr={dropdownOptions}
                          />
                        </div>
                      ) : salesBoosterType.id == 2 ? (
                        <div className="mb-6 max-w-xs">
                          <SelectComponentWithSearch
                            title={"Select Classification"}
                            placeholder={"Select classification applicable on..."}
                            selected={selectedClassification}
                            setSelected={setSelectedClassification}
                            dataArr={classificationDropdownOptions}
                          />
                        </div>
                      ) : (
                        <></>
                      )}

                    </div>

                    <div className="w-full flex space-x-3">
                      {(salesBoosterType.id === 2 ||
                        salesBoosterType.id === 3) && (
                          <>
                            <div className="w-1/2 py-2 px-3 border rounded-md">
                              <h3 className="block text-base font-medium capitalize leading-6 py-2 text-gray-900">
                                Products available
                                {/* in{" "} */}
                                {/* {selectedCategories.id != -1 &&
                                selectedCategories.name}
                               {selectedClassification.id != -1 &&
                                selectedClassification.name}   */}
                              </h3>
                              {productsByCategoryIsLoading ? (
                                <CircularProgress />
                              ) : (

                                <>
                                  <div action="">
                                    <div className="flex justify-center items-center space-x-3 w-full" >
                                      <input
                                        type="text"
                                        placeholder="Enter product code ..."
                                        value={searchQuery}
                                        onChange={handleSearchInputChange}

                                        className="mb-2 px-3 w-full py-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-blue-500"
                                      />

                                      <button
                                        className="btn btn-primary align-middle py-2.5 w-28 mb-2 h-full "
                                        onClick={() => handleSearchClick()}
                                      >
                                        Search
                                      </button>

                                    </div>
                                  </div>

                                  <div>


                                    {productsArray.map((product) => (
                                      <div
                                        key={product.product_id}
                                        className="flex items-center px-2 justify-start"
                                      >
                                        <input
                                          type="checkbox"
                                          id={`product-${product.product_id}`}
                                          checked={selectedProducts.some(
                                            (selectedProduct) =>
                                              selectedProduct.product_id ===
                                              product.product_id,
                                          )}
                                          onChange={() =>
                                            handleCheckboxChange(
                                              product.product_id,
                                              product.name,
                                              product.product_code,
                                              product.category_id,
                                              product.category_name,
                                            )
                                          }
                                          className="mr-2"
                                        />
                                        <label
                                          className="mt-1 font-medium"
                                          htmlFor={`product-${product.product_id}`}
                                        >
                                          {product.name} : {product.product_code}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )}

                              <div className="flex justify-center items-center mt-4 w-1/2 p-2">
                                <div className="w-1/2 flex justify-center">
                                  {currentPage - 1 >= 0 && totalPages != 1 && (
                                    <button
                                      className="btn btn-secondary"
                                      onClick={() =>
                                        prevPageFunc(currentPage - 1)
                                      }
                                    >
                                      <MdKeyboardDoubleArrowLeft />
                                    </button>
                                  )}
                                </div>

                                <div className="w-full text-sm text-center font-semibold">
                                  {totalPages != 0 && ` Page ${currentPage + 1} of ${totalPages}`}
                                </div>
                                <div className="w-1/2 flex justify-center">
                                  {currentPage + 1 < totalPages &&
                                    totalPages != 1 && (
                                      <button
                                        className="btn btn-secondary"
                                        onClick={() =>
                                          nextPageFunc(currentPage + 1)
                                        }
                                      >
                                        <MdKeyboardDoubleArrowRight />
                                      </button>
                                    )}
                                </div>


                              </div>
                            </div>

                            <div className="w-1/2 py-2 px-3 border rounded-md">
                              <h3 className="block text-base font-medium capitalize leading-6 text-gray-900">
                                Selected Products
                              </h3>
                              <ul className="mt-2">
                                {selectedProducts.map((product) => (
                                  <li
                                    key={product.product_id}
                                    className="flex items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      checked={product.isSelected}
                                      onChange={() =>
                                        handleCheckboxToggle(product.product_id)
                                      }
                                      className="mr-2"
                                    />
                                    <span>
                                      {product.name} - {product.product_code}
                                    </span>
                                  </li>
                                ))}
                              </ul>
                              <button
                                className="mt-2 bg-red-500 hover:bg-red-600 text-white font-semibold text-base py-1 px-3 rounded"
                                onClick={handleRemoveSelected}
                              >
                                Remove Selected
                              </button>
                            </div>
                          </>
                        )}
                    </div>


                  </div>

                  <div>

                  </div>
                  <div className="flex justify-center mt-3">
                    <div className="space-x-5">
                      <button
                        type="submit"
                        onClick={(e) => handleSubmit(e)}
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                      >
                        Save
                      </button>
                      <button
                        type="reset"
                        onClick={(e) => handleReset(e)}
                        className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-2 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSalesBooster;
