import React, { useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import { useAddSmsCredentialForTenantMutation, useUpdateSmsCredentialForTenantMutation } from "../../../features/tenant/smsCredential/smsCredentialApi";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

const providerArray = [
  {
    id: 1,
    name: "orbital",
    form: {
      name: ["user_id", "password", "entity_id"],
      view_name: ["User Id", "Password", "Entity Id"],
    },
  },
  {
    id: 2,
    name: "smslane",
    form: {
      name: ["api_key", "entity_id"],
      view_name: ["Api Key", "Entity Id"],
    },
  },
  {
    id: 3,
    name: "juro",
    form: {
      name: ["token", "entity_id"],
      view_name: ["Token", "Entity Id"],
    },
  },
  {
    id: 3,
    name: "epush",
    form: {
      name: ["user_id", "password", "entity_id"],
      view_name: ["User Id", "Password", "API KEY"],
    },
  },
  {
    id: 4,
    name: "textlocal",
    form: {
      name: ["api_key"],
      view_name: ["API KEY",],
    },
  },
];

const AddSMSCredential = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isUpdate, setIsUpdate] = useState(
    location?.state?.edit ? true : false
  );
  console.log("location ", location?.state);

  const [selectCredType, setSelectCredType] = useState(
    isUpdate ? Number(location.state.form.id) - 1 : 0
  );
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (isUpdate) {
      let temp = {};
      // console.log("location",location);
      location &&
        location?.state?.form.form.name.map((x) => {
          temp[x] = location.state.data.api_credential[x];
        });
      temp["entity_id"] = location.state.data.entity_id;
      console.log("temp", temp);

      setFormData({ ...temp });
    }
  }, [location]);

  const [
    addSmsCredentialForTenant,
    {
      data: addSmsCredentialForTenantData,
      isError: addSmsCredentialForTenantIsError,
      error: addSmsCredentialForTenantError,
      isLoading: addSmsCredentialForTenantIsLoading,
    },
  ] = useAddSmsCredentialForTenantMutation();

  const [
    updateSmsCredentialForTenant,
    {
      data: updateSmsCredentialForTenantData,
      isError: updateSmsCredentialForTenantIsError,
      error: updateSmsCredentialForTenantError,
      isLoading: updateSmsCredentialForTenantIsLoading,
    },
  ] = useUpdateSmsCredentialForTenantMutation();


  const handleAddSmsCredential = (e) => {
    e.preventDefault();
    let allFieldAdded = false;

    for (const [key, value] of Object.entries(formData)) {
      console.log(key, value);
      if (value === null || value === "") {
        allFieldAdded = true;
      }
    }
    if (
      allFieldAdded ||
      Object.keys(formData).length <
        providerArray[selectCredType].form.name.length
    ) {
      toast.error("Please Enter field ", {
        id: "field error ",
      });
      return;
    }

    let apiCredential = {};

    for (const [key, value] of Object.entries(formData)) {
      if (key != "entity_id") apiCredential[key] = value;
    }
    console.log(formData["entity_id"]);

    addSmsCredentialForTenant({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        api_credential: apiCredential,
        entity_id: formData["entity_id"],
        type: providerArray[selectCredType].name,
      },
    });
  };

  if (addSmsCredentialForTenantIsError) {
    toast.dismiss();
    toast.error("Some Error Occured", {
      id: "addSmsCredentialIsError",
    });
  }

  if (addSmsCredentialForTenantIsLoading) {
    toast.dismiss();
    toast.loading("Loading...", {
      id: "addSmsCredentialIsLoading",
    });
  }

  useEffect(() => {
    if (addSmsCredentialForTenantData) {
      toast.dismiss();
      toast.success(`${addSmsCredentialForTenantData.message}`, {
        id: `getSmsCredentialWithLimitOffsetData`,
      });
      navigate(-1);
    }
    if (addSmsCredentialForTenantError) {
      toast.dismiss();
      toast.success(`${addSmsCredentialForTenantError.data.message}`, {
        id: `addSmsCredentialForTenantError`,
      });
    }
  }, [addSmsCredentialForTenantData, addSmsCredentialForTenantError]);

  const handleUpdate=(e)=>{
    e.preventDefault();
    let allFieldAdded = false;

    for (const [key, value] of Object.entries(formData)) {
      console.log(key, value);
      if (value === null || value === "") {
        allFieldAdded = true;
      }
    }
    if (
      allFieldAdded ||
      Object.keys(formData).length <
        providerArray[selectCredType].form.name.length
    ) {
      toast.error("Please Enter field ", {
        id: "field error ",
      });
      return;
    }

    let apiCredential = {};

    for (const [key, value] of Object.entries(formData)) {
      if (key != "entity_id") apiCredential[key] = value;
    }

    console.log("--->",providerArray[selectCredType].name);


    updateSmsCredentialForTenant({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id : location.state.data.id,
        body: {
          api_credential: apiCredential,
          entity_id: formData["entity_id"],
          type: providerArray[selectCredType].name,
        },
    })
  }

  useEffect(() => {
    if (updateSmsCredentialForTenantData) {
      toast.dismiss();
      toast.success(`${updateSmsCredentialForTenantData.message}`, {
        id: `updateSmsCredentialForTenantData`,
      });
      navigate(-1);
    }
    if (updateSmsCredentialForTenantError) {
      toast.dismiss();
      toast.success(`${updateSmsCredentialForTenantError.data.message}`, {
        id: `updateSmsCredentialForTenantError`,
      });
    }
  }, [updateSmsCredentialForTenantData, updateSmsCredentialForTenantError]);


  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {isUpdate ? "Update " : "Add "}
            SMS Credential
            <LinksComponent />
          </div>
          <div className="p-3 ">
            <form className="p-3 bg-white shadow rounded-lg">
              <div className="w-full flex justify-center space-x-3 ">
                <div className="mb-6 w-1/4">
                  <div className="w-full">
                    <label className="block text-sm font-medium leading-6 text-gray-900 mb-0">&nbsp;</label>
                  <select
                    type="text"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm h-auto rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                    placeholder="Add new UserType"
                    value={selectCredType}
                    onChange={(e) => setSelectCredType(Number(e.target.value))}
                  >
                    {providerArray &&
                      providerArray.map((x, index) => {
                        if (isUpdate) {
                          if (x.id == Number(selectCredType) + 1) {
                            return (
                              <option key={x.id} value={Number(index)}>
                                {x.name}
                              </option>
                            );
                          }
                          return;
                        } else {
                          return (
                            <option key={x.id} value={Number(index)}>
                              {x.name}
                            </option>
                          );
                        }
                      })}
                  </select>
                </div></div>

                {providerArray &&
                  providerArray.length > 0 &&
                  providerArray[Number(selectCredType)]?.form?.name.map(
                    (x, index) => {
                      return (
                        <>
                          <div key={index} className="mb-6 w-1/4">
                            <div className="w-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900 mb-0" for="name">
                                {" "}
                                {
                                  providerArray[selectCredType]?.form.view_name[
                                    index
                                  ]
                                }{" "}
                              </label>
                              <input
                                name={x}
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                value={
                                  Object.keys(formData).length > 0
                                    ? formData[x]
                                    : ""
                                }
                                onChange={(e) => {
                                  let data = {};
                                  data[e.target.name] = e.target.value;
                                  setFormData({ ...formData, ...data });
                                }}
                                type="text"
                              />
                            </div>
                          </div>{" "}
                        </>
                      );
                    }
                  )}

               
              </div>
              <div className="flex justify-center mt-2">
                  {isUpdate ? (
                    <button
                      type="update"
                      onClick={(e) => handleUpdate(e)}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      type="submit"
                      onClick={handleAddSmsCredential}
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    >
                      Add
                    </button>
                  )}
                </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSMSCredential;
