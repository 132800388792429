import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import CSVReader from "../../../components/ImportCsv/CSVReader";
import { useUploadGullakPointsMutation } from "../../../features/tenant/ModenikApi/modenikApi";
import { downloadCSV } from "../../../utils/downloadCSV";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { getVendorName } from "../../../utils/getVendorName";

const monthsArr = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
    { id: 12, name: "Q1" },
    { id: 13, name: "Q2" },
    { id: 14, name: "Q3" },
    { id: 15, name: "Q4" },
];
const yearArr = [
    { id: 23, name: "2023-24" },
    { id: 24, name: "2024-25" },
    { id: 25, name: "2025-26" },
];


function validateAndCleanObjectsForCustomer(objects) {
    const requiredKeysSet = new Set(
        [
            "SAP Code",
            "DKG Money"
        ]
    );

    function isEmptyObject(obj) {
        return Array.from(requiredKeysSet).every((key) => !obj[key]);
    }

    const cleanedObjects = [];

    for (const object of objects) {
        const newObject = {};

        if (isEmptyObject(object)) {
            continue;
        }

        let valid = true;
        for (let key in object) {
            key = key.trim();

            if (requiredKeysSet.has(key)) {
                if (!object[key]) {
                    valid = false;
                    break;
                } else {
                    newObject[key] = object[key];
                }
            }
        }

        newObject.status = valid ? 1 : 2;

        cleanedObjects.push(newObject);
    }

    if (cleanedObjects.some((obj) => obj.status === 2)) {
        return {
            isValid: false,
            data: cleanedObjects,
        };
    }

    return {
        isValid: true,
        data: cleanedObjects,
    };
}


const GullakPointsUpload = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [canSubmit, setCanSubmit] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [fileName, setFileName] = useState(null);

    const [schemeName, setSchemeName] = useState("");

    const [selectedMonth, setSelectedMonth] = useState({ id: -1, name: "Select Month" });
    const [selectedYear, setSelectedYear] = useState({ id: -1, name: "Select Year" });

    const [
        uploadGullakPoints,
        { data: gullakPointsData, error: gullakPointsError, isLoading: gullakPointsIsLoading },
    ] = useUploadGullakPointsMutation();

    const handleShowConfimationToast = (e) => {
        e.preventDefault();

        if (selectedMonth.id == -1) {
            toast.error('Month not selected.', {
                id: 'noMonth&year',
            });
            return;
        }

        if (selectedYear.id == -1) {
            toast.error('Year not selected.', {
                id: 'noMonth&year',
            });
            return;
        }

        if (finalArray.length === 0) {
            toast.dismiss();
            toast.error('User type and Uploaded data mismatch', {
                id: 'mismatchCsv&UserType',
            });
            return;
        }


        if (window.confirm(`Are you sure you want to add ${finalArray.length} entries for Scheme ${schemeName} ${selectedMonth.name} ${selectedYear.name} `)) {
            handleOnSubmit(e);
        } else {
            toast.dismiss();
            return;
        }
    };


    const handleOnSubmit = (e) => {
        e.preventDefault();
        uploadGullakPoints({
            token: sessionStorage.getItem("access_token"),
            tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
            body: {
                rows: finalArray,
                qty: finalArray.length,
                user_type_id: 3,
                user_type: 'distributor',
                method_id: "3",
                method: "Upload Points",
                platform_id: "3",
                platform: "Panel",
            },
        });
    };

    useEffect(() => {
        if (gullakPointsData) {
            toast.dismiss();
            toast.success("Saved Successfully", {
                id: "gullakPointsData",
            });
            console.log("gullakPointsData", gullakPointsData);


            if (gullakPointsData.body && gullakPointsData.body.users_not_found && gullakPointsData.body.users_not_found.length > 0) {
                console.log("users_not_found", gullakPointsData.body.users_not_found);
                downloadCSV(gullakPointsData.body.users_not_found, "UsersNotMapped.csv");
            }

            setTimeout(() => {
                navigate(`/genefied/${getVendorName()}/gullak-points`);
            }, 400);
        }

        if (gullakPointsError) {
            console.log("gullakPointsError", gullakPointsError);
            toast.dismiss();
            if (gullakPointsError.status == 400) {
                toast.error(
                    `Bad Request : ${gullakPointsError.data.message}`,
                );
            } else {
                toast.error(
                    `Something went wrong`,
                );
            }
        }
    }, [gullakPointsData, gullakPointsError]);

    const [CSVData, setCSVData] = useState(null);
    const [finalArray, setFinalArray] = useState([]);


    useEffect(() => {
        if (selectedMonth.id != -1 && selectedYear.id != -1 && CSVData && CSVData.parsed) {

            const res = validateAndCleanObjectsForCustomer(CSVData?.parsed);
            const mappedArray = res.data.map((obj) => ({
                user_id: obj["SAP Code"],
                points: parseFloat(obj["DKG Money"]),
                month: selectedMonth.name,
                year: selectedYear.name,
                scheme_name: schemeName,
            }));


            if (res.isValid) {
                setCanSubmit(true);
                setFinalArray(mappedArray);
            }
        }
    }, [CSVData, selectedMonth , selectedYear, schemeName]);



    return (
        <>
            <div className="flex w-full bg-gray-200 h-full justify-center">
                <div className="w-full ">
                    <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
                        Upload Gullak Points
                        <LinksComponent />
                    </div>

                    <div className="mx-9 mt-6">

                        <div className="flex space-x-3">

                            <div className="flex mb-1">
                                <button
                                    type="submit"
                                    className="mb-2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 text-center "
                                    onClick={() => downloadCSV(sampleData, "sampleUploadGullakPoints.csv")}
                                >
                                    Sample Upload Csv
                                </button>
                            </div>
                        </div>

                        <form className="bg-white shadow-md rounded-lg flex w-full p-6 justify-center max-w-full mx-auto">
                            <div className="w-full flex flex-col justify-center items-center">
                                <div className="w-full max-w-xl flex flex-col items-center space-y-6">


                                    <div className="w-full flex justify-between space-x-4">

                                        <div className="w-1/2">
                                            <InputComponent
                                                name="Enter Scheme Name"
                                                type="text"
                                                value={schemeName}
                                                setValue={setSchemeName}
                                                required={false}
                                                className="border border-gray-300 rounded-lg px-3 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            />
                                        </div>



                                        <div className="w-1/3">
                                            {yearArr && yearArr.length > 0 && (
                                                <div className=" w-full">
                                                    <SelectComponent
                                                        title={"Year"}
                                                        selected={selectedYear}
                                                        setSelected={setSelectedYear}
                                                        dataArr={yearArr}
                                                        placeholder={"Select Year"}
                                                        required={true}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        <div className="w-1/3">
                                            {monthsArr && monthsArr.length > 0 && (
                                                <div className=" w-full">
                                                    <SelectComponent
                                                        title={"Month"}
                                                        selected={selectedMonth}
                                                        setSelected={setSelectedMonth}
                                                        dataArr={monthsArr}
                                                        placeholder={"Select Month"}
                                                        required={true}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="w-full flex flex-col items-center">
                                        <label className="block text-gray-600 text-sm mb-2">
                                            Upload Excel/CSV File:
                                        </label>
                                        <CSVReader setCSVData={setCSVData} setFileName={setFileName} />
                                    </div>

                                    <div className="flex justify-center space-x-4 mt-6">
                                        {canSubmit && (
                                            <>
                                                <button
                                                    type="button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowModal(true);
                                                    }}
                                                    className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-6 py-2.5"
                                                >
                                                    View CSV File
                                                </button>


                                                {
                                                    gullakPointsIsLoading ? (
                                                        <button
                                                            className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-6 py-2.5 disabled:opacity-50 flex items-center justify-center"
                                                            disabled
                                                        >
                                                            <svg
                                                                className="animate-spin h-6 w-6 text-white"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="currentColor"
                                                            >
                                                                <circle
                                                                    cx="12"
                                                                    cy="12"
                                                                    r="10"
                                                                    stroke="currentColor"
                                                                    strokeWidth="4"
                                                                    fill="none"
                                                                    strokeLinecap="round"
                                                                />
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M4 12a8 8 0 018-8v16a8 8 0 01-8-8z"
                                                                />
                                                            </svg>
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="submit"
                                                            onClick={(e) => handleShowConfimationToast(e)}
                                                            disabled={!canSubmit}
                                                            className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-6 py-2.5 disabled:opacity-50"
                                                        >
                                                            Save
                                                        </button>
                                                    )
                                                }

                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>

                    {showModal && finalArray && (
                        <ModalComponent
                            size={"5xl"}
                            data={""}
                            show={showModal}
                            setShow={setShowModal}
                        >
                            <div className="w-full rounded-lg">
                                <div className=" w-full border-opacity-40 rounded-lg p-4 mt-2">
                                    <div className="overflow-x-auto">
                                        <table className="min-w-full bg-white border border-gray-300">
                                            <thead>
                                                <tr>
                                                    {(finalArray.length > 0
                                                        ? Object.keys(finalArray[0])
                                                        : []
                                                    ).map((column, index) => (
                                                        <th key={index} className="py-2 px-4 border-b uppercase">
                                                            {column}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {finalArray.slice(0, 30).map((item, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {(finalArray.length > 0
                                                            ? Object.keys(finalArray[0])
                                                            : []
                                                        ).map((column, colIndex) => (
                                                            <td key={colIndex} className="py-2 px-4 border-b">
                                                                {item[column]}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}

                                                <h2 className="mt-2 ml-auto">
                                                    {" "}
                                                    {finalArray.length > 30
                                                        ? `${finalArray.length - 30} rows more`
                                                        : ` `}
                                                </h2>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </ModalComponent>
                    )}
                </div>
            </div>
        </>
    );
};

export default GullakPointsUpload;


const sampleData = [
    {
        "SAP Code": 1005478,
        "DKG Money": 1000
    },
    {
        "SAP Code": 1005386,
        "DKG Money": 1000
    },
    {
        "SAP Code": 1005181,
        "DKG Money": 1000
    },
    {
        "SAP Code": 1005283,
        "DKG Money": 877
    },
    {
        "SAP Code": 1005473,
        "DKG Money": 23424
    },
    {
        "SAP Code": 1005451,
        "DKG Money": 131313
    },
    {
        "SAP Code": 1005378,
        "DKG Money": 1335
    },
    {
        "SAP Code": 1005471,
        "DKG Money": 352389
    },
    {
        "SAP Code": 1005464,
        "DKG Money": 21412487
    },
    {
        "SAP Code": 1005286,
        "DKG Money": 87325
    },
    {
        "SAP Code": 1005285,
        "DKG Money": 239261
    },
    {
        "SAP Code": 1005364,
        "DKG Money": 3648209
    },
    {
        "SAP Code": 1005280,
        "DKG Money": 99999
    }
]