import React, { Fragment, useEffect, useState } from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { getVendorName } from "../../../utils/getVendorName";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi";
import { hasPermission } from "../../../utils/hasPermission";
import { toast } from "react-hot-toast";
import { BsCheckCircleFill, BsCircle } from "react-icons/bs";
import { HiEye } from "react-icons/hi2";
import {
  useRevertOrderPointsMutation,
  useModenikGetAllOrdersMutation,
} from "../../../features/tenant/ModenikApi/modenikApi";

const AllOrderList = () => {
  const initialRequest = {
    limit: 10,
    offset: 0,
    user_id: "",
    mobile: "",
    invoice_id: "",
    date: "",
    startDate: "",
    endDate: "",
    created_at: "",
  };

  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  const [myList, setMyList] = useState([])
  const [totalRows, setTotalRows] = useState(0);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Invoice Id",
      selector: (row) => row.invoice_id,
      sortable: true,
    },
    {
      name: "Type",
      cell: (row) => row.bill_type,
      sortable: true,
      // width: "120px",
    },
    {
      name: "User Type",
      cell: (row) => row.user_type,
      sortable: true,
      // width: "120px",
    },
    {
      name: "User ID",
      cell: (row) => row.user_id,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Mobile",
      cell: (row) => row.mobile,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Style",
      cell: (row) => row.style,
      sortable: true,
      width: "120px",
    },
    {
      name: "Subcategory",
      cell: (row) => row.subcategory,
      sortable: true,
      width: "120px",
    },
    {
      name: "Category",
      cell: (row) => row.category,
      sortable: true,
      width: "120px",
    }, {
      name: "Range",
      cell: (row) => row.range,
      sortable: true,
      width: "120px",
    },
    {
      name: "Month",
      cell: (row) => row.month,
      sortable: true,
    },
    {
      name: "Year",
      cell: (row) => row.year,
      sortable: true,
    },
    {
      name: "Order Date",
      cell: (row) => new Date(row.date).toLocaleString(),
      sortable: true,
      width: "120px",
    },
    {
      name: "Points",
      cell: (row) => row.points,
      sortable: true,
      // width: "120px",
    },

    {
      name: "Qty",
      selector: (row) => row.qty,
      sortable: true,
      // width: "120px",
    },

    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
      // width: "120px",
    },

    {
      name: "Bill Value",
      selector: (row) => row.bill_value,
      sortable: true,
      // width: "120px",
    },

    {
      name: "Status",
      selector: (row) =>
        row.status == 1 ? (
          <span className="text-green-400">Active</span>
        ) : (
          <span className="text-red-400">Reverted</span>
        ),
      sortable: true,
      // width: "120px",
    },
    {
      name: "Created At",
      selector: (row) => new Date(row.created_at).toLocaleDateString(),
      sortable: true,
      width: "150px",
    },

    // {
    //   name: "",
    //   selector: (row) =>
    //     row.status == 1 && revertPointsPermission &&(
    //       <div>
    //         <button
    //           onClick={() => handleRevertPoints(row.id)}
    //           className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
    //         >
    //           <AiOutlineDelete className="mr-1" />
    //           Revert Points
    //         </button>
    //       </div>
    //     ),
    //   width: "240px",
    // },
  ];

  // const [
  //   revertOrderPoints,
  //   { data: revertPointData, error: revertPointError },
  // ] = useRevertOrderPointsMutation();

  // const handleRevertPoints = (id) => {
  //   if (window.confirm("Are you sure you want to reject this request")) {
  //     revertOrderPoints({
  //       token: sessionStorage.getItem("access_token"),
  //       tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  //       id: id,
  //     });
  //   }
  //   return;
  // };

  // useEffect(() => {
  //   if (revertPointError) {
  //     toast.dismiss();
  //     console.log("revertPointError", revertPointError);
  //     toast.error("Something went wrong", { id: "revertPointError" });
  //     fetchAllOrder({
  //       token: sessionStorage.getItem("access_token"),
  //       tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  //     });
  //   }

  //   if (revertPointData) {
  //     toast.dismiss();
  //     console.log("revertPointData", revertPointData);
  //     toast.success("Points successfully reverted", { id: "revertPointData" });
  //     fetchAllOrder({
  //       token: sessionStorage.getItem("access_token"),
  //       tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  //     });
  //   }
  // }, [revertPointData, revertPointError]);

  const navigate = useNavigate();

  const [fetchAllOrder, { data: orderListData, error: orderListError }] =
    useModenikGetAllOrdersMutation();

  const revertPointsPermission = hasPermission("revertOrderPoints");
  // const uploadOrderCsvPermission = hasPermission("uploadOrderCsv");
  const uploadOrderCsvPermission = true;

  useEffect(() => {

    // const hasViewPermission = hasPermission("viewOrders");
    // if (!hasViewPermission) {
    //   navigate(`/genefied/${getVendorName()}/`);
    // }
    console.log("request", request);

    if (
      request.invoice_id === "" &&
      request.user_id === "" &&
      request.date === "" &&
      request.startDate === "" &&
      request.endDate === "" &&
      request.mobile === "" &&
      request.created_at === ""
    ) {
      fetchAllOrderFunc();
    }
  }, [request]);

  const fetchAllOrderFunc = () => {
    fetchAllOrder({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };

  console.log("all orders", orderListData);

  useEffect(() => {
    if (orderListData && orderListData.body?.order_list) {
      setMyList(orderListData.body.order_list)
      setTotalRows(orderListData.body?.total_orders);
    }
  }, [orderListData, orderListError])


  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Order List
            <LinksComponent />
          </div>

          <div className="p-3">
            <div className="p-3 w-full bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                // title={"Product List"}
                data={myList}
                // loading={loading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              >
                <div className="flex  space-x-2 justify-end">
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Invoice ID..."
                        required
                        value={request.invoice_id}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, invoice_id: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Mobile..."
                        required
                        value={request.mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, mobile: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Code..."
                        required
                        value={request.product_code}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, product_code: newValue };
                          });
                        }}
                      />
                    </div>
                  </div> */}

                  {/* <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Product Name..."
                        required
                        value={request.product_name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, product_name: newValue };
                          });
                        }}
                      />
                    </div>
                  </div> */}

                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Date..."
                        required
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, startDate: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Date..."
                        required
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, endDate: newValue };
                          });
                        }}
                      />
                    </div>
                  </div>

                  {/* <div className="w-52 h-10">
                    <div className="relative searchin">
                      <select
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        name="User Scanned"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return { ...prevState, is_scanned: newValue };
                          });
                        }}
                        id=""
                      >
                        <option selected value={true}>
                          Scans Active
                        </option>
                        <option value={false}>Scans Inactive</option>
                      </select>
                    </div>
                  </div> */}

                  <div className="flex flex-row  gap-2 h-10">
                    <div className="relative searchin">
                      <button
                        className="btn btn-outline-primary clear-both float-right  p-2"
                        onClick={() => {
                          fetchAllOrderFunc();
                          // fetchAppUserByUserTypeCsvFunction();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                      <button
                        className="btn btn-outline-primary clear-both float-right p-2"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
              </DataTableComponent>
              {
                uploadOrderCsvPermission &&
                <div
                  className="fixed bottom-0 right-16 w-16 h-16 mr-10 mb-2 cursor-pointer"
                  id="box_btn"
                >
                  <div className="text-right">
                    <Menu className="relative inline-block text-left">
                      <div>
                        <Menu.Button
                          onClick={() => navigate(`upload`)}
                          className="inline-flex justify-cente w-12 h-12 rounded-full text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 "
                          style={{ backgroundColor: `${primaryColor["700"]}` }}
                        >
                          <HiOutlinePlus className="w-full h-full p-3" />
                        </Menu.Button>
                      </div>
                    </Menu>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllOrderList;
