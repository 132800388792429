import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  useDeleteFormTemplateMutation,
  useFetchFormTemplateMutation,
} from "../../../features/admin/formTemplate/formTemplateApi";
import { toast } from "react-hot-toast";
import { Backdrop, Box, Fade, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 2,
};

export default function FormTemplate() {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [userTypes, setUsersTypes] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [templateData, setTemplateData] = useState(null);

  const [tableDataItems, setTableDataItems] = useState([]);

  // useEffect(() => {
  //   console.log("state", selectedRows);
  // }, [selectedRows]);

  const [
    fetchFormTemplates,
    { data: formTemplateData, error: formTemplateError },
  ] = useFetchFormTemplateMutation();

  const [
    deleteFormTemplate,
    { data: deleteFormTemplateData, error: deleteformTemplateError },
  ] = useDeleteFormTemplateMutation();

  useEffect(() => {
    fetchFormTemplates(sessionStorage.getItem("access_token"));
  }, []);

  useMemo(() => {
    if (formTemplateData) {
      (() => {
        setTableDataItems(formTemplateData.body);
      })();
    }
    if (formTemplateError) {
      (() => {
        console.log("errror", formTemplateError);
      })();
    }
  }, [formTemplateData, formTemplateError]);

  const handleDeleteWrapper = (r) => {
    toast(
      (t) => (
        <span>
          <h6>Are you sure you want to delete this ?</h6>
          <div className="mt-3 d-flex">
            <button
              className="btn btn-outline-danger btn-sm ml-auto mr-1"
              onClick={() => {
                handleButtonDelete(r);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-primary btn-sm mr-auto ml-1"
              onClick={() => {
                toast.dismiss(t.id);
              }}
            >
              No
            </button>
          </div>
        </span>
      ),
      {
        id: "deleteWorkflow",
      },
    );
    return;
  };

  const handleButtonDelete = (r) => {
    //handle the edit functionality

    deleteFormTemplate({
      token: sessionStorage.getItem("access_token"),
      id: r.id,
    });
  };

  useMemo(() => {
    if (deleteFormTemplateData) {
      (() => {
        toast.dismiss();
        fetchFormTemplates(sessionStorage.getItem("access_token"));
        console.log("delete success", deleteFormTemplateData);
      })();
    }

    if (deleteformTemplateError) {
      (() => {
        toast.dismiss();
        if (deleteformTemplateError.status === 409) {
          toast.error(deleteformTemplateError.data.message);
        }
        console.log("delete error", deleteformTemplateError);
      })();
    }
  }, [deleteFormTemplateData, deleteformTemplateError]);

  const handleChange = useCallback((state) => {
    // setSelectedRows(state.selectedRows);
  }, []);

  const columns = [
    {
      name: "S.No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.template_name,
      sortable: true,
      width : "400px"

    },
    {
      name: "Type",
      selector: (row) => {
        if (row.form_type == 1) return <p>Payment</p>;
        else if (row.form_type == 2) return <p>Warranty</p>;
        else if (row.form_type == 3) return <p>KYC</p>;
        else if (row.form_type == 4) return <p>Login</p>;
        else if (row.form_type == 5) return <p>Registration</p>;
        else if (row.form_type == 6) return <p>Profile</p>;
        else if (row.form_type == 7) return <p>Panel-AddUser</p>;
        else if (row.form_type == 8) return <p>App-AddUser</p>;
        else if (row.form_type == 9) return <p>Enquiry</p>;
        else if (row.form_type == 10) return <p>WheelCampaign</p>;
        else if (row.form_type == 11) return <p>App-Feedback</p>;
      },
      sortable: false,
      allowOverflow: false,
    },

    {
      name: "Created On",
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => row.created_by_name,
      sortable: true,
    },
    {
      cell: (r) => (
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => {
            setTemplateData(r);
            handleOpen();
          }}
        >
          View
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (r) => (
        <button
          className="btn btn-outline-secondary btn-sm"
          onClick={() => {
            navigate("/addForm", {state : r})
          }}
        >
          Edit
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (r) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => handleDeleteWrapper(r)}
        >
          Delete
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  // if(formTemplateIsError){
  //   return (
  //     <div>
  //       <div className="alert alert-danger" role="alert">
  //         {formTemplateError}
  //       </div>
  //     </div>
  //   );
  // }

  // if(formTemplateIsLoading){
  //   return <div>Loading...</div>
  // }

  return (
    <div className="">
      <div className="page-content">
      <div className="card p-4"> 
        <div className="borderbtm d-flex justify-content-between align-items-center flex-wrap grid-margin">
          <div>
            <h4 className="mb-0 card-title">Welcome to Forms Master</h4>
          </div>
          <div className="d-flex align-items-center flex-wrap text-nowrap">
            <button
              type="button"
              className="btn btn-outline-primary btn-icon-text mb-2 mb-md-0"
              onClick={() => navigate("/addForm")}
            >
              Add New Dynamic Form
            </button>
          </div>
        </div>
        <div>
          {open && (
            <Modal keepMounted open={open} onClose={handleClose} closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
             
            }}>
               <Fade in={open}>
              <Box className="px-5 py-4" style={{ overflowY: "scroll" }} sx={style}>
                <div className="frmpop_n">
                <h2 className="card-title poprts" >{templateData.template_name}</h2>
                  <div className="frmds grid grid-cols-2 gap-3">
                  
                    {Object.values(templateData.template).map((element, idx) => {
                      return element.type === "select" ? (
                        <div className="w-full my-2" key={idx}>
                          <div className="my-1">
                            <span className="capitalize  text-gray-400 text-xs">{element.name} </span>
                            <span className="block text-md">{element.label}</span>
                          </div>
                          <select className=" form-control rounded-lg shadow-sm shadow-black px-2 h-auto" disabled>
                            <option value={"Select Options"}>
                              Select Options
                            </option>

                            {element.options.map((opt) => (
                              <option value={opt}>{opt}</option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        <div className="w-full my-2" key={idx}>
                          <div className="my-1">
                            <span className="capitalize text-gray-400 text-xs">{element.name} </span>
                            <span className="block text-md">{element.label}</span>
                          </div>
                          <input
                            className="form-control rounded-lg shadow-sm shadow-black px-2"
                            type={element.type}
                            maxLength={element.maxLength}
                            placeholder={element.label}
                            required={element.required}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Box>
              </Fade>
            </Modal>
          )}
        </div>
        <div className="row">
          <div className="col-12 col-xl-12">
            <DataTable
              title="Dynamic Forms"
              data={tableDataItems}
              columns={columns}
              //selectableRows
              onSelectedRowsChange={handleChange}
            />
          </div>
        </div> </div>
      </div>
    </div>
  );
}
