import React, { useEffect, useState } from "react";
import InputComponent from "../InputComponent/InputComponent";
import { BsCheckCircleFill } from "react-icons/bs";
import SelectComponentWithSearch from "../SelectComponentWIthSearch/SelectComponentWithSearch";
import { useFetchAllGiftsMutation } from "../../../features/tenant/gift/giftApi";
import { useGetAllWheelCampaignMutation } from "../../../features/tenant/wheelCampaign/wheelCampaignApi";
import { useFetchCouponsBrandMutation } from "../../../features/tenant/staticCoupons/staticCouponsApi";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { useCreateSalesBoosterTriggerMutation } from "../../../features/tenant/salesBoosterTrigger/salesBoosterTriggerApi";

const pointsTypeObj = [
  { id: 1, name: "Flat" },
  { id: 2, name: "Multiplier" },
];

const offerOpsObj = [
  { id: 1, name: "Point", checked: true },
  { id: 2, name: "Gift", checked: false },
  { id: 3, name: "Coupon", checked: false },
  { id: 4, name: "Wheel", checked: false },
  { id: 5, name: "Cashback", checked: false },
];

const offerTypeObj = [
  { id: 1, name: "Single", checked: true },
  { id: 2, name: "Multi", checked: false },
];

const triggerOptionObj = [
  // { id: 1, name: "Each Scan", checked: true },
  { id: 2, name: "Purchase Limit", checked: false },
  { id: 3, name: "Target Scan", checked: false },
  { id: 4, name: "Target Point", checked: false },
  { id: 4, name: "Target Category", checked: false },
];

const TriggerOn = ({
  name,
  applicableOn,
  startDate,
  endDate,
  sb_id,
  takenTriggers,
  setRefetchTriggers,
  removeTrigger,
  products,
  sb_type,
}) => {

  // console.log("products", products);
  const [triggerOptions, setTriggerOptions] = useState([]);
  const [triggerOnValue, setTriggerOnValue] = useState();
  const [offerType, setOfferType] = useState(offerTypeObj);

  const [offerOptions, setOfferOptions] = useState(
    JSON.parse(JSON.stringify(offerOpsObj)),
  );

  const [pointType, setPointType] = useState(pointsTypeObj[0]);

  const [pointTypeArr, setPointTypeArr] = useState(pointsTypeObj);

  const [pointValue, setPointValue] = useState(null);

  const [giftQty, setGiftQty] = useState(0);

  const [selectedGift, setSelectedGift] = useState({
    id: 0,
    name: "Select gift",
  });
  const [selectedCoupon, setSelectedCoupon] = useState({
    id: 0,
    name: "Select Coupon",
  });
  const [selectedWheel, setSelectedWheel] = useState({
    id: 0,
    name: "Select Wheel",
  });
  const [cashbackValue, setCashbackValue] = useState(null);
  const [pointProbability, setPointProbability] = useState();
  const [giftProbability, setGiftProbability] = useState();
  const [couponProbability, setCouponProbability] = useState();
  const [wheelProbability, setWheelProbability] = useState();
  const [cashbackProbability, setCashbackProbability] = useState();

  const handleChangeTriggerOn = (idx) => {
    const newData = [...triggerOptions];
    for (let i = 0; i < newData.length; i++) {
      const element = newData[i];
      element.checked = false;
    }
    newData[idx].checked = true;
    setTriggerOptions(newData);
  };

  const handleChangeOfferType = (idx, name) => {
    const newData = [...offerType];

    for (let i = 0; i < newData.length; i++) {
      const element = newData[i];
      element.checked = false;
    }
    newData[idx].checked = true;
    setOfferType(newData);
  };

  useEffect(() => {
    if (offerType[0].checked) {
      // console.log("inside");
      const newData = [...offerOptions];
      for (let i = 0; i < newData.length; i++) {
        const element = newData[i];
        element.checked = false;
      }
      newData[0].checked = true;
      setOfferOptions(newData);
    }
  }, [offerType]);

  const handleChangeOfferOptions = (idx, type) => {
    console.log(type);
    if (type === "SINGLE") {
      const newData = [...offerOptions];
      for (let i = 0; i < newData.length; i++) {
        const element = newData[i];
        element.checked = false;
      }
      newData[idx].checked = true;
      console.log(newData);
      setOfferOptions(newData);
    } else {
      const newData = [...offerOptions];
      newData[idx].checked = !offerOptions[idx].checked;
      setOfferOptions(newData);
    }
  };

  useEffect(() => {
    for (let i = 0; i < triggerOptions.length; i++) {
      const element = triggerOptions[i];
      if (element.name == "Each Scan" && element.checked) {
        setPointTypeArr(pointsTypeObj);
        setPointType(pointsTypeObj[0]);
        setOfferOptions([offerOpsObj[0]]);
        break;
      } else {
        setPointTypeArr(pointsTypeObj);
        setPointType(pointsTypeObj[0]);
        setOfferOptions(offerOpsObj)
      }
    }
  }, [triggerOptions]);

  const [couponList, setCouponList] = useState([]);
  const [
    fetchcouponBrands,
    {
      data: couponBrandList,
      isLoading: couponBrandIsLoading,
      error: couponBrandError,
    },
  ] = useFetchCouponsBrandMutation();

  const [
    fetchGifts,
    { data: giftsData, isLoading: giftsIsLoading, error: giftsError },
  ] = useFetchAllGiftsMutation();

  const [wheelCampaignList, setWheelCampaignList] = useState([]);
  const [
    fetchWheelCampaigns,
    {
      data: wheelCampaignData,
      isLoading: wheelCampaignIsLoading,
      error: wheelCampaignError,
    },
  ] = useGetAllWheelCampaignMutation();

  useEffect(() => {
    // console.log("Taken Triggers" ,takenTriggers);
    const arr = [];
    // for (let i = 0; i < triggerOptionObj.length; i++) {
    //   if (takenTriggers.includes(triggerOptionObj[i].name.toLowerCase())) {
    //     // console.log("present trigger" , triggerOptionObj[i]);
    //   } else {
    //     arr.push(triggerOptionObj[i]);
    //   }
    // }
    // setTriggerOptions(arr);
    // if (sb_type == '3' || sb_type == '2') {
    //   let count = products.length; 
    //   let newTriggers = [ ...triggerOptionObj];
    //   newTriggers.push({ id: 5, name: `Sub-Category (${count})`, checked: false });
    //   setTriggerOptions(newTriggers);

    // } else {
    setTriggerOptions(triggerOptionObj);
    // }

    //fetching all coupon provider data
    fetchcouponBrands({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });

    //fetching all gifts data
    fetchGifts({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });

    //fetching wheel campaign data
    fetchWheelCampaigns({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    // if coupon brand list is not empty then create couponList arr
    if (couponBrandList && couponBrandList.body) {
      const arr = [];
      for (let i = 0; i < couponBrandList.body.length; i++) {
        const element = couponBrandList.body[i];
        arr.push({ ...element, name: element.brand });
      }
      setCouponList(arr);
    }
  }, [couponBrandList]);

  useEffect(() => {
    // if wheel Campaign Data is not empty then create campaign list
    if (wheelCampaignData && wheelCampaignData?.body && wheelCampaignData?.body?.wheelCampaigns) {
      console.log("wheel Campaign Data", wheelCampaignData.body.wheelCampaigns);
      const arr = [];
      for (let i = 0; i < wheelCampaignData.body.wheelCampaigns.length; i++) {
        const element = wheelCampaignData.body.wheelCampaigns[i];
        arr.push({ ...element, name: element.title });
      }
      setWheelCampaignList(arr);
    }
  }, [wheelCampaignData]);

  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme
    .color_shades;

  const location = useLocation();

  const [
    AddSbTrigger,
    {
      data: addSbTriggerData,
      isLoading: addSbTriggerIsLoading,
      error: addSbTriggerError,
    },
  ] = useCreateSalesBoosterTriggerMutation();

  useEffect(() => {
    if (addSbTriggerData) {
      console.log("add sb trigger success", addSbTriggerData);
      setRefetchTriggers((previous) => previous + 1);
      removeTrigger(0);
      toast.success("Trigger saved successfully", {
        id: "addedTriggerSuccess",
      });
    }

    if (addSbTriggerError) {
      console.log("add sb trigger error", addSbTriggerError);
      toast.error("Something went wrong", { id: "addedTriggerError" });
    }
  }, [addSbTriggerData, addSbTriggerError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.dismiss();

    console.log("SUBMT", sb_id);

    if (!name) {
      toast.error("Name is required", {
        id: "nameError",
      });
      return;
    }

    if (!applicableOn || applicableOn.length <= 0) {
      toast.error("Applicable on is required", {
        id: "applicableOnError",
      });
      return;
    }

    if (!startDate || !endDate) {
      toast.error("Start Date and End Date are required", {
        id: "dateError",
      });
      return;
    }
    // date error check
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (start > end) {
      toast.error("End date should be greater than Start date", {
        id: "dateError",
      });
      return;
    }

    let selectedTriggerOption = null;
    let trigger_value = triggerOnValue;
    let isError = false;
    triggerOptions.forEach((each) => {
      if (each.checked) {
        selectedTriggerOption = each;
        //trigger value equal 1 when trigger on is Each Scan
        if (each.id === 1) {
          trigger_value = 1;
        }
        // else if(each.id === 5) {
        //   console.log("cat subcategory valuesss", each, trigger_value);
        //   if(trigger_value > products.length){
        //     toast.error("Trigger value should be less than total products",{id : "subcategoryError",})
        //     isError = true;
        //   }
        // }
        else {
          //trigger value error checks
          if (!trigger_value) {
            toast.error("Trigger on value is required ", {
              id: "triggerOnValueError",
            });
            isError = true;
          } else if (trigger_value <= 0) {
            toast.error("Trigger on value should be greater than 0 ", {
              id: "triggerOnValueError",
            });
            isError = true;
          }
        }
      }
    });

    if (isError) {
      return;
    }

    if (selectedTriggerOption == null) {
      toast.error("Select a trigger", {
        id: "seletTriggerError",
      });
      return;
    }
    // console.log(selectedTriggerOption, triggerOnValue);
    // console.log(offerType);

    // const userTypeIds =  applicableOn.map( (user) => user.user_type_id);

    const data = {
      sb_id: sb_id,
      trigger_on: selectedTriggerOption.name.toLowerCase(),
      trigger_value: trigger_value,
      offer_type: null,
      point: null,
      gift_id: null,
      coupon: null,
      wheel_campaign_id: null,
      cashback: null,
      point_probability: null,
      gift_probability: null,
      coupon_probability: null,
      wheel_campaign_probability: null,
      cashback_probability: null,
    };

    //if single offer
    if (offerType[0].checked) {
      data.offer_type = "S";
      let selectedOfferOption = null;
      offerOptions.map((each) => {
        if (each.checked) {
          selectedOfferOption = each.name;
        }
      });
      if (selectedOfferOption.toLowerCase() === "point") {
        if (!pointValue || pointValue <= 0) {
          toast.error("Point value must be greater than 0", {
            id: "pointValueError",
          });
          return;
        }
        data.point = `${pointType.name.slice(0, 1) + "-" + pointValue}`;
      } else if (selectedOfferOption.toLowerCase() === "gift") {

        // if (selectedGift.id == 0) {
        //   toast.error("Select a gift", {
        //     id: "giftError",
        //   });
        //   return;
        // }
        // data.gift_id = selectedGift.id;

        if (giftQty <= 0) {
          toast.error("Enter valid gift qty", {
            id: "giftError",
          });
          isError = true;
        }
        data.gift_id = giftQty;

      } else if (selectedOfferOption.toLowerCase() === "coupon") {
        if (selectedCoupon.id == 0) {
          toast.error("Select a coupon", {
            id: "couponError",
          });
          return;
        }
        data.coupon = selectedCoupon.name;
      } else if (selectedOfferOption.toLowerCase() === "wheel") {
        if (selectedWheel.id == 0) {
          toast.error("Select a wheel", {
            id: "wheelError",
          });
          return;
        }
        data.wheel_campaign_id = selectedWheel.id;
      } else if (selectedOfferOption.toLowerCase() === "cashback") {
        if (!cashbackValue || cashbackValue <= 0) {
          toast.error("Cashback value must be greater than 0", {
            id: "cashbackValueError",
          });
          return;
        }
        data.cashback = cashbackValue;
      }
    }
    //multiple offer
    else {
      data.offer_type = "M";
      let isError = false;
      let totalProbability = 0;
      offerOptions.forEach((each) => {
        if (each.checked) {
          if (each.name.toLowerCase() === "point") {
            if (!pointValue || pointValue <= 0) {
              toast.error("Point value must be greater than 0", {
                id: "pointValueError",
              });
              isError = true;
            }

            if (!pointProbability || pointProbability <= 0) {
              toast.error("Point probability must be greater than 0", {
                id: "pointProbabilityError",
              });
              isError = true;
            }
            data.point = `${pointType.name.slice(0, 1) + "-" + pointValue}`;
            data.point_probability = pointProbability;
            totalProbability += Number(pointProbability);
          } else if (each.name.toLowerCase() === "gift") {
            // if (selectedGift.id == 0) {
            //   toast.error("Select a gift", {
            //     id: "giftError",
            //   });
            //   isError = true;
            // }
            // if (!giftProbability || giftProbability <= 0) {
            //   toast.error("Gift probability must be greater than 0", {
            //     id: "giftProbabilityError",
            //   });
            //   isError = true;
            // }
            // data.gift_id = selectedGift.id;

            if (giftQty <= 0) {
              toast.error("Enter valid gift qty", {
                id: "giftError",
              });
              isError = true;
            }
            if (!giftProbability || giftProbability <= 0) {
              toast.error("Gift probability must be greater than 0", {
                id: "giftProbabilityError",
              });
              isError = true;
            }
            data.gift_id = giftQty;
            data.gift_probability = giftProbability;
            totalProbability += Number(giftProbability);
          } else if (each.name.toLowerCase() === "coupon") {
            if (selectedCoupon.id == 0) {
              toast.error("Select a coupon", {
                id: "couponError",
              });
              isError = true;
            }
            if (!couponProbability || couponProbability <= 0) {
              toast.error("Coupon probability must be greater than 0", {
                id: "couponProbabilityError",
              });
              isError = true;
            }
            data.coupon = selectedCoupon.name;
            data.coupon_probability = couponProbability;
            totalProbability += Number(couponProbability);
          } else if (each.name.toLowerCase() === "wheel") {
            if (selectedWheel.id == 0) {
              toast.error("Select a wheel", {
                id: "wheelError",
              });
              isError = true;
            }
            if (!wheelProbability || wheelProbability <= 0) {
              toast.error("Wheel probability must be greater than 0", {
                id: "wheelProbabilityError",
              });
              isError = true;
            }
            data.wheel_campaign_id = selectedWheel.id;
            data.wheel_campaign_probability = wheelProbability;
            totalProbability += Number(wheelProbability);
          } else if (each.name.toLowerCase() === "cashback") {
            if (!cashbackValue || cashbackValue <= 0) {
              toast.error("Cashback value must be greater than 0", {
                id: "cashbackValueError",
              });
              isError = true;
            }
            if (!cashbackProbability || cashbackProbability <= 0) {
              toast.error("Cashback probability must be greater than 0", {
                id: "cashbackProbabilityError",
              });
              isError = true;
            }
            data.cashback = cashbackValue;
            data.cashback_probability = cashbackProbability;
            totalProbability += Number(cashbackProbability);
          }
        }
      });
      console.log(totalProbability);
      if (totalProbability > 100 || totalProbability <= 0) {
        toast.error("Total Probabilty cannot exceed 100 ", {
          id: "totalProbabilityError",
        });
        isError = true;
      }
      if (isError) {
        console.log(isError);
        return;
      }
    }

    console.log("Final Data", data);

    AddSbTrigger({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: data,
    });
  };

  return (
    <div>
      <div className="w-full flex-col justify-center ">
        <div className="mb-2 w-full justify-center items-center">
          <div className="flex mb-3 space-x-5 w-full justify-center items-center pb-3 rounded-xl p-1">
            {triggerOptions &&
              triggerOptions.length > 0 &&
              triggerOptions.map((each, idx) => (
                <div
                  key={each.id}
                  className={`flex justify-center  items-center shadow gap-2 rounded-lg py-2.5 px-4 text-sm font-medium leading-5 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 border border-gray-200 text-center " +
                                      ${each.checked
                      ? "  bg-blue-700 text-white "
                      : " text-black hover:bg-white/[0.12] hover:text-gray-800 "
                    }`}
                  onClick={() => handleChangeTriggerOn(idx)}
                >
                  {each.checked ? (
                    <BsCheckCircleFill className="" />
                  ) : (
                    <div className=""></div>
                  )}
                  {each.name}
                </div>
              ))}
          </div>
          {triggerOptions && triggerOptions.length > 0 && (
            <div className="mx-auto w-40">
              <InputComponent className="bg-white"
                placeholder={"Enter Value"}
                type={"number"}
                // value={triggerOptions[0].checked ? "1" : triggerOnValue}
                // setValue={
                //   triggerOptions[0].checked ? () => {} : setTriggerOnValue
                // }
                value={triggerOnValue}
                setValue={setTriggerOnValue}
                required={true}
              />
            </div>
          )}
        </div>
      </div>

      <div>
        <div className="flex-col justify-center w-full py-2">
          <div className="w-full bg-gray-100 border-t border-gray-300 p-2 mt-4 mb-4 dropar">
            <h2 className="text-lg font-bold text-center"> Offer</h2>
          </div>

          <div className="w-full flex-col justify-center ">
            {triggerOptions && triggerOptions.length > 0 && (
              <div className="mb-6">
                <div className="w-full flex justify-center items-center flex-wrap">
                  <div className="w-full px-2 sm:px-0">
                    <div>
                      <div className="mb-3 flex gap-2 space-x-1 max-w-sm mx-auto rounded-xl p-1">
                        {offerType.map((each, idx) => (
                          <div
                            key={each.id}
                            className={`w-full rounded-lg py-2.5 text-sm font-medium leading-5 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 shadow text-center " +
                                      ${each.checked
                                ? " shadow-lg text-white "
                                : " text-black hover:bg-white/[0.12] hover:text-gray-800 "
                              }`}
                            style={{
                              backgroundColor: `  ${each.checked
                                ? primaryColor["600"]
                                : primaryColor["100"]
                                }`,
                            }}
                            onClick={() =>
                              handleChangeOfferType(idx, each.name)
                            }
                          >
                            {each.name}
                          </div>
                        ))}
                      </div>

                      <div className=" m-auto bg-gray-50 border border-gray-300 rounded-lg">
                        {/* SINGLE OPTIONS */}
                        {offerType[0].checked === true ? (
                          <>
                            <div className="w-full flex flex-col justify-center items-center py-4 ">
                              <div className="border-b border-gray-200 w-full pb-3">
                                <div className="flex space-x-5 rounded-xl p-1  justify-center">
                                  {offerOptions.map((each, idx) => (
                                    <div
                                      key={each.id}
                                      className={` gap-2 flex justify-center items-center rounded-lg py-2.5 px-4 text-sm font-medium leading-5 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 shadow text-center " +
                                      ${each.checked
                                          ? " shadow-lg bg-gray-600 text-white "
                                          : " text-black hover:bg-white/[0.12] hover:text-gray-800 "
                                        }`}
                                      onClick={() =>
                                        handleChangeOfferOptions(idx, "SINGLE")
                                      }
                                    >
                                      {each.checked ? (
                                        <BsCheckCircleFill className="" />
                                      ) : (
                                        <div className=""></div>
                                      )}
                                      {each.name}
                                    </div>
                                  ))}</div>
                              </div>

                              <div className="my-3 w-full flex justify-center ">
                                {/* Points */}
                                {offerOptions.length >= 1 && offerOptions[0].name == "Point" && offerOptions[0].checked && (
                                  <div className="mb-1 w-full flex space-x-3 justify-center items-center ">
                                    <div className="w-1/3">
                                      <SelectComponentWithSearch
                                        title={"Points Type"}
                                        selected={pointType}
                                        setSelected={setPointType}
                                        dataArr={pointTypeArr}
                                      />
                                    </div>
                                    <div className="w-1/3">
                                      <InputComponent
                                        name={"Value"}
                                        placeholder={"Enter Value.."}
                                        type={"number"}
                                        value={pointValue}
                                        setValue={setPointValue}
                                        required={true}
                                      />
                                    </div>
                                  </div>
                                )}

                                {/* Gift */}
                                {offerOptions.length >= 2 && offerOptions[1].name == "Gift" && offerOptions[1].checked &&
                                  // giftsData.body.gifts.length > 0 && (
                                  //   <div className="mb-1 w-1/2 flex space-x-3 justify-center items-center ">
                                  //     <div className="w-2/3">
                                  //       {/* <SelectComponentWithSearch
                                  //         title={"Gift"}
                                  //         selected={selectedGift}
                                  //         setSelected={setSelectedGift}
                                  //         dataArr={
                                  //           giftsData
                                  //             ? giftsData.body.gifts
                                  //             : []
                                  //         }
                                  //       /> */}
                                  //     </div>
                                  //   </div>
                                  // )
                                  <div className="mb-1 w-1/2 flex space-x-3 justify-center items-center ">
                                    <div className="w-2/3">
                                      <InputComponent
                                        name={"Qty"}
                                        placeholder={"Enter Qty.."}
                                        type={"number"}
                                        value={giftQty}
                                        setValue={setGiftQty}
                                        required={true}
                                      />
                                    </div>
                                  </div>
                                }

                                {/* Coupon */}
                                {offerOptions.length >= 3 && offerOptions[2].name == "Coupon" && offerOptions[2].checked &&
                                  couponList.length > 0 && (
                                    <div className="mb-1 w-1/2 flex space-x-3 justify-center items-center ">
                                      <div className="w-2/3">
                                        <SelectComponentWithSearch
                                          title={"Coupon "}
                                          selected={selectedCoupon}
                                          setSelected={setSelectedCoupon}
                                          dataArr={couponList}
                                        />
                                      </div>
                                    </div>
                                  )}

                                {/* Wheel */}
                                {offerOptions.length >= 4 && offerOptions[3].name == "Wheel" && offerOptions[3].checked &&
                                  wheelCampaignList.length > 0 && (
                                    <div className="mb-1 w-1/2 flex space-x-3 justify-center items-center ">
                                      <div className="w-2/3">
                                        <SelectComponentWithSearch
                                          title={"Wheel"}
                                          selected={selectedWheel}
                                          setSelected={setSelectedWheel}
                                          dataArr={wheelCampaignList}
                                        />
                                      </div>
                                    </div>
                                  )}

                                {/* Cashback */}
                                {offerOptions.length >= 5 && offerOptions[4].name == "Cashback" && offerOptions[4].checked && (
                                  <div className="mb-1 w-1/2 flex space-x-3 justify-center items-center ">
                                    <div className="w-2/3">
                                      <InputComponent
                                        name={"Cashback"}
                                        placeholder={"Enter Value.."}
                                        type={"number"}
                                        value={cashbackValue}
                                        setValue={setCashbackValue}
                                        required={true}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {/* MULTI OPTIONS */}
                            <div className="w-full flex flex-col justify-center items-center py-4 ">
                              <div className="flex space-x-5 rounded-xl p-1">
                                {offerOptions.map((each, idx) => (
                                  <div
                                    key={each.id}
                                    className={`w-full flex justify-center gap-2 items-center rounded-lg py-2.5 px-4 text-sm font-medium leading-5 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 shadow text-center " +
                                      ${each.checked
                                        ? " shadow-lg bg-gray-600 text-white "
                                        : " text-black hover:bg-white/[0.12] hover:text-gray-800 "
                                      }`}
                                    onClick={() =>
                                      handleChangeOfferOptions(idx, "MULTI")
                                    }
                                  >
                                    {each.checked ? (
                                      <BsCheckCircleFill className="" />
                                    ) : (
                                      <div className=""></div>
                                    )}
                                    {each.name}
                                  </div>
                                ))}
                              </div>

                              <div className="my-5 w-full flex-col justify-center items-center ">
                                {/* Points */}
                                {offerOptions.length >= 1 && offerOptions[0].name == "Point" && offerOptions[0].checked && (
                                  <div className="mb-2 w-1/2 mx-auto flex space-x-3 justify-center items-center ">
                                    <div className="w-1/3">
                                      <SelectComponentWithSearch
                                        title={"Points Type"}
                                        selected={pointType}
                                        setSelected={setPointType}
                                        dataArr={pointsTypeObj}
                                      />
                                    </div>
                                    <div className="w-1/3">
                                      <InputComponent
                                        name={"Value"}
                                        placeholder={"Enter Value.."}
                                        type={"number"}
                                        value={pointValue}
                                        setValue={setPointValue}
                                        required={true}
                                      />
                                    </div>
                                    <div className="w-1/3">
                                      <InputComponent
                                        name={"Probability (1 - 99)"}
                                        placeholder={"Enter Value.."}
                                        type={"number"}
                                        value={pointProbability}
                                        setValue={setPointProbability}
                                        required={true}
                                        maxLength={"2"}
                                      />
                                    </div>
                                  </div>
                                )}

                                {/* Gift */}
                                {offerOptions.length >= 2 && offerOptions[1].name == "Gift" && offerOptions[1].checked &&
                                  // giftsData.body.gifts.length > 0 && (
                                  //   <div className="mb-2 w-1/2 mx-auto flex space-x-3 justify-center items-center ">
                                  //     <div className="w-2/3">
                                  //       <SelectComponentWithSearch
                                  //         title={"Gift"}
                                  //         selected={selectedGift}
                                  //         setSelected={setSelectedGift}
                                  //         dataArr={
                                  //           giftsData
                                  //             ? giftsData.body.gifts
                                  //             : []
                                  //         }
                                  //       />
                                  //     </div>
                                  //     <div className="w-1/3">
                                  //       <InputComponent
                                  //         name={"Probability (1 - 99)"}
                                  //         placeholder={"Enter Value.."}
                                  //         type={"number"}
                                  //         value={giftProbability}
                                  //         setValue={setGiftProbability}
                                  //         required={true}
                                  //         maxLength={"2"}
                                  //       />
                                  //     </div>
                                  //   </div>
                                  // )
                                  <div className="mb-2 w-1/2 mx-auto flex space-x-3 justify-center items-center ">
                                       <div className="w-2/3">
                                      <InputComponent
                                        name={"Qty"}
                                        placeholder={"Enter Qty.."}
                                        type={"number"}
                                        value={giftQty}
                                        setValue={setGiftQty}
                                        required={true}
                                      />
                                    </div>
                                    <div>
                                      <InputComponent
                                        name={"Probability (1 - 99)"}
                                        placeholder={"Enter Value.."}
                                        type={"number"}
                                        value={giftProbability}
                                        setValue={setGiftProbability}
                                        required={true}
                                        maxLength={"2"}
                                      />
                                    </div>
                                  </div>
                                }

                                {/* Coupon */}
                                {offerOptions.length >= 3 && offerOptions[2].name == "Coupon" && offerOptions[2].checked &&
                                  couponList.length > 0 && (
                                    <div className="mb-2 w-1/2 mx-auto flex space-x-3 justify-center items-center ">
                                      <div className="w-2/3">
                                        <SelectComponentWithSearch
                                          title={"Coupon "}
                                          selected={selectedCoupon}
                                          setSelected={setSelectedCoupon}
                                          dataArr={couponList}
                                        />
                                      </div>
                                      <div className="w-1/3">
                                        <InputComponent
                                          name={"Probability (1 - 99)"}
                                          placeholder={"Enter Value.."}
                                          type={"number"}
                                          value={couponProbability}
                                          setValue={setCouponProbability}
                                          required={true}
                                          maxLength={"2"}
                                        />
                                      </div>
                                    </div>
                                  )}

                                {/* Wheel */}
                                {offerOptions.length >= 4 && offerOptions[3].name == "Wheel" && offerOptions[3].checked &&
                                  wheelCampaignList.length > 0 && (
                                    <div className="mb-2 w-1/2 mx-auto flex space-x-3 justify-center items-center ">
                                      <div className="w-2/3">
                                        <SelectComponentWithSearch
                                          title={"Wheel"}
                                          selected={selectedWheel}
                                          setSelected={setSelectedWheel}
                                          dataArr={wheelCampaignList}
                                        />
                                      </div>
                                      <div className="w-1/3">
                                        <InputComponent
                                          name={"Probability (1 - 99)"}
                                          placeholder={"Enter Value.."}
                                          type={"number"}
                                          value={wheelProbability}
                                          setValue={setWheelProbability}
                                          required={true}
                                          maxLength={"2"}
                                        />
                                      </div>
                                    </div>
                                  )}

                                {/* Cashback */}
                                {offerOptions.length >= 5 && offerOptions[4].name == "Cashback" && offerOptions[4].checked && (
                                  <div className="mb-2 w-1/2 mx-auto flex space-x-3 justify-center items-center ">
                                    <div className="w-2/3">
                                      <InputComponent
                                        name={"Cashback"}
                                        placeholder={"Enter Value.."}
                                        type={"number"}
                                        value={cashbackValue}
                                        setValue={setCashbackValue}
                                        required={true}
                                      />
                                    </div>
                                    <div className="w-1/3">
                                      <InputComponent
                                        name={"Probability (1 - 99)"}
                                        placeholder={"Enter Value.."}
                                        type={"number"}
                                        value={cashbackProbability}
                                        setValue={setCashbackProbability}
                                        required={true}
                                        maxLength={"2"}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-center mb-3">
          {false ? (
            <button
              type="update"
              //   onClick={(e) => handleUpdate(e)}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
            >
              Update
            </button>
          ) : (
            <div className="space-x-5 mb-3">
              <button
                type="submit"
                onClick={(e) => handleSubmit(e)}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
              >
                Save
              </button>
              <button
                type="reset"
                // onClick={(e) => handleReset(e)}
                className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-2 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
              >
                Reset
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TriggerOn;
