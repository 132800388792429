import React, { useState } from 'react';
import { useCSVReader, formatFileSize } from 'react-papaparse';
import { FiUploadCloud, FiTrash2 } from 'react-icons/fi'; // Icons for upload and remove


const parseData = (data) => {
  if (!data || data.length < 1) return null;
  const columns = data[0];
  const finalData = data.slice(1).map(row => {
    const obj = {};
    columns.forEach((col, index) => obj[col] = row[index]);
    return obj;
  });
  return finalData;
};

export default function CSVReader({ setCSVData, setFileName }) {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState('#FF5C5C');

  // Get the theme's primary color from sessionStorage
  const primaryColor = JSON.parse(sessionStorage.getItem("vendorTheme")).theme.color_shades;

  const styles = {
    zone: {
      alignItems: 'center',
      border: `2px dashed ${primaryColor["700"]}`,  // Dynamic primary color
      borderRadius: 12,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100px',  // Adjusted height
      width: '100%',
      background: '#F7F9FC',
      transition: 'border-color 0.3s ease',
      cursor: 'pointer',
    },
    zoneHover: {
      borderColor: primaryColor["900"],  // Darker shade on hover
      background: '#F0F4F8',
    },
    fileContainer: {
      background: '#FFFFFF',
      borderRadius: 16,
      boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px',  // Reduced padding for smaller height
      position: 'relative',
      width: '80%',
      maxWidth: '250px',
      margin: '0 auto',
    },
    fileInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      fontSize: '12px',
      color: '#333',
    },
    progressBar: {
      marginTop: '5px',  // Adjusted spacing
      width: '100%',
    },
    remove: {
      position: 'absolute',
      top: '5px',  // Adjusted position for smaller height
      right: '5px',
      cursor: 'pointer',
    },
    icon: {
      fontSize: '32px',  // Adjusted icon size for smaller height
      color: primaryColor["300"],  // Lighter shade for the icon
      marginBottom: '5px',
    },
    placeholderText: {
      fontSize: '14px',  // Adjusted text size for smaller height
      color: primaryColor["700"],  // Primary color for placeholder text
      marginTop: '10px',
      fontWeight: '500',
    },
  };
  return (
    <CSVReader
      onUploadAccepted={(results, file) => {
        const finalData = parseData(results.data);
        setCSVData({ ...results, headers: results.data[0], parsed: finalData, file });
        setZoneHover(false);
      }}
      onDragOver={() => setZoneHover(true)}
      onDragLeave={() => setZoneHover(false)}
    >
      {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }) => (
        <div
          {...getRootProps()}
          style={{ ...styles.zone, ...(zoneHover && styles.zoneHover) }}
        >
          {acceptedFile ? (
            <div style={styles.fileContainer}>
              <div style={styles.fileInfo}>
                <span>{formatFileSize(acceptedFile.size)}</span>
                <span>{acceptedFile.name}</span>
                <div style={styles.progressBar}><ProgressBar /></div>
              </div>
              <div
                {...getRemoveFileProps()}
                style={styles.remove}
                onMouseOver={() => setRemoveHoverColor('#FF8A8A')}
                onMouseOut={() => setRemoveHoverColor('#FF5C5C')}
              >
                <FiTrash2 size={18} color={removeHoverColor} />
              </div>
              <div>{setFileName(acceptedFile.name)}</div>
            </div>
          ) : (
            <>
              <FiUploadCloud style={styles.icon} />
              <div style={styles.placeholderText}>
                Drop CSV file here or click to upload
              </div>
            </>
          )}
        </div>
      )}
    </CSVReader>
  );
}
