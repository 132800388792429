import React from "react";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { getLoginUserData } from "../../../utils/getLoginUserData";


const Home = () => {

  const tenantUserdata = getLoginUserData();
  const vendorData = JSON.parse(sessionStorage.getItem("vendorData"));

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Home
            <LinksComponent />
          </div>
          <div className="p-2 pt-0">
            <div className="homecenter">
              <div className="text-center w-full flex flex-col">
                <span className="text-right ">Last Login&nbsp;&nbsp;{tenantUserdata.last_login.split("T")[0]}<br />{tenantUserdata.last_login.split("T")[1].split(".")[0]}

                </span>
                <img className="mt-1 mx-auto w-4/5" src={vendorData.logo[0]} alt="" />
                <h1 className="mt-3 text-3xl">Welcome to  <span className="capitalize"> {tenantUserdata.name} </span></h1>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
