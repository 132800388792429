import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useAddWhatsappNotificationTemplateForTenantMutation, useUpdateWhatsappNotificationTemplateForTenantMutation } from "../../../features/tenant/whatsappNotificationTemplates/whatsappNotificationTemplatesApi";
import toast from "react-hot-toast";

const templateTypeArray = [
  {
    id: 1,
    type: "otp",
  },
  { id: 2, type: "reset" },
  { id: 3, type: "block account" },
  { id: 4, type: "activate account" },
  { id: 5, type: "registraion" },
  { id: 6, type: "profile completion" },
  { id: 7, type: "set mpin" },
  { id: 8, type: "redeem request" },
  { id: 9, type: "bonus points" },
  { id: 10, type: "birthday wishes" },
  { id: 11, type: "redemption reminders" },
  { id: 12, type: "scan_coupon points" },
  { id: 13, type: "complaint message" },
  { id: 14, type: "gift delivery" },
  { id: 15, type: "extra points" },
  { id: 16, type: "tier upgrade" },
  { id: 17, type: "welcome bonus" },
  { id: 18, type: "welcome back" },
  { id: 19, type: "redemption encouragement" },
  { id: 20, type: "membership-change-reminder" },
  { id: 21, type: "cashback-encouragement" },
];

const AddWhatappNotificationTemplates = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location);
  const [isUpdate, setIsUpdate] = useState(
    location?.state?.edit ? true : false
  );
  const [selectTemplateType, setTemplateType] = useState(0);
  const [inputTemplateId, setTemplateId] = useState(null);
  const [inputTemplateText, setTemplateText] = useState(null);

  const [
    addWhatsappNotificationTemplateForTenant,
    {
      data: addWhatsappNotificationTemplateForTenantData,
      isError: addWhatsappNotificationTemplateForTenantIsError,
      error: addWhatsappNotificationTemplateForTenantError,
      isLoading: addWhatsappNotificationTemplateForTenantIsLoading,
    },
  ] = useAddWhatsappNotificationTemplateForTenantMutation();
  const [
    updateWhatsappNotificationTemplateForTenant,
    {
      data: updateWhatsappNotificationTemplateForTenantData,
      isError: updateWhatsappNotificationTemplateForTenantIsError,
      error: updateWhatsappNotificationTemplateForTenantError,
      isLoading: updateWhatsappNotificationTemplateForTenantIsLoading,
    },
  ] = useUpdateWhatsappNotificationTemplateForTenantMutation();

  useEffect(() => {
    if (isUpdate) {
      let typeId = 0;
      templateTypeArray.map((x) => {
        if (x.type == location.state.data.type) {
          typeId = Number(x.id) - 1;
        }
      });
      setTemplateType(typeId);
      setTemplateId(location.state.data.template_id);
      setTemplateText(location.state.data.template_text);
      
    }
  }, [location]);

  const handleAddTemplate = (e) => {
    e.preventDefault();

    if (inputTemplateId == null || inputTemplateId == "") {
      toast.error("please enter Template Id");
      return;
    }
    if (inputTemplateText == null || inputTemplateText == "") {
      toast.error("please enter Template Text");
      return;
    }

    console.log("selectTemplateType", selectTemplateType);
    console.log("inputTemplateText", inputTemplateText);
    console.log("inputTemplateId", inputTemplateId);

    addWhatsappNotificationTemplateForTenant({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        template_id: inputTemplateId,
        template_text: inputTemplateText,
        account_id: location.state.data.id,
        type: templateTypeArray[selectTemplateType].type,
      },
    });
  };

  useEffect(() => {
    if (addWhatsappNotificationTemplateForTenantData) {
      toast.dismiss();
      toast.success(`${addWhatsappNotificationTemplateForTenantData.message}`, {
        id: `addWhatsappNotificationTemplateForTenantData`,
      });
      navigate(-1);
    }
    if (addWhatsappNotificationTemplateForTenantError) {
      toast.dismiss();
      toast.error(`${addWhatsappNotificationTemplateForTenantError.data.message}`, {
        id: `addWhatsappNotificationTemplateForTenantError`,
      });
    }
  }, [addWhatsappNotificationTemplateForTenantData, addWhatsappNotificationTemplateForTenantError]);

  const handleUpdate = (e) => {
    e.preventDefault();
    console.log("update notification");
    if (inputTemplateId == null || inputTemplateId == "") {
      toast.error("please enter Template Id");
      return;
    }
    if (inputTemplateText == null || inputTemplateText == "") {
      toast.error("please enter Template Text");
      return;
    }
    
    updateWhatsappNotificationTemplateForTenant({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        id : location.state.data.id,
        body: {
          template_id: inputTemplateId,
          template_text: inputTemplateText,
        },
    })
  };

  useEffect(() => {
    if (updateWhatsappNotificationTemplateForTenantData) {
      toast.dismiss();
      toast.success(`${updateWhatsappNotificationTemplateForTenantData.message}`, {
        id: `updateWhatsappNotificationTemplateForTenantData`,
      });
      navigate(-1);
    }
    if (updateWhatsappNotificationTemplateForTenantError) {
      toast.dismiss();
      toast.error(`${updateWhatsappNotificationTemplateForTenantError.data.message}`, {
        id: `updateWhatsappNotificationTemplateForTenantError`,
      });
    }
  }, [updateWhatsappNotificationTemplateForTenantData, updateWhatsappNotificationTemplateForTenantError]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            {isUpdate ? "Update " : "Add "}
            Add Whatsapp Notification Template
            <LinksComponent />
          </div>
          <div className="p-3">
            <form className="p-3 bg-white shadow rounded-lg">
              <div className="w-full flex justify-center space-x-3 ">
                <div className="mb-6 w-1/4">
                  <div className="w-full">
                    <label className="block text-sm font-medium leading-6 text-gray-900 mb-0">
                      &nbsp;
                    </label>
                    <select
                      type="text"
                      className="h-auto shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      placeholder="Add new UserType"
                      value={selectTemplateType}
                      onChange={(e) => setTemplateType(Number(e.target.value))}
                    >
                      {templateTypeArray &&
                        templateTypeArray.length > 0 &&
                        templateTypeArray.map((x) => {
                          if (isUpdate) {
                            if (x.type == location.state.data.type) {
                              return (
                                <option value={Number(x.id) - 1}>
                                  {x.type}
                                </option>
                              );
                            }
                            return;
                          } else {
                            return (
                              <option value={Number(x.id) - 1}>{x.type}</option>
                            );
                          }
                        })}
                    </select>
                  </div>
                </div>
                <div className="mb-6 w-1/4">
                  <div className="w-full">
                    <label
                      className="block text-sm font-medium leading-6 text-gray-900 mb-0"
                      for="name"
                    >
                      Template Id{" "}
                    </label>
                    <input
                      id="name"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      value={inputTemplateId}
                      onChange={(e) => {
                        setTemplateId(e.target.value);
                      }}
                      type="number"
                    />
                  </div>
                </div>{" "}
                <div className="mb-6 w-1/4">
                  <div className="w-full">
                    <label
                      className="block text-sm font-medium leading-6 text-gray-900 mb-0"
                      for="name"
                    >
                      Template Text
                    </label>
                    <input
                      id="name"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                      value={inputTemplateText}
                      onChange={(e) => {
                        setTemplateText(e.target.value);
                      }}
                      type="text"
                    />
                  </div>
                </div>
                
              </div>
              <div className="flex justify-center mt-2">
                {isUpdate ? (
                  <button
                    type="update"
                    onClick={(e) => handleUpdate(e)}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={handleAddTemplate}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Add
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddWhatappNotificationTemplates;
