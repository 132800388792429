import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { getVendorName } from "../../../utils/getVendorName";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { useGetAppUserTypesMutation } from "../../../features/tenant/appUsers/appUsersApi";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { downloadCSV } from "../../../utils/downloadCSV";
import CSVReader from "../../../components/ImportCsv/CSVReader";
import { useModenikBulkUploadOrdersMutation } from "../../../features/tenant/ModenikApi/modenikApi";
import InputComponent from "../../../components/Tenant/InputComponent/InputComponent";

const sampleDataRetailer = [
  {
    "Voucher No": "13543",
    "Voucher Date": "01-Nov-2023",
    "Distributor Code": "DR0312",
    "Distributor Name": "Balaji Traders,Jabalpur",
    "Distributor Sales Person": "",
    "Dealer Code": "DLR00000000159412",
    "Dealer Name": "Cash",
    "Dealer City": "",
    "Dealer Phone No": "",
    "Dealer Email Id": "",
    "Dealer GSTIN": "",
    "Item Code": "FG0378",
    "Item Description": "Ecotrop-FG0378",
    "Item Group": "Bakery Toppings",
    "Item Unit": "Kilogram",
    "Sale Qty": "12.00",
    "FOC Qty": "0.00",
    "Rate": "80.01",
    "Billed Value": "960.12"
  },
  {
    "Voucher No": "13544",
    "Voucher Date": "01-Nov-2023",
    "Distributor Code": "DR0312",
    "Distributor Name": "Balaji Traders,Jabalpur",
    "Distributor Sales Person": "",
    "Dealer Code": "DLR00000000159412",
    "Dealer Name": "Cash",
    "Dealer City": "",
    "Dealer Phone No": "",
    "Dealer Email Id": "",
    "Dealer GSTIN": "",
    "Item Code": "FG0378",
    "Item Description": "Ecotrop-FG0378",
    "Item Group": "Bakery Toppings",
    "Item Unit": "Kilogram",
    "Sale Qty": "12.00",
    "FOC Qty": "0.00",
    "Rate": "80.01",
    "Billed Value": "960.12"
  },
  {
    "Voucher No": "13512",
    "Voucher Date": "01-Nov-2023",
    "Distributor Code": "DR0312",
    "Distributor Name": "Balaji Traders,Jabalpur",
    "Distributor Sales Person": "",
    "Dealer Code": "DLR00000000160635",
    "Dealer Name": "Vijay Bakers  (Adhartal) (Urd)",
    "Dealer City": "",
    "Dealer Phone No": "",
    "Dealer Email Id": "",
    "Dealer GSTIN": "",
    "Item Code": "FG0792",
    "Item Description": "Magic Jelly Neutral-FG0792",
    "Item Group": "Bakery Ingredients",
    "Item Unit": "Kilogram",
    "Sale Qty": "30.00",
    "FOC Qty": "0.00",
    "Rate": "80.51",
    "Billed Value": "2,415.30"
  },
  {
    "Voucher No": "13540",
    "Voucher Date": "01-Nov-2023",
    "Distributor Code": "DR0312",
    "Distributor Name": "Balaji Traders,Jabalpur",
    "Distributor Sales Person": "",
    "Dealer Code": "DLR00000000160635",
    "Dealer Name": "Kushagr Enterprises",
    "Dealer City": "",
    "Dealer Phone No": "",
    "Dealer Email Id": "",
    "Dealer GSTIN": "23AKEPP5308E1ZI",
    "Item Code": "FG0020",
    "Item Description": "Hitrop-FG0020",
    "Item Group": "Bakery Toppings",
    "Item Unit": "Kilogram",
    "Sale Qty": "216.00",
    "FOC Qty": "0.00",
    "Rate": "66.10",
    "Billed Value": "14,277.60"
  },
]


function generateUniqueId(key) {
  const timestamp = performance.now(); 
  const randomNumber = Math.floor(Math.random() * 1000);
  return `id_${key}${timestamp}${randomNumber}`;
}

function formatDateCustomer(inputDateString) {
  const parsedDate = new Date(inputDateString);

  // Check if the parsed date is valid
  if (isNaN(parsedDate.getTime())) {
    // If the parsed date is not valid, return today's date
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1; // Month is zero-indexed, so add 1
    const year = today.getFullYear();

    return `${month}/${day}/${year}`;
  } else {
    // If the parsed date is valid, format and return it
    const day = parsedDate.getDate();
    const month = parsedDate.getMonth() + 1; // Month is zero-indexed, so add 1
    const year = parsedDate.getFullYear();

    return `${month}/${day}/${year}`;
  }
}


function validateAndCleanObjectsForCustomer(objects) {
  const requiredKeysSet = new Set(
    [
      "YEARNO",
      "MONTH NO",
      "BSM AREA",
      "STATE",
      "ASM AREA",
      "DB CODE",
      "DB NAME",
      "OUTLET UID",
      "OUTLET NAME",
      "INVOICE DATE",
      "INVOICE NO",
      "TRN TYPE",
      "QTY(BOX)",
      "CR. BASIC POINTS",
      "DR. BASIC POINTS",
      "SUB BRAND",
      "CATEGORY",
      "SUB-CATEGORY",
      "STYLE",
      "RANGE",
      "POINT PER BOX",
      "UNIQUE SUB-CAT"
  ]  
  );

  function isEmptyObject(obj) {
    return Array.from(requiredKeysSet).every((key) => !obj[key]);
  }

  const cleanedObjects = [];
  // console.log("objects", objects);
  for (const object of objects) {
    const newObject = {};

    if (isEmptyObject(object)) {
      continue;
    }

    let valid = true;
    for (let key in object) {
      key = key.trim();

      if (requiredKeysSet.has(key)) {
        if (!object[key]) {
          valid = false;
          break;
        } else {
          if (key ==  "INVOICE DATE") {
            // console.log("Voucher Date",object[key], formatDateCustomer(object[key].trim()) )

            newObject[key] = formatDateCustomer(object[key].trim());
          } else {
            newObject[key] = object[key];
          }
        }
      }
    }

    newObject.status = valid ? 1 : 2;
    // newObject.unique_id =
    // newObject["BILL TYPE"] + newObject["DB CODE"] + newObject["RETAILER CODE"] + 
    //   newObject["BILL NO"] + newObject["PRODUCT CODE"] + newObject["SIZE"] + newObject["QTY"] + newObject["MRP"];

      // newObject.unique_id = generateUniqueId(newObject["BILL NO"]);
      
    cleanedObjects.push(newObject);
  }

  console.log("cleanedObjects", cleanedObjects);

  if (cleanedObjects.some((obj) => obj.status === 2)) {
    return {
      isValid: false,
      data: cleanedObjects,
    };
  }

  return {
    isValid: true,
    data: cleanedObjects,
  };
}


const orderTypeArr = [
  {
    id: "1",
    name: "Sale",
  },
  {
    id: "2",
    name: "Purchase",
  },
];

const UploadOrders = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [canSubmit, setCanSubmit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fileName, setFileName] = useState(null);


  const [selectedOrderType, setSelectedOrderType] = useState(orderTypeArr[0]);

  const [orderType, setOrderType] = useState(orderTypeArr);

  const [
    bulkUploadOrderFunc,
    { data: bulkUploadOrderData, error: bulkUploadOrderError },
  ] = useModenikBulkUploadOrdersMutation();

  const [
    getAppUserTypes,
    {
      data: appUserTypesData,
      isLoading: appUserTypesIsLoading,
      error: appUserTypesError,
    },
  ] = useGetAppUserTypesMutation();

  const handleOnSubmit = (e) => {
    toast.loading("Processing your data...");

    e.preventDefault();
    console.log("userType ", userTypes);
    // console.log("selectedOrderType ", selectedOrderType);


    if (finalArray.length == 0) {
      toast.dismiss();
      toast.error(`User type and uploaded data mismatch`, {
        id: "mismatchCsv&UserType",
      });
      return;
    }

    bulkUploadOrderFunc({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        rows: finalArray,
        qty: finalArray.length,
        user_type_id: userTypes.user_type_id,
        user_type: userTypes.user_type,
        method_id: "1",
        method: "Upload Order",
        platform_id: "3",
        platform: "Panel",
        type: orderTypeArr[1].name,
      },
    });
  };

  const [userTypes, setUserTypes] = useState();
  useEffect(() => {
    getAppUserTypes({
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    if (appUserTypesData && appUserTypesData.body) {
      setUserTypes(appUserTypesData.body[0]);
    }
  }, [appUserTypesData]);

  useEffect(() => {
    if (bulkUploadOrderData) {
      toast.dismiss();
      toast.success("Saved Successfully", {
        id: "bulkUploadOrderData",
      });
      console.log("bulkUploadOrderData", bulkUploadOrderData);
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/orders`);
      }, 400);
    }

    if (bulkUploadOrderError) {
      toast.dismiss();
      if (bulkUploadOrderError.status == 409) {
        toast.error(
          `Duplicate Entry Found : ${bulkUploadOrderError.data.message}`,
        );
      } else {
        toast.error(
          `Something went wrong : ${bulkUploadOrderError.data.message}`,
        );
      }
      console.log("bulkUploadOrderError", bulkUploadOrderError);
    }
  }, [bulkUploadOrderData, bulkUploadOrderError]);

  const [CSVData, setCSVData] = useState(null);

  const [finalArray, setFinalArray] = useState([]);

  useEffect(() => {
    // console.log(CSVData);
    if (CSVData && CSVData.parsed && userTypes.user_type == "retailer") {
      const res = validateAndCleanObjectsForCustomer(CSVData?.parsed);

      const mappedArray = res.data.map((obj) => ({
        user_id: obj["OUTLET UID"],
        // unique_id: obj["unique_id"],
        invoice_id: obj["INVOICE NO"],
        date: obj["INVOICE DATE"],
        month: obj["MONTH NO"],
        year: obj["YEARNO"],
        qty: obj["TRN TYPE"] == "SALES" ? parseFloat(obj["QTY(BOX)"]) : Math.abs(parseFloat(obj["QTY(BOX)"])),
        price: parseFloat(obj["POINT PER BOX"]),
        bill_value: obj["TRN TYPE"] == "SALES" ?  parseFloat(obj["CR. BASIC POINTS"]) : parseFloat(obj["DR. BASIC POINTS"]),
        bill_type : obj["TRN TYPE"],
        style: obj["STYLE"],
        range: obj["RANGE"],
        category: obj["CATEGORY"],
        subcategory: obj["SUB-CATEGORY"],
      }));

      // console.log(mappedArray);

      if (res.isValid) {
        setCanSubmit(true);
        setFinalArray(mappedArray);
      }
    }else {
      toast.error("Invalid User Type ", {id : "invalid_user_type"})
    }
  }, [CSVData]);

  // console.log("show modal", showModal, finalArray);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Upload Orders
            <LinksComponent />
          </div>

          <div className="mx-9 mt-6">

            <div className="flex space-x-3">

              <div className="flex mb-1">
                <button
                  type="submit"
                  className="mb-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 text-center "
                  onClick={() => downloadCSV(sampleDataRetailer, "sampleOrderUploadRetailer.csv")}
                >
                  Sample Csv : Retailer
                </button>
              </div>
            </div>

            <form className="bg-white m-auto border-opacity-40 rounded-lg flex w-full p-4 px-5 justify-center">
              <div className="w-full flex flex-col justify-center align-middle">
                <div className="w-full">
                  <div className="w-full flex flex-col items-center justify-center ">
                    <div className="max-w-xl w-full flex justify-center items-center space-x-4 ">
                      <div className="w-1/3 flex items-start ">
                        {appUserTypesData && appUserTypesData.body && (
                          <div className=" w-full">
                            <SelectComponent
                              title={"User Type"}
                              selected={userTypes}
                              setSelected={setUserTypes}
                              dataArr={appUserTypesData.body}
                              placeholder={"Select User Type"}
                            />
                          </div>
                        )}
                      </div>
                      <div className="w-2/3 flex items-center justify-evenly flex-col ">
                        <label className="block text-gray-700 text-sm mb-2">
                          Upload Excel/CSV File:
                        </label>

                        <CSVReader setCSVData={setCSVData} setFileName={setFileName} />
                      </div>
                    </div>

                    <div className="mt-10 max-w-sm flex justify-evenly space-x-3 ">
                      {/* View CSV File Button */}
                      {canSubmit && (
                        <button
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowModal(true);
                          }}
                          className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm  px-5 py-2.5 text-center"
                        >
                          View CSV File
                        </button>
                      )}

                      {/* Save Button */}
                      {canSubmit && (
                        <button
                          type="submit"
                          onClick={(e) => handleOnSubmit(e)}
                          disabled={!canSubmit}
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {showModal && finalArray && (
            <ModalComponent
              size={"5xl"}
              data={""}
              show={showModal}
              setShow={setShowModal}
            >
              <div className="w-full rounded-lg">
                <div className=" w-full border-opacity-40 rounded-lg p-4 mt-2">
                  <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-300">
                      <thead>
                        <tr>
                          {(finalArray.length > 0
                            ? Object.keys(finalArray[0])
                            : []
                          ).map((column, index) => (
                            <th key={index} className="py-2 px-4 border-b">
                              {column}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {finalArray.slice(0, 30).map((item, rowIndex) => (
                          <tr key={rowIndex}>
                            {(finalArray.length > 0
                              ? Object.keys(finalArray[0])
                              : []
                            ).map((column, colIndex) => (
                              <td key={colIndex} className="py-2 px-4 border-b">
                                {item[column]}
                              </td>
                            ))}
                          </tr>
                        ))}

                        <h2 className="mt-2 ml-auto">
                          {" "}
                          {finalArray.length > 30
                            ? `${finalArray.length - 30} rows more`
                            : ` `}
                        </h2>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </ModalComponent>
          )}
        </div>
      </div>
    </>
  );
};

export default UploadOrders;
