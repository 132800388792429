import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { getVendorName } from "../../../utils/getVendorName";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import { useBulInsertPaymentTransferMutation } from "../../../features/tenant/paymentTransfer/paymentTransferApi";
import { useFetchAllBatchMutation } from "../../../features/tenant/batch/batchApi";
import SelectComponentWithSearch from "../../../components/Tenant/SelectComponentWIthSearch/SelectComponentWithSearch";
import { useBulkUploadQrCodeMutation } from "../../../features/tenant/qrCode/qrCodeApi";
import { downloadCSV } from "../../../utils/downloadCSV";

const sampleDataQrBulkUpload = [
  {
    "ProductName": "FS-8400 STD SSS",
    "ProductCode": "FS-8400 STD SSS",
    "SapCode": "DCCDA1001OOPL",
    "MRP": "11980",
    "FromNo": "49896510",
    "ToNo": "49896512",
    "Qty": "3",
    "MapDate": "16-10-2023",
    "VisibleNumber": "49896510",
    "HiddenNumber": "7S0H9T1N7U",
    "URL": "http://ozone-in.com/pa?uid=7S0H9T1N7U",
   
},{
  "ProductName": "FS-8400 STD SSS",
  "ProductCode": "FS-8400 STD SSS",
  "SapCode": "DCCDA1001OOPL",
  "MRP": "11980",
  "FromNo": "49896510",
  "ToNo": "49896512",
  "Qty": "3",
  "MapDate": "16-10-2023",
  "VisibleNumber": "49896511",
  "HiddenNumber": "AL34NOVYQS",
  "URL": "http://ozone-in.com/pa?uid=AL34NOVYQS",
 
},{
  "ProductName": "FS-8400 STD SSS",
  "ProductCode": "FS-8400 STD SSS",
  "SapCode": "DCCDA1001OOPL",
  "MRP": "11980",
  "FromNo": "49896510",
  "ToNo": "49896512",
  "Qty": "3",
  "MapDate": "16-10-2023",
  "VisibleNumber": "49896512",
  "HiddenNumber": "6GKMQGLM8T",
  "URL": "http://ozone-in.com/pa?uid=6GKMQGLM8T",
 
},{
  "ProductName": "FS-8400 STD SSS",
  "ProductCode": "FS-8400 STD SSS",
  "SapCode": "DCCDA1001OOPL",
  "MRP": "11980",
  "FromNo": "49896523",
  "ToNo": "49896540",
  "Qty": "18",
  "MapDate": "16-10-2023",
  "VisibleNumber": "49896526",
  "HiddenNumber": "5IJWXRGNF9",
  "URL": "http://ozone-in.com/pa?uid=5IJWXRGNF9",

}
]

function validateAndCleanObjects(objects) {
  const requiredKeysSet = new Set([
    "SapCode",
    "MRP",
    "MapDate",
    "VisibleNumber",
    "HiddenNumber",
    "year",
  ]);

  function isEmptyObject(obj) {
    return Array.from(requiredKeysSet).every((key) => !obj[key.trim()]);
  }

  const cleanedObjects = [];

  console.log("object", objects);

  for (const object of objects) {
    const newObject = {};

    if (isEmptyObject(object)) {
      continue;
    }

    let valid = true;
    for (let key in object) {
      key = key.trim();

      if (requiredKeysSet.has(key)) {
        if (!object[key]) {
          console.log("missing required key", object , key);
          valid = false;
          break;
        } else {
          newObject[key] = object[key];
        }
      }
    }

    newObject.status = valid ? 1 : 2;
    cleanedObjects.push(newObject);
  }

  if (cleanedObjects.some((obj) => obj.status === 2)) {
    return {
      isValid: false,
      data: cleanedObjects,
    };
  }

  return {
    isValid: true,
    data: cleanedObjects,
  };
}

const QrBulkUpload = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [batchList, setbatchList] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState({
    id: -1,
    name: "",
  });

  const [file, setFile] = useState();
  const [array, setArray] = useState([]);

  const [canSubmit, setCanSubmit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const fileReader = new FileReader();
  const [
    bulkUploadQrCodes,
    {
      data: bulkUploadQrCodesData,
      error: bulkUploadQrCodesError,
      isLoading: bulkUploadQrCodesIsLoading,
    },
  ] = useBulkUploadQrCodeMutation();

  const [fetchBatchList, { data: batchListData, error: batchListError }] =
    useFetchAllBatchMutation();

  useEffect(() => {
    fetchBatchList({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    toast.dismiss();

    if (batchListData?.body?.batches) {
      setbatchList(batchListData?.body?.batches);
      console.log(batchListData);
    }
    if (batchListError) {
      console.log("batch list error", batchListError);
    }
  }, [batchListData, batchListError]);

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  // const csvFileToArray = (string) => {
  //   const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
  //   const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

  //   console.log("--------->", csvHeader[csvHeader.length - 1]);

  //   console.log("csv header: ", csvHeader);

  //   const array = csvRows.map((i) => {
  //     const values = i.split(",");
  //     const obj = csvHeader.reduce((object, header, index) => {
  //       object[header] = values[index];
  //       return object;
  //     }, {});
  //     return obj;
  //   });

  //   const validationResult = validateAndCleanObjects(array);
  //   console.log("validationResult", validationResult);

  //   setCanSubmit(validationResult.isValid);

  //   setModalData(validationResult.data);

  //   setArray(validationResult.data);
  //   toast.success("Data successfully imported...");
  // };


  function parseCSV(string) {
    const csvHeader = string
      .slice(0, string.indexOf("\n"))
      .replace(/\s/g, "")
      .split(",");

    const csvRows = string.slice(string.indexOf("\n") + 1).split(/\r?\n/);

    // console.log("csvRows: ", csvRows.length, csvRows);

    const array = csvRows
      .map((row) => {
        const values = [];
        let insideQuotes = false;
        let currentField = "";

        for (let char of row) {
          if (char === "," && !insideQuotes) {
            values.push(currentField.trim());
            currentField = "";
          } else if (char === '"') {
            insideQuotes = !insideQuotes;
          } else {
            currentField += char;
          }
        }

        values.push(currentField.trim());
        return values;
      })
      .filter((values) => values.length === csvHeader.length)
      .map((values) => {
        const obj = csvHeader.reduce((object, header, index) => {
          object[header] = values[index].trim();
          return object;
        }, {});
        return obj;
      });

    // console.log("array", array);
    const validationResult = validateAndCleanObjects(array);
    console.log("validationResult", validationResult);

    setCanSubmit(validationResult.isValid);

    setModalData(validationResult.data);

    setArray(validationResult.data);
    return array;

  }
  const handleFileUpload = (e) => {
    e.preventDefault();
    console.log(file);

    if (file) {
      console.log("file upload");
      fileReader.onload = function (event) {
        const text = event.target.result;
        parseCSV(text);
      };
      fileReader.readAsText(file);
    }
  };

  console.log(array);
  const headerKeys = Object.keys(Object.assign({}, ...array));

  //hanlde submit
  const handleOnSubmit = (e) => {
    toast.loading("Processing your data...");

    e.preventDefault();

    console.log(selectedBatch, array.length);

    bulkUploadQrCodes({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        qrs: array,
        qty: array.length,
        batch_id: selectedBatch.batch_id,
        batch_code: selectedBatch.name,
        product_code: "Bulk Upload",
        product_name: "Bulk Upload",
      },
    });
  };

  useEffect(() => {
    if (bulkUploadQrCodesData) {
      toast.dismiss();
      toast.success("Saved Successfully", {
        id: "bulkUploadQrCodesData",
      });
      console.log("bulkUploadQrCodesData", bulkUploadQrCodesData);
      setTimeout(() => {
        navigate(`/genefied/${getVendorName()}/printQr`);
      }, 400);
    }

    if (bulkUploadQrCodesError) {
      toast.dismiss();
      toast.error("Something went wrong");
      console.log("bulkUploadQrCodesError", bulkUploadQrCodesError);
    }
  }, [bulkUploadQrCodesData, bulkUploadQrCodesError]);

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Bulk Upload Qr
            <LinksComponent />
          </div>

          <div className="mx-9 mt-10">

          <div className="flex space-x-3">
            <div className="flex mb-1">
              <button
                type="submit"
                className="mb-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 text-center "
                onClick={() => downloadCSV(sampleDataQrBulkUpload, "sampleBulkQrUpload.csv")}
              >
                Sample Csv
              </button>
            </div>
            </div>
            <form className="bg-white m-auto border-opacity-40 rounded-lg flex w-full p-4 px-5 justify-center">
              <div className=" flex flex-col justify-center align-middle w-full">
                <div className="">
                  <div className="text-center ">
                    {/* <label className="block text-gray-700 text-sm font-bold mb-2">
                      Upload Excel/CSV File:
                    </label> */}
                    <div className="w-full flex justify-center items-center space-x-4">
                      <div className="w-1/3 flex items-center ">
                        <div className=" w-full">
                          <SelectComponentWithSearch
                            // title={"Batch"}
                            selected={selectedBatch}
                            setSelected={setSelectedBatch}
                            dataArr={batchList}
                            placeholder={"Select Batch"}
                          />
                        </div>
                      </div>

                      <div className="w-2/3 flex items-center ">
                        {/* CSV File Input */}
                        <input
                          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          type={"file"}
                          id={"csvFileInput"}
                          accept={".csv"}
                          onChange={handleOnChange}
                        />

                        {/* Import CSV Button */}
                        <button
                          className="whitespace-nowrap bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded ml-3"
                          onClick={(e) => handleFileUpload(e)}
                        >
                          IMPORT CSV
                        </button>
                      </div>
                    </div>

                    <div className="mt-4 flex justify-center space-x-2 ">
                      {/* View CSV File Button */}
                      {canSubmit && (
                        <button
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowModal(true);
                          }}
                          className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm  px-5 py-2.5 text-center"
                        >
                          View CSV File
                        </button>
                      )}

                      {/* Save Button */}
                      {canSubmit && (
                        <button
                          type="submit"
                          onClick={(e) => handleOnSubmit(e)}
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {showModal && modalData && (
            <ModalComponent
              size={"5xl"}
              data={""}
              show={showModal}
              setShow={setShowModal}
            >
              <div className="w-full rounded-lg">
                <div className=" w-full border-opacity-40 rounded-lg p-4 mt-2">
                  <table className="w-full border-collapse">
                    <thead>
                      <tr>
                        {headerKeys.map((key, idx) => (
                          <th
                            className="border-l-2 border-r-2 border-gray-300 px-2 py-2"
                            key={idx}
                          >
                            {key}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {array.slice(0, 30).map((item) => (
                        <tr
                          key={item.id}
                          className="border-t-2 border-b-2 border-gray-300"
                        >
                          {Object.values(item).map((val, index) => (
                            <td
                              key={index}
                              className="border-l-2 border-r-2 border-gray-300 px-2 py-2"
                            >
                              {val}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                    <h2 className="mt-2 ml-auto">
                      {" "}
                      {array.length - 30} rows more
                    </h2>
                  </table>
                </div>
              </div>
            </ModalComponent>
          )}
        </div>
      </div>
    </>
  );
};

export default QrBulkUpload;
