import React, { useEffect, useState } from "react";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import {
  useFetchIssueReportsMutation,
  useUpdateIssueReportsMutation,
} from "../../../features/tenant/issueReport/issueReportApi";
import { TiTick, TiTimes } from "react-icons/ti";
import { hasPermission } from "../../../utils/hasPermission";
import { getVendorName } from "../../../utils/getVendorName";
import { useNavigate } from "react-router-dom";
import ExportCSV from "../../../components/ExportCsv/ExportCsv";
import toast from "react-hot-toast";
import CustomExport from "../../../components/CustomExport/CustomExport";

const IssueReport = () => {

  const [fetchIssueReport, { data: issueReportData, error: issueReportError }] =
    useFetchIssueReportsMutation();
  const [
    updateIssueReports,
    { data: updateIssueReportsData, error: updateIssueReportsError },
  ] = useUpdateIssueReportsMutation();
  const [
    fetchIssueReportCsv,
    { data: issueReportCsvData, error: issueReportCsvError },
  ] = useFetchIssueReportsMutation();


  const [myList, setMyList] = useState([])
  const [totalRows, setTotalRows] = useState(0);


  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const currentDate = new Date().toISOString().split("T")[0];

  const initialRequest = {
    batch_code: "",
    limit: 10,
    offset: 0,
    mobile: "",
    qr_id: "",
    name: "",
    unique_code: "",
    batch_running_code: "",
    product_code: "",
    product_name: "",
    startDate: "",
    endDate: currentDate,
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
  };

  useEffect(() => {
    if (
      request.batch_code === "" &&
      request.mobile === "" &&
      request.qr_id === "" &&
      request.name === "" &&
      request.unique_code === "" &&
      request.batch_running_code === "" &&
      request.product_code === "" &&
      request.product_name === "" &&
      request.startDate === "" &&
      request.endDate === currentDate
    ) {
      fetchIssueReportFunction();
      // fetchIssueReportCsvFunction();
    }
  }, [request]);
  const navigate = useNavigate();
  useEffect(() => {
    const hasViewPermission = hasPermission("Claim/ScanReportsList");
    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    if(limit!=10||offset!=0)
    fetchIssueReportFunction();
    // fetchIssueReportCsvFunction();
  }, [limit, offset]);

  const fetchIssueReportFunction = () => {
    fetchIssueReport({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
    });
  };
  const fetchIssueReportCsvFunction = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchIssueReportCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
    });
  };

  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if(issueReportCsvData && issueReportCsvData.body.issueReport){
      setCsvData(issueReportCsvData.body.issueReport);
    }

    if (issueReportCsvError) {
      setCsvData(null);
    }
  }, [issueReportCsvData , issueReportCsvError])
  

  useEffect(() => {
    if (issueReportData) {
      console.log("Issue Report", issueReportData);
      setMyList(issueReportData.body && issueReportData.body.issueReport ?  issueReportData.body.issueReport :[]);
      setTotalRows(issueReportData.body && issueReportData.body.total ?  issueReportData.body.total :[]);
      
    }

    if (issueReportError) {
      console.log("Issue Report error", issueReportError);
    }
  }, [issueReportData, issueReportError]);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLimit(newPerPage);
  };

  const handleApprove = (e, row) => {
    console.log("object");
    const remark = window.prompt("please add remarks");
    if (!remark) {
      toast.error("Please add a remark");
      return;
    }
    console.log("remark", remark);
    console.log("reojafjaldsj-->",row.ir_id);
    console.log("fjlsdf",row);
    // return ;
    updateIssueReports({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        type: "approve",
        remark: remark,
      },
      id: row.ir_id,
    });
  };
  const handleReject = (e, row) => {
    updateIssueReports({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        type: "reject",
      },
      id: row.ir_id,
    });
  };

  useEffect(() => {
    if (updateIssueReportsData) {
      toast.dismiss();

      toast.success(updateIssueReportsData.message, {
        id: "updateIssueReportsData",
      });
      fetchIssueReportFunction();
    }
    if (updateIssueReportsError) {
      toast.dismiss();

      toast.error(updateIssueReportsError.data.message, {
        id: "updateIssueReportsError",
      });
      fetchIssueReportFunction();
    }
  }, [updateIssueReportsData, updateIssueReportsError]);

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
    },
    {
      name: "Mobile",
      cell: (row) => row.mobile,
      sortable: true,
      //   width: "120px",
    },
    {
      name: "Qr Id",
      cell: (row) => row.qr_id,
      sortable: true,
      //   width: "120px",
    },
    {
      name: "Batch Running Code",
      cell: (row) => row.batch_running_code,
      sortable: true,
      width: "200px",
    },
    {
      name: "User",
      cell: (row) => row.user_type,
      sortable: true,
      //   width: "120px",
    },
    {
      name: "Desc",
      cell: (row) => row.desc,
      sortable: true,
      //   width: "120px",
    },
    {
      name: "Images",
      cell: (row) => row.images?.length > 0 && row.images[0],
      sortable: true,
      //   width: "120px",
    },
    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <div>
          {row.status == "1" && (
            <div className="flex space-x-1 ">
              <button
                onClick={(e) => handleApprove(e, row)}
                className="flex justify-center items-center rounded bg-success  px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#14a44d] transition duration-150 ease-in-out hover:bg-success-600 hover:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] focus:bg-success-600 focus:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] focus:outline-none focus:ring-0 active:bg-success-700 active:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.3),0_4px_18px_0_rgba(20,164,77,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(20,164,77,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(20,164,77,0.2),0_4px_18px_0_rgba(20,164,77,0.1)]"
              >
                <TiTick className="mr-1" />
                Approve
              </button>
              <button
                onClick={(e) => handleReject(e, row)}
                className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
              >
                <TiTimes className="mr-1" />
                Reject
              </button>
            </div>
          )}
          {
            row.status == '2' && (
              <div className=" text-red-950 " >Rejected</div>
            )
          }
          {
            row.status == '3' && (
              <div className=" text-green-600 " >Approved</div>
            )
          }
        </div>
      ),
      width: "250px",
    },
  ];

  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center ">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Claim/Scan Reports
            <LinksComponent />
          </div>
          <div className="p-3">
            {/* {issueReportData && issueReportData.body.issueReport && (
              <ExportCSV
                data={issueReportData && issueReportData.body.issueReport}
                columnsToExport={["name", "mobile", "qr_id", "user_type"]}
              />
            )}   */}

<div className="flex gap-2 mb-2">
<div>
                  <CustomExport fetchData={fetchIssueReportCsvFunction} data={csvData} defaultColumnExport={["name", "mobile", "qr_id", "user_type"]}
                    filename="claim_scan_report"
                    />
                </div>
                </div>



            <div className="w-full bg-white shadow rounded-lg">
              <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
                <div className="flex space-x-2 justify-between">
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Mobile</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Mobile No..."
                        required
                        value={request.mobile}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              mobile: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Name</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2   text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Name..."
                        required
                        value={request.name}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              name: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Qr Id</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Qr Id..."
                        required
                        value={request.qr_id}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              qr_id: +newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10 ">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">B-R-Code</label>
                      <input
                        type="search"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                        placeholder="Batch Running Code"
                        required
                        value={request.batch_running_code}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              batch_running_code: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">Start Date</label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Start Date"
                        value={request.startDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              startDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-52 h-10">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">End Date</label>
                      <input
                        type="date"
                        id="default-search"
                        className="w-full px-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 "
                        placeholder="End Date"
                        value={request.endDate}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setRequest((prevState) => {
                            return {
                              ...prevState,
                              endDate: newValue,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row w-52 gap-2 h-10">
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right pdbtn_all"
                        onClick={() => {
                          fetchIssueReportFunction();
                          // fetchIssueReportCsvFunction();
                        }}
                      >
                        Search
                      </button>
                    </div>
                    <div className="relative searchin">
                    <label class="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">&nbsp;</label>
                      <button
                        className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        Clear Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <DataTableComponent
                  columns={columns}
                  // title={""}
                  data={myList}
                  // loading={loading}
                  totalRows={totalRows}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                ></DataTableComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IssueReport;
