import React, { useEffect, useMemo, useState } from "react";
import DataTableComponent from "../../../components/Tenant/DataTableComponent/DataTableComponent";
import LinksComponent from "../../../components/Tenant/LinksComponent/LinksComponent";
import { useFetchRedemptionHistoryMutation } from "../../../features/tenant/redeemptionHistory/redemptionHistoryApi";
import { hasPermission } from "../../../utils/hasPermission";
import { useNavigate } from "react-router-dom";
import { getVendorName } from "../../../utils/getVendorName";
import ModalComponent from "../../../components/Tenant/ModalComponent/ModalComponent";
import {
  useGetExtraRedeemedGiftsStatusMutation,
  useGetRedeemedGiftsStatusQuery,
  useUpdateRedeemedGiftsStatusMutation,
} from "../../../features/tenant/redeemedGiftsStatus/redeemedGiftsStatusApi";
import SelectComponent from "../../../components/Tenant/SelectComponent/SelectComponent";
import { toast } from "react-hot-toast";
import { getLoginUserData } from "../../../utils/getLoginUserData";
import { useGetAllZoneMutation } from "../../../features/tenant/zones/zonesApi";
import CustomExport from "../../../components/CustomExport/CustomExport";
import {
  FaCity,
  FaMapMarkerAlt,
  FaFlag,
  FaEnvelope,
  FaBuilding,
  FaUser,
  FaHome,
  FaCalendarAlt,
} from "react-icons/fa";
import { TiTimes } from "react-icons/ti";

const RedeemRequest = () => {
  // console.log("mere changes");
  const redemptionHierarchyzoneId = getLoginUserData().zone_id;
  const vendorData = JSON.parse(sessionStorage.getItem("companyProfile"));
  console.log("vendorData",vendorData);
  const redemptionHierarchy = vendorData.redemption_hierarchy && vendorData.redemption_hierarchy.gift_mapped_users;
  console.log("redemptionHierarchy", redemptionHierarchy);

  const [myList, setMyList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const [showAddressModal, setshowAddressModal] = useState(false);

  const [popUpBtnToggle, setPopUpBtnToggle] = useState(false);
  const [giftDetailPopUpToggle, setGiftDetailPopUpToggle] = useState(false);
  const [giftDetails, setGiftDetails] = useState(null);
  const [selectStatus, setSelectStatus] = useState(1);
  const [currentRefNo, setCurrentRefNo] = useState("");
  const [progressBtnToggle, setProgressBtnToggle] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(0);
  const [row, setRow] = useState([]);
  const appUserTypes = JSON.parse(sessionStorage.getItem("appUserType")) || [];
  const [appUsers, setAppUsers] = useState(appUserTypes);
  const [userZone, setUserZone] = useState({
    name: "All Zones",
    id: 0,
  });
  const [allZones, setAllZones] = useState([]);
  const [userTypesArray, setUserTypesArray] = useState({
    name: "All",
    id: null,
  });
  const [userTypeId, setUserTypeId] = useState(userTypesArray.id);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [prevPage, setPrevPage] = useState(0);
  const [currentUserType, setCurrentUserType] = useState(null);
  const [lastStatus, setLastStatus] = useState(null);
  const [extraStatus, setExtraStatus] = useState([]);
  const [
    getAllZone,
    {
      data: getAllZoneData,
      isLoading: getAllZoneIsLoading,
      isError: getAllZoneIsError,
      error: getAllZoneError,
    },
  ] = useGetAllZoneMutation();
  const currentDate = new Date().toISOString().split("T")[0];
  const initialRequest = {
    name: "",
    mobile: "",
    user_type_id: userTypeId,
    limit: 10,
    offset: 0,
    startDate: "",
    endDate: currentDate,
  };
  const [request, setRequest] = useState(initialRequest);
  const clearFilters = () => {
    setRequest(initialRequest);
    setUserZone({
      name: "All Zones",
      id: 0,
    });
  };

  // useEffect(() => {
  //   if (request.name === "" && request.mobile === "") {
  //     handleFetchRedemptionHistory();
  //     handleFetchRedemptionHistoryCsv();
  //   }
  // }, [request]);

  useEffect(() => {
    getAllZone({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
    });
  }, []);

  useEffect(() => {
    if (getAllZoneData) {
      const allZone = getAllZoneData.body.map((x) => {
        return {
          id: x.id,
          name: x.zone_name,
        };
      });

      setAllZones(allZone);
    }
    if (getAllZoneError) {
      toast.dismiss();
      toast.error(getAllZoneError.data.message, {
        id: "errorAtGetallzonedata",
      });
    }
  }, [getAllZoneData, getAllZoneError]);

  const [
    fetchRedemptionHistory,
    { data: redemptionHistoryData, error: redemptionHistoryError },
  ] = useFetchRedemptionHistoryMutation();

  const [
    fetchRedemptionHistoryCsv,
    { data: redemptionHistoryCsvData, error: redemptionHistoryCsvError },
  ] = useFetchRedemptionHistoryMutation();
  const [
    updateRedeemedGiftsStatus,
    {
      data: updateRedeemedGiftsStatusData,
      error: updateRedeemedGiftsStatusError,
    },
  ] = useUpdateRedeemedGiftsStatusMutation();

  const {
    data: getRedeemedGiftsStatusData,
    error: getRedeemedGiftsStatusError,
  } = useGetRedeemedGiftsStatusQuery({
    token: sessionStorage.getItem("access_token"),
    tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
  });

  const [
    getExtraRedeemedGiftsStatus,
    {
      data: getExtraRedeemedGiftsStatusData,
      error: getExtraRedeemedGiftsStatusError,
    },
  ] = useGetExtraRedeemedGiftsStatusMutation();

  useEffect(() => {
    if (currentUserType != null) {
      getExtraRedeemedGiftsStatus({
        token: sessionStorage.getItem("access_token"),
        tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
        user_type: currentUserType,
      });
    }
  }, [currentUserType]);

  useEffect(() => {
    if (getExtraRedeemedGiftsStatusData) {

      console.log("getExtraRedeemedGiftsStatusData : ",getExtraRedeemedGiftsStatusData);
      setExtraStatus(getExtraRedeemedGiftsStatusData.body)
    }
  }, [getExtraRedeemedGiftsStatusData]);


  console.log("getExtraRedeemedGiftsStatusData : ",getExtraRedeemedGiftsStatusData);



  const navigate = useNavigate();

  const handleFetchRedemptionHistory = () => {
    fetchRedemptionHistory({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: request,
      zoneId: userZone.id,
    });
  };

  const handleFetchRedemptionHistoryCsv = () => {
    const modifiedRequest = {
      ...request,
      limit: null,
      offset: null,
    };
    fetchRedemptionHistoryCsv({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: modifiedRequest,
      zoneId: userZone.id,
    });
  };

  const [csvData, setCsvData] = useState(null);
  useEffect(() => {
    if (redemptionHistoryCsvData && redemptionHistoryCsvData?.body?.data) {
      setCsvData(redemptionHistoryCsvData?.body?.data);
    }

    if (redemptionHistoryCsvError) {
      setCsvData(null);
    }
  }, [redemptionHistoryCsvData, redemptionHistoryCsvError]);

  useEffect(() => {
    const hasViewPermission = hasPermission("redeemRequestList");
    console.log(hasViewPermission);

    if (!hasViewPermission) {
      navigate(`/genefied/${getVendorName()}/`);
    }
    // if(limit!=10||offset!=0)
    handleFetchRedemptionHistory();
    // handleFetchRedemptionHistoryCsv();
  }, [limit, offset]);

  useEffect(() => {
    if (redemptionHistoryData) {
      // console.log("redemption history", redemptionHistoryData);
      setMyList(
        redemptionHistoryData.body && redemptionHistoryData.body.data
          ? redemptionHistoryData.body.data
          : []
      );

      setTotalRows(
        redemptionHistoryData.body && redemptionHistoryData.body.total
          ? redemptionHistoryData.body.total
          : 0
      );
    }

    if (redemptionHistoryError) {
      console.log("redemtion error", redemptionHistoryError);
    }
  }, [redemptionHistoryData, redemptionHistoryError]);

  const handleUpdateRedeemedGiftsStatus = () => {
    console.log("selectStatus",selectStatus);
    console.log("lastStatus",lastStatus);

    if(  selectStatus == lastStatus ){
      toast.error("you can not update same");
      return;
    }
    // if( Number(selectStatus) > 10){

    // }else{
    //   if( selectStatus != lastStatus+1){
    //     toast.error("you can't select this status directly");
    //     return;
    //   }

    // }

    // return;
    updateRedeemedGiftsStatus({
      token: sessionStorage.getItem("access_token"),
      tenant_id: JSON.parse(sessionStorage.getItem("vendorData")).tenant_id,
      body: {
        status: selectStatus,
        ref_no: currentRefNo,
        status_change_at: "2",
      },
    });
  };
  useEffect(() => {
    if (updateRedeemedGiftsStatusData) {
      toast.dismiss();
      toast.success(`${updateRedeemedGiftsStatusData.message}`, {
        id: `updateRedeemedGiftsStatusData`,
      });
      handleFetchRedemptionHistory(limit, offset);
      setPopUpBtnToggle(false);
    }

    if (updateRedeemedGiftsStatusError) {
      toast.dismiss();
      toast.error(`${updateRedeemedGiftsStatusError.data.message}`, {
        id: `updateRedeemedGiftsStatusError`,
      });
      setPopUpBtnToggle(false);
    }
  }, [updateRedeemedGiftsStatusData, updateRedeemedGiftsStatusError]);

  useMemo(() => {
    for (let index = 0; index < appUsers.length; index++) {
      const element = appUsers[index];
      if (
        element.user_type.toLocaleLowerCase() ===
        userTypesArray?.name?.toLocaleLowerCase()
      ) {
        setUserTypeId(element.user_type_id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          user_type_id: +element.user_type_id,
        }));
        break;
      }
    }
  }, [userTypesArray]);

  const handleReject = ()=>{

  }

  const columns = [
    {
      name: "SNo",
      cell: (row, idx) => offset + Number(idx + 1),
      sortable: true,
      width: "80px",
    },
    {
      name: "Platform",
      cell: (row) => row.platform,
      sortable: true,
      width: "120px",
    },
    {
      name: "Name",
      cell: (row) => row.name,
      sortable: true,
      width: "120px",
    },
    {
      name: "Email",
      cell: (row) => row.email,
      sortable: true,
      width: "120px",
    },
    {
      name: "Mobile",
      cell: (row) => row.mobile,
      sortable: true,
      width: "120px",
    },
    {
      name: "User Type",
      cell: (row) => row.user_type,
      sortable: true,
      width: "120px",
    },
    {
      name: "Points",
      cell: (row) => row.points,
      sortable: true,
      width: "120px",
    },
    {
      name: "Redemtion Type",
      cell: (row) => (row.redemption_type == "1" ? "Gift" : "Cashback"),
      sortable: true,
    },
    {
      name: "Ref No",
      cell: (row) => row.ref_no,
      sortable: true,
    },
    {
      name: "",
      cell: (row) => {
        if (row.address && Object.keys(row.address).length > 0) {
          return (
            <div>
              <button
                onClick={() => {
                  setshowAddressModal(row.address);
                }}
                className="flex justify-center items-center rounded bg-blue-900 px-2 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-blue-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                view address
              </button>
            </div>
          );
        } else {
          return "";
        }
      },
      sortable: true,
    },
    {
      name: "",
      cell: (row) => {
        if (row.gift) {
          return (
            <div>
              <button
                onClick={() => {
                  setGiftDetailPopUpToggle(true);
                  setGiftDetails(row.gift.gift);
                }}
                className="flex justify-center items-center rounded bg-green-900 px-2 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                view gifts
              </button>
            </div>
          );
        } else {
          return "";
        }
      },
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) =>
        row.redemption_type == '0' ? ( 
          <button
          onClick={(e) => handleReject(e, row)}
          className="flex justify-center items-center rounded bg-danger px-3 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#dc4c64] transition duration-150 ease-in-out hover:bg-danger-600 hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:bg-danger-600 focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] focus:outline-none focus:ring-0 active:bg-danger-700 active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.3),0_4px_18px_0_rgba(220,76,100,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(220,76,100,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(220,76,100,0.2),0_4px_18px_0_rgba(220,76,100,0.1)]"
        >
          <TiTimes className="mr-1" />
          Reject
        </button>
        ) :
        row.redemption_type == "1" ? (
          <button
            onClick={() => {
              // handleStatusChange(row, "1");
              setCurrentRefNo(row.ref_no);
              setSelectStatus(Number(row.status));
              setCurrentUserType(row.user_type);
              setLastStatus(Number(row.status))
              setPopUpBtnToggle(true);
            }}
            className="flex justify-center items-center rounded bg-gray-900 px-1 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gray-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            {/* <AiOutlineEdit className="mr-1" /> */}
            {Number(row.status) <= 10
              ? getRedeemedGiftsStatusData?.body &&
                getRedeemedGiftsStatusData.body[Number(row.status)]
              : redemptionHierarchy &&
              redemptionHierarchy[row.user_type][Number(row.status)-11]}
             
          </button>
        ) : (
          <button className="flex justify-center items-center rounded bg-gray-900 px-1 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-gray-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-gray-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
            {/* <AiOutlineEdit className="mr-1" /> */}
            {getRedeemedGiftsStatusData?.body &&
              getRedeemedGiftsStatusData.body[Number(row.status)]}
          </button>
        ) ,

      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          {" "}
          <button
            onClick={() => {
              // handleStatusChange(row, "2");
              setCurrentStatus(Number(row.status));
              setProgressBtnToggle(true);
              setRow(row.statusUpdateDetails);
            }}
            className="flex justify-center items-center rounded bg-green-900 px-2 pb-1.5 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-green-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-green-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          >
            {/* <AiOutlineEdit className="mr-1" /> */}
            view progress
          </button>
        </>
      ),
      sortable: true,
    },

    {
      name: "Created At",
      cell: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
  ];

  console.log("extra status",extraStatus);

  const handlePageChange = (pageNumber) => {
    const newOffset = (pageNumber - 1) * limit;
    setOffset(newOffset);
    setRequest({ ...request, offset: newOffset });
    setPrevPage(pageNumber - 1);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setRequest({ ...request, limit: newPerPage });
    setLimit(newPerPage);
  };


  console.log("showAddressModal", showAddressModal);
  return (
    <>
      <div className="flex w-full bg-gray-200 h-full justify-center">
        <div className="w-full ">
          <div className="border-b bg-white border-gray-300 pl-6 py-2 shadow-sm text-xl font-bold">
            Redeem Request
            <LinksComponent />
          </div>

          <div className="p-3">
            {/* {redemptionHistoryCsvData &&
              redemptionHistoryCsvData?.body?.data && (
                <ExportCSV
                  data={
                    redemptionHistoryCsvData &&
                    redemptionHistoryCsvData?.body?.data
                  }
                  columnsToExport={[
                    "name",
                    "email",
                    "mobile",
                    "user_type",
                    "points",
                    "redemption_type",
                    "ref_no",
                    "gift.gift[0].name"
                  ]}
                />
              )} */}

            <div className="mb-2">
              <CustomExport
                fetchData={handleFetchRedemptionHistoryCsv}
                data={csvData}
                defaultColumnExport={[
                  "name",
                  "email",
                  "mobile",
                  "user_type",
                  "points",
                  "redemption_type",
                  "ref_no",
                  "gift.gift[0].name",
                ]}
                filename="redeem_request"
              />
            </div>
            <div className="border-b  border-gray-300 bg-gray-50 rounded-t-lg p-3">
              <div className="flex  space-x-2 ">
                <div className="w-52">
                  <div className="relative searchin">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      Name
                    </label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Name..."
                      required
                      value={request.name}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, name: newValue };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="w-52">
                  <div className="relative searchin">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      Mobile
                    </label>
                    <input
                      type="search"
                      id="default-search"
                      className="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                      placeholder="Mobile No..."
                      required
                      value={request.mobile}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setRequest((prevState) => {
                          return { ...prevState, mobile: newValue };
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="w-52">
                  <div className="relative searchin topslt">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      Zones
                    </label>
                    <div className="relative searchin">
                      <SelectComponent
                        placeholder={"Select Zone*"}
                        selected={userZone}
                        setSelected={setUserZone}
                        dataArr={allZones}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-52 searchin">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    From Date
                  </label>
                  <input
                    type="date"
                    className="w-full  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder=""
                    value={request.startDate}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return {
                          ...prevState,
                          startDate: newValue,
                        };
                      });
                    }}
                  />
                </div>

                <div className="w-52 searchin">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    End Date
                  </label>
                  <input
                    type="date"
                    className="w-full  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 inputp"
                    placeholder=""
                    value={request.endDate}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setRequest((prevState) => {
                        return {
                          ...prevState,
                          endDate: newValue,
                        };
                      });
                    }}
                  />
                </div>

                <div className="w-52 ">
                  <div className="flex-col slc_dvd topslt">
                    <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                      User Types
                    </label>
                    <div className="relative searchin">
                      {appUsers && appUsers.length > 0 && (
                        <SelectComponent
                          className="capitalize"
                          //title={"User Types"}
                          selected={userTypesArray}
                          setSelected={setUserTypesArray}
                          dataArr={appUsers}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="">
                  <label className="block text-sm font-medium capitalize leading-6 text-gray-900 mb-1">
                    &nbsp;
                  </label>
                  <div className="relative searchin gap-2 flex">
                    <button
                      className="py-2 btn btn-outline-primary clear-both float-right  pdbtn_all"
                      onClick={() => {
                        handleFetchRedemptionHistory();
                        // handleFetchRedemptionHistoryCsv();
                      }}
                    >
                      Search
                    </button>

                    <button
                      className="btn btn-outline-primary clear-both float-right pdbtn_all whitespace-nowrap"
                      onClick={() => {
                        clearFilters();
                      }}
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-3 bg-white shadow rounded-lg">
              <DataTableComponent
                columns={columns}
                // title={""}
                data={myList}
                // loading={loading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
              ></DataTableComponent>
              {popUpBtnToggle && (
                <ModalComponent
                  size={"md"}
                  show={popUpBtnToggle}
                  setShow={setPopUpBtnToggle}
                >
                  <div className="w-full p-4">
                    <div className="w-full flex justify-center ">
                      <select
                        type="text"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-auto capitalize"
                        placeholder="Add new UserType"
                        value={selectStatus}
                        onChange={(e) =>
                          setSelectStatus(Number(e.target.value))
                        }
                      >
                        <option key={0} value={Number(0)}>
                          {"Reject"}
                        </option>
                        <option key={1} value={Number(1)}>
                          {"Pending"}
                        </option>
                        {getExtraRedeemedGiftsStatusData &&
                          getExtraRedeemedGiftsStatusData.body.map(
                            (x, index) => {
                              return (
                                <option
                                  key={11 + index}
                                  value={Number(11 + index)}
                                >
                                  {x}_approval
                                </option>
                              );
                            }
                          )}
                        {getRedeemedGiftsStatusData &&
                          getRedeemedGiftsStatusData.body.map((x, index) => {
                            if ( index == 0 || index == 1){
                              return <></>;
                            }else{
                              return (
                                <option key={index} value={Number(index)}>
                                  {x}
                                </option>
                              );

                            }
                          })}
                      </select>
                    </div>
                    <div className="w-full flex justify-center mt-4 ">
                      <button
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                        onClick={handleUpdateRedeemedGiftsStatus}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </ModalComponent>
              )}
              {progressBtnToggle && (
                <ModalComponent
                  size={"sm"}
                  show={progressBtnToggle}
                  setShow={setProgressBtnToggle}
                >
                  <div className="w-full p-4">
                    <div className="flex justify-start pl-4 ">
                      <ol class="relative text-gray-500 border-l border-gray-200 dark:border-gray-700 dark:text-gray-400">
                        {getRedeemedGiftsStatusData.body.map((x, index) => {
                          if (index == 0) {
                            return <></>;
                          }
                          if (index <= currentStatus) {
                            return (
                              <li class="mb-10 ml-7 flex items-center">
                                <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                                  <svg
                                    class="w-3.5 h-3.5 text-green-500 dark:text-green-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 16 12"
                                  >
                                    <path
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M1 5.917 5.724 10.5 15 1.5"
                                    />
                                  </svg>
                                </span>
                                <h3 class="font-medium leading-tight capitalize fntchng">
                                  {x}

                                  {row &&
                                    row.length > 0 &&
                                    row
                                      .filter((y) => +y.status === index)
                                      .map((filteredRow) => (
                                        <span key={filteredRow.id} className="">
                                          {new Date(
                                            filteredRow.created_at
                                          ).toLocaleString()}
                                        </span>
                                      ))}
                                </h3>
                              </li>
                            );
                          } else {
                            return (
                              <li class="mb-10 ml-7 flex items-center">
                                <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                                  <svg
                                    class="w-3.5 h-3.5 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 16"
                                  >
                                    <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z" />
                                  </svg>
                                </span>
                                <h3 class="font-medium leading-tight capitalize">
                                  {x}
                                </h3>
                                {/* <p class="text-sm">Step details here</p> */}
                              </li>
                            );
                          }
                        })}

                        {/* <li class="mb-10 ml-6">
                        <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                          <svg
                            class="w-3.5 h-3.5 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 18 20"
                          >
                            <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                          </svg>
                        </span>
                        <h3 class="font-medium leading-tight">Review</h3>
                        <p class="text-sm">Step details here</p>
                      </li>
                      <li class="ml-6">
                        <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                          <svg
                            class="w-3.5 h-3.5 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 18 20"
                          >
                            <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z" />
                          </svg>
                        </span>
                        <h3 class="font-medium leading-tight">Confirmation</h3>
                        <p class="text-sm">Step details here</p>
                      </li> */}
                      </ol>
                    </div>
                  </div>
                </ModalComponent>
              )}

              {giftDetailPopUpToggle && (
                <ModalComponent
                  size={"md"}
                  show={giftDetailPopUpToggle}
                  setShow={setGiftDetailPopUpToggle}
                >
                  <div className="w-full ">
                    <div className="w-full p-4">
                      {giftDetails &&
                        giftDetails.map((x) => {
                          return (
                            <div className="flex gap-3 items-center bg-gray-50 border border-gray-100 rounded-sm overflow-hidden p-2 mb-2">
                              <div className="w-1/5 border bg-white border-gray-100 rounded-sm overflow-hidden p-2">
                                <img
                                  src={x.images[0]}
                                  alt={"error loading image"}
                                />
                              </div>
                              <div className="w-4/5">
                                <span className="capitalize block">
                                  <span className="text-gray-400">name</span>
                                  &nbsp;&nbsp;:&nbsp;&nbsp;{x.name}
                                </span>
                                <span className="capitalize block">
                                  <span className="text-gray-400">brand</span>
                                  &nbsp;&nbsp;:&nbsp;&nbsp;{x.brand}
                                </span>
                                <span className="capitalize block">
                                  <span className="text-gray-400">point</span>
                                  &nbsp;&nbsp;:&nbsp;&nbsp;{x.points}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </ModalComponent>
              )}

              {showAddressModal && Object.keys(showAddressModal).length > 0 && (
                <ModalComponent
                  size={"md"}
                  show={
                    showAddressModal && Object.keys(showAddressModal).length > 0
                      ? true
                      : false
                  }
                  setShow={setshowAddressModal}
                >
                  <div className="w-full">
                    <div className="w-full">
                      <div className="p-3 bg-white rounded-lg shadow-md  mx-auto">
                        <h2 className="text-lg text-center font-bold mb-4">
                          Address Details
                        </h2>
                        <div className="mb-2">
                          <span className="font-semibold">Address: </span>
                          {showAddressModal?.address ?  showAddressModal.address : showAddressModal?.data?.address}
                        </div>
                        <div className="mb-2">
                          <span className="font-semibold">Pincode: </span>
                          {showAddressModal.pincode}
                        </div>
                        <div className="mb-2">
                          <span className="font-semibold">District: </span>
                          {showAddressModal.district}
                        </div>
                        <div className="mb-2">
                          <span className="font-semibold">City: </span>
                          {showAddressModal.city}
                        </div>
                        <div className="mb-2">
                          <span className="font-semibold">State: </span>
                          {showAddressModal.state}
                        </div>
                        <div className="mb-2">
                          <span className="font-semibold">Country: </span>
                          {showAddressModal.country}
                        </div>
                      
                       
                      </div>
                    </div>
                  </div>
                </ModalComponent>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RedeemRequest;

const ExportCSV = ({ data, columnsToExport }) => {
  function exportCsv() {
    console.log("Exporting CSV...");
    if (!data) {
      console.error("No data to export");
      return;
    }

    const csvRows = [];
    // Create header row
    const headers = columnsToExport.map((field) => `"${field}"`);
    csvRows.push(headers.join(","));

    // Extract data for each row
    data.forEach((entry) => {
      const rowData = [];
      columnsToExport.forEach((field) => {
        let value = entry[field];
        // If the field contains nested properties, traverse them
        console.log(entry, "ENTRY HERE");

        if (
          field === "gift.gift[0].name" &&
          entry.gift &&
          entry.gift.gift &&
          entry.gift.gift[0]
        ) {
          console.log("entry:", entry);
          console.log("entry.gift:", entry.gift);
          console.log("entry.gift.gift[0]:", entry.gift.gift[0]);
          value = entry.gift.gift[0].name || "";
          console.log("name value:", value);
        }
        // Add the value to rowData
        rowData.push(`"${value}"`);
      });
      // Push the rowData as a CSV row
      csvRows.push(rowData.join(","));
    });

    // Join all CSV rows with newline characters
    const csvContent = csvRows.join("\n");

    // Create a Blob containing the CSV data
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element to trigger the download
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "redemption_history.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Your browser does not support downloading files");
    }
  }

  return (
    <button className="btn btn-primary" onClick={exportCsv}>
      Export CSV
    </button>
  );
};
